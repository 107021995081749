import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AuthContext from 'context/authContext';

import CrudCard from '../../common/CrudCard/CrudCard';

/* -------------------------------------------------------------------------- */
const CREATE_DONATION_AMOUNT = gql`
  mutation CreateDonationAmount(
    $amount: Int = ""
    $organisation_id: uuid = ""
    $description: String = ""
  ) {
    insert_donation_amount_one(
      object: {
        amount: $amount
        description: $description
        organisation_id: $organisation_id
      }
    ) {
      id
      amount
      description
      organisation_id
    }
  }
`;

const UPDATE_DONATION_AMOUNT = gql`
  mutation UpdateDonationAmount(
    $id: uuid = ""
    $amount: Int = ""
    $description: String = ""
  ) {
    update_donation_amount(
      where: { id: { _eq: $id } }
      _set: { amount: $amount, description: $description }
    ) {
      returning {
        id
        amount
        description
        organisation_id
      }
    }
  }
`;

const DELETE_DONATION_AMOUNT = gql`
  mutation DeleteDonationAmount($id: uuid = "") {
    delete_donation_amount_by_pk(id: $id) {
      id
    }
  }
`;

const GET_DONATION_AMOUNTS = gql`
  query GetDonationAmounts($where: donation_amount_bool_exp = {}) {
    donation_amount(order_by: { created_at: desc }, where: $where) {
      id
      amount
      description
      organisation_id
    }
  }
`;

/* -------------------------------------------------------------------------- */
function DonationAmounts({ sectionRef, sideNavLink, subject }) {
  const cardRef = useRef();
  const { organization } = useContext(AuthContext);

  const [where, setWhere] = useState({});

  useEffect(() => {
    setWhere({
      where: { organisation_id: { _eq: organization.id } }
    });
  }, [organization]);

  const [createDonationAmount, { loading: createLoading }] = useMutation(
    CREATE_DONATION_AMOUNT,
    {
      refetchQueries: ['GetDonationAmounts'],
      onCompleted: () => {
        toast.success('Donation Amount created successfully');
        cardRef.current.onCreationSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [updateDonationAmount, { loading: updateLoading }] = useMutation(
    UPDATE_DONATION_AMOUNT,
    {
      refetchQueries: ['GetDonationAmounts'],
      onCompleted: () => {
        toast.success('Donation Amount updated successfully');
        cardRef.current.onEditSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [deleteDonationAmount, { loading: deleteLoading }] = useMutation(
    DELETE_DONATION_AMOUNT,
    {
      refetchQueries: ['GetDonationAmounts'],
      onCompleted: () => {
        toast.success('Donation Amount deleted successfully');
        cardRef.current.onDeleteSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );

  const [GetDonationAmounts, { data: donationAmountList }] =
    useLazyQuery(GET_DONATION_AMOUNTS);

  useEffect(() => {
    if (Object.keys(where).length)
      GetDonationAmounts({
        variables: { ...where }
      });
  }, [where]);

  const handleCreate = ({ amount, description }) => {
    createDonationAmount({
      variables: {
        amount,
        description,
        organisation_id: organization.id
      }
    });
  };
  const handleEdit = ({ id, data }) => {
    updateDonationAmount({
      variables: {
        id,
        amount: data.amount,
        description: data.description
      }
    });
  };
  const handleDelete = id => {
    deleteDonationAmount({
      variables: {
        id
      }
    });
  };

  const list = donationAmountList?.donation_amount.map(amount => ({
    id: amount.id,
    values: [
      {
        value: amount.amount,
        name: 'amount'
      },
      {
        value: amount.description,
        name: 'description'
      }
    ]
  }));
  if (list) {
    list.unshift({
      id: null,
      values: [
        {
          value: 'Other',
          name: 'amount'
        },
        { value: 'Custom donation amount', name: 'description' }
      ]
    });
  }

  return (
    <CrudCard
      ref={cardRef}
      sectionRef={sectionRef}
      sideNavLink={sideNavLink}
      createData={handleCreate}
      editData={handleEdit}
      deleteData={handleDelete}
      createLoading={createLoading}
      updateLoading={updateLoading}
      deleteLoading={deleteLoading}
      // formTitle={'Add Donation Amounts'}
      listTitle={'Current Donation Amounts'}
      title={'Donation Amounts'}
      inputs={[
        {
          name: 'amount',
          placeholder: 'Add Donation Amount',
          type: 'text',
          required: true,
          value: '',
          label: 'Donation Amount',
          validation: yup
            .number()
            .typeError('Amount must be a number')
            .required('Donation Amount is required')
        },
        {
          name: 'description',
          placeholder: 'Add Description',
          type: 'text',
          required: false,
          value: '',
          label: 'Amount Description',
          validation: yup.string()
        }
      ]}
      list={list}
      subject={subject}
    />
  );
}

DonationAmounts.propTypes = {
  sectionRef: PropTypes.any,
  sideNavLink: PropTypes.any,
  subject: PropTypes.string
};

export default DonationAmounts;
