import { Button, Image } from 'react-bootstrap';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import { CartIcon } from 'assets/icons/cart';
import logo from 'assets/img/we-raise-images/help-yateem.png';

import { CartDrawer } from './CartDrawer';
import { GiftAidDrawer } from './GiftAidDrawer';

const HEADER_HEIGHT = 94;

function DonationHeader() {
  const { basket, setIsCartDrawerOpen } = useDonationContextProvider();

  return (
    <>
      <header className="px-4 px-md-8 h-94 d-flex justify-content-between align-items-center wr-donation-shadow">
        <Image src={logo} alt="Help Yateem" />
        <div className="d-flex flex-column flex-md-row gap-2 gap-md-4 align-items-center">
          <a href="tel:+201021312451254" className="text-black">
            +201021312451254
          </a>
          <Button
            className="d-flex justify-content-center align-items-center wr-donation-btn wr-donation-cart-btn bg-wr-donation-primary"
            onClick={() => setIsCartDrawerOpen(true)}
          >
            <CartIcon />
            {basket.length} Donation
          </Button>
        </div>
      </header>
      <CartDrawer />
      <GiftAidDrawer />
    </>
  );
}

export { DonationHeader, HEADER_HEIGHT };
