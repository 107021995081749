import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Image, Row, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import paths from 'routes/paths';
// import SingleImage from './Image';
// import product2 from 'assets/img/products/2.jpg';

const ImageList = ({
  ImageObj,
  index,
  setSelectedId,
  setShowDeleteImageModal
}) => {
  const [show, setShow] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const {
    created_at,
    orphan,
    gallery_image,
    image_description,
    id: imageId
  } = ImageObj;

  const handleShow = src => {
    setPreviewImage(src);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <Col
        xs={12}
        className={classNames('p-x1', {
          'bg-100': index % 2 !== 0
        })}
      >
        <Row>
          <Col sm={5} md={2}>
            <Image
              src={gallery_image}
              rounded={true}
              className={classNames('h-100 w-100 fit-cover cursor-pointer')}
              role="button"
              onClick={() => handleShow(gallery_image)}
            />
          </Col>
          <Col sm={7} md={10}>
            <p className="text-black fs-1">{image_description}</p>
            <p>{created_at.split('T')[0]}</p>
            <p className="text-primary">
              <span className="text-dark"> By </span>
              <Link to={paths.beneficiaries + '/' + orphan.id}>
                {'  ' + orphan.first_name + ' ' + orphan.last_name}
              </Link>
            </p>
            <Button
              variant="link"
              className="ps-0"
              style={{ fontSize: '12px' }}
              onClick={() => {
                setSelectedId(imageId);
                setShowDeleteImageModal(true);
              }}
            >
              Remove
            </Button>
          </Col>
        </Row>
      </Col>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Body className="text-center">
          {previewImage && <Image src={previewImage} fluid />}
        </Modal.Body>
      </Modal>
    </>
  );
};

ImageList.propTypes = {
  ImageObj: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    created_at: PropTypes.date,
    owner: PropTypes.string,
    gallery_image: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object,
    orphan: PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    image_description: PropTypes.string
  }),
  index: PropTypes.number,
  setSelectedId: PropTypes.func,
  setShowDeleteImageModal: PropTypes.func
};

export default ImageList;
