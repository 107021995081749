import { Button, Image } from 'react-bootstrap';

import { DonationHeader } from './DonationHeader';
import { PrivacyPolicy } from './DonationFooter';
import IconButton from '../common/IconButton';

import { ShareCircleIcon } from 'assets/icons/share-circle';
import image from 'assets/img/we-raise-images/front-end/thank_you_video_thum.png';

function DonationThanksPage() {
  return (
    <div>
      <DonationHeader />
      <div className="wr-donation-thanks-container">
        <div className="mx-auto mb-2 rounded-5 d-flex flex-column gap-25 wr-donation-thanks-card">
          <div className="position-relative">
            <IconButton
              icon="far fa-play-circle"
              iconClassName="text-white"
              className="p-0 bg-transparent border-0 shadow-none center-item fs-8 lh-1"
            />
            <Image src={image} fluid className="w-100" />
          </div>

          <div className="text-center">
            <p
              className="font-patrick mb-2 wr-donation-text-primary"
              style={{ fontSize: '42px' }}
            >
              A Huge Thank You!
            </p>

            <p className="fs-0 wr-donation-text-grey-900">
              Mr.Ahmed, Your contribution mean a lot and will be put to good use
              in making a difference. we send your donation receipt to your
              register email{' '}
              <a
                href="mailto:ahmed@email.com"
                className="wr-donation-text-primary"
              >
                Ahmed@Email.com
              </a>
            </p>
          </div>

          <div className="d-flex flex-column gap-3">
            <Button className="w-100 h-48 text-uppercase d-flex align-items-center justify-content-center gap-2 wr-donation-btn bg-wr-donation-primary">
              <ShareCircleIcon />
              share the good
            </Button>
            <Button className="w-100 h-48 text-capitalize wr-donation-text-grey-400 bg-wr-donation-secondary border-1 border-blue-100">
              help us by giving us your feedback
            </Button>
          </div>
        </div>
        <PrivacyPolicy />
      </div>
    </div>
  );
}

export { DonationThanksPage };
