import { useState, useContext } from 'react';
import { Routes, Navigate, Route, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { getRelativePath } from 'helpers/utils';
import DonationConfig from './DonationConfig';
import paths from 'routes/paths';
import DonationPayment from './DonationPayment';
import AuthContext from 'context/authContext';
import { GIFT_AID_STATUS_UNCLAIMED } from 'components/gift-aid/unclaimed-transactions';
import { GET_DONORS } from './donation-form-components/ADSelectDonor';

export const CREATE_DONATION = gql`
  mutation InsertOrder(
    $donar_id: uuid
    $organisation_id: uuid
    $branch_id: uuid
    $order_details: [order_details_insert_input!] = {
      amount: ""
      billing_condition: ""
      donation_type_id: ""
      package_id: ""
    }
    $payment_status: String = ""
    $subtotal: float8 = ""
    $transaction_fees: float8 = ""
    $donation_amount: float8 = ""
    $gift_aid_eligible: Boolean = false
    $gift_aids: gift_aid_arr_rel_insert_input = null
  ) {
    insert_orders_one(
      object: {
        donar_id: $donar_id
        organisation_id: $organisation_id
        branch_id: $branch_id
        order_details: { data: $order_details }
        payment_status: $payment_status
        subtotal: $subtotal
        transaction_fees: $transaction_fees
        donation_amount: $donation_amount
        gift_aid_eligible: $gift_aid_eligible
        gift_aids: $gift_aids
      }
    ) {
      donation_amount
      gift_aid_eligible
      payment_status
      id
      created_at
      updated_at
      donar_id
      payment_method_id
      transaction_fees
      subtotal
      organisation_id
      branch_id
    }
  }
`;

export const UPDATE_PAYMENT_STATUS = gql`
  mutation UpdatePaymentStatus($id: uuid = "", $payment_status: String = "") {
    update_orders_by_pk(
      pk_columns: { id: $id }
      _set: { payment_status: $payment_status }
    ) {
      id
    }
  }
`;

export const MAKE_STRIPE_PAYMENT = gql`
  mutation MakeStripePayment(
    $donor_id: ID = ""
    $payment_type: String = ""
    $amount: Int = 10
  ) {
    ChargeStripeAmount(
      donor_id: $donor_id
      payment_type: $payment_type
      amount: $amount
    ) {
      message
    }
  }
`;

function CreateDonation() {
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const { organization, selectedBranchId } = useContext(AuthContext);
  const [createDonation, { loading }] = useMutation(CREATE_DONATION, {
    onCompleted: dataDonation => {
      setData({ ...data, ...dataDonation });
      navigate(getRelativePath(paths.payNewDonation, paths.addNewDonation));
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });
  const [makePayment, { loading: makePaymentLoading }] = useMutation(
    MAKE_STRIPE_PAYMENT,
    {
      onCompleted: () => {
        updateOrderStatus({
          variables: { id: data.insert_orders_one.id, payment_status: 'paid' }
        });
      },
      onError: error => {
        toast.error('Something went wrong');
        console.log(error);
      }
    }
  );

  const [updateOrderStatus, { loading: updateOrderStatusLoading }] =
    useMutation(UPDATE_PAYMENT_STATUS, {
      onCompleted: () => {
        toast.success('Payment received!');
        navigate(paths.dashboard);
      },
      onError: error => {
        toast.error('Something went wrong');
        console.log(error);
      }
    });

  const { data: donorsData } = useQuery(GET_DONORS, {
    variables: {
      where: {
        organisation_id: { _eq: organization.id }
      }
    }
  });

  const onSubmitDonationConfig = data => {
    setData(data);
    let donorEmail = '';

    const {
      donorId,
      donationItems,
      subtotal,
      transactionFees,
      total,
      settings
    } = data;
    donorsData.donars.forEach(element => {
      if (element.id === donorId) {
        element.email.forEach(email => {
          if (email.isPrimary === true) {
            donorEmail = email.email;
          }
        });
      }
    });

    const orderDetailsItems = donationItems.map(
      ({ amount, values, type, packageId }) => {
        return {
          amount: amount,
          billing_condition: values.billingCondition,
          donation_type: type,
          package_id: packageId
        };
      }
    );

    const giftAidData = {
      data: {
        amount: total * 0.25,
        donar_id: donorId,
        status: GIFT_AID_STATUS_UNCLAIMED
      }
    };
    setData({
      donorId,
      donationItems,
      subtotal,
      transactionFees,
      total,
      settings,
      organization: organization.id,
      donorsData,
      donorEmail
    });

    createDonation({
      variables: {
        organisation_id: organization.id,
        branch_id: selectedBranchId,
        donar_id: donorId,
        order_details: orderDetailsItems,
        subtotal,
        transaction_fees: settings.coverTransactionFee ? transactionFees : 0,
        donation_amount: total,
        gift_aid_eligible: settings.giftAid,
        gift_aids: settings.giftAid ? giftAidData : null,
        payment_status: 'pending'
      }
    });
  };
  const onSubmitMakePayment = () => {
    makePayment({
      variables: {
        donor_id: data.donorId,
        payment_type: 'stripe',
        amount: data.total * 100
      }
    });
  };

  return (
    <Routes>
      <Route
        path={getRelativePath(paths.configNewDonation, paths.addNewDonation)}
        element={
          <DonationConfig onSubmit={onSubmitDonationConfig} loading={loading} />
        }
      />
      <Route
        path={getRelativePath(paths.payNewDonation, paths.addNewDonation)}
        element={
          <DonationPayment
            onSubmit={onSubmitMakePayment}
            loading={loading || makePaymentLoading || updateOrderStatusLoading}
            data={data}
          />
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to={getRelativePath(paths.configNewDonation, paths.addNewDonation)}
            replace
          />
        }
      />
    </Routes>
  );
}

export default CreateDonation;
