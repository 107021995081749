import { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import useScrollSpy from 'react-use-scrollspy';

import AuthContext from 'context/authContext';

import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_DONATION,
  MAKE_STRIPE_PAYMENT,
  UPDATE_PAYMENT_STATUS
} from 'components/add-donation';
import { GIFT_AID_STATUS_UNCLAIMED } from 'components/gift-aid/unclaimed-transactions';
import { GET_DONORS } from 'components/add-donation/donation-form-components/ADSelectDonor';

import { getRelativePath } from 'helpers/utils';
import paths from 'routes/paths';

import { toast } from 'react-toastify';

import { Row, Col, Card, Nav } from 'react-bootstrap';
import DonationConfig from 'components/add-donation/DonationConfig';
import DonationPayment from 'components/add-donation/DonationPayment';

const sideNavLinks = [
  { id: 1, href: '#details', title: 'Donation Details' },
  { id: 2, href: '#appeals', title: 'Selected Appeals' },
  { id: 3, href: '#settings', title: 'Advanced Settings' }
];

function AddDonation() {
  const [data, setData] = useState({});
  const [stickyStatus, setStickyStatus] = useState(false);

  const navigate = useNavigate();

  const sectionRefs = [useRef(null), useRef(null), useRef(null)];
  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 100) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, []);

  const { organization, selectedBranchId } = useContext(AuthContext);
  const [createDonation, { loading }] = useMutation(CREATE_DONATION, {
    onCompleted: dataDonation => {
      setData({ ...data, ...dataDonation });
      navigate(getRelativePath(paths.addDonationPayment, paths.addDonation));
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });
  const { data: donorsData } = useQuery(GET_DONORS, {
    variables: {
      where: {
        organisation_id: { _eq: organization.id }
      }
    }
  });

  const onSubmitDonationConfig = data => {
    setData(data);
    let donorEmail = '';

    const {
      donorId,
      donationItems,
      subtotal,
      transactionFees,
      total,
      settings
    } = data;
    donorsData.donars.forEach(element => {
      if (element.id === donorId) {
        element.email.forEach(email => {
          if (email.isPrimary === true) {
            donorEmail = email.email;
          }
        });
      }
    });

    const orderDetailsItems = donationItems.map(
      ({ amount, values, type, packageId }) => {
        return {
          amount: amount,
          billing_condition: values.billingCondition,
          donation_type: type,
          package_id: packageId
        };
      }
    );

    const giftAidData = {
      data: {
        amount: total * 0.25,
        donar_id: donorId,
        status: GIFT_AID_STATUS_UNCLAIMED
      }
    };
    setData({
      donorId,
      donationItems,
      subtotal,
      transactionFees,
      total,
      settings,
      organization: organization.id,
      donorsData,
      donorEmail
    });

    createDonation({
      variables: {
        organisation_id: organization.id,
        branch_id: selectedBranchId,
        donar_id: donorId,
        order_details: orderDetailsItems,
        subtotal,
        transaction_fees: settings.coverTransactionFee ? transactionFees : 0,
        donation_amount: total,
        gift_aid_eligible: settings.giftAid,
        gift_aids: settings.giftAid ? giftAidData : null,
        payment_status: 'pending'
      }
    });
  };

  const [updateOrderStatus, { loading: updateOrderStatusLoading }] =
    useMutation(UPDATE_PAYMENT_STATUS, {
      onCompleted: () => {
        toast.success('Payment received!');
        navigate(paths.dashboard);
      },
      onError: error => {
        toast.error('Something went wrong');
        console.log(error);
      }
    });
  const [makePayment, { loading: makePaymentLoading }] = useMutation(
    MAKE_STRIPE_PAYMENT,
    {
      onCompleted: () => {
        updateOrderStatus({
          variables: { id: data.insert_orders_one.id, payment_status: 'paid' }
        });
      },
      onError: error => {
        toast.error('Something went wrong');
        console.log(error);
      }
    }
  );
  const onSubmitMakePayment = () => {
    makePayment({
      variables: {
        donor_id: data.donorId,
        payment_type: 'stripe',
        amount: data.total * 100
      }
    });
  };

  return (
    <Row className="pt-3 gx-3">
      <Col md={9}>
        <Routes>
          <Route
            path={getRelativePath(paths.addDonationConfig, paths.addDonation)}
            element={
              <DonationConfig
                onSubmit={onSubmitDonationConfig}
                loading={loading}
              />
            }
          />
          <Route
            path={getRelativePath(paths.addDonationPayment, paths.addDonation)}
            element={
              <DonationPayment
                onSubmit={onSubmitMakePayment}
                loading={
                  loading || makePaymentLoading || updateOrderStatusLoading
                }
                navBackPath={getRelativePath(
                  paths.addDonationConfig,
                  paths.addDonation
                )}
                data={data}
              />
            }
          />
          <Route
            path={'/*'}
            element={
              <Navigate
                to={getRelativePath(paths.addDonationConfig, paths.addDonation)}
              />
            }
          />
        </Routes>
      </Col>
      <Col md={3} className="order-0 order-md-1 d-none d-md-block">
        <Card
          className={'mb-3 sticky-top border shadow-none'}
          style={{ top: stickyStatus ? '5rem' : '0' }}
        >
          <Card.Body>
            <div>
              <Nav className="flex-column" variant="pills">
                {sideNavLinks?.map(item => (
                  <Nav.Link
                    key={item.id}
                    href={item.href}
                    className={`${
                      activeSection === item.id
                        ? 'current custom-nav-link'
                        : 'custom-nav-link'
                    }`}
                    active={activeSection >= item.id}
                  >
                    {item.title}
                  </Nav.Link>
                ))}
              </Nav>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export { AddDonation };
