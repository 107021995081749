import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import Flex from 'components/common/Flex';
import dayjs from 'dayjs';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

const GET_GIFT_AID_ID = gql`
  query GetGiftAidId($donarId: uuid!) {
    gift_aid(where: { donar_id: { _eq: $donarId } }) {
      id
    }
  }
`;

const GET_DECLARATION_LOG = gql`
  query GetDeclarationLog($giftAidId: uuid!) {
    declaration_log(
      where: { gift_aid_id: { _eq: $giftAidId } }
      order_by: { created_at: desc }
    ) {
      created_at
      declared_via
      eligibility
      ended_on
      gift_aid_id
      id
      ref_number
      started_on
    }
  }
`;

const GET_ELIGIBILITY = gql`
  query GetEligibility($donarId: uuid!) {
    orders(where: { donar_id: { _eq: $donarId } }) {
      gift_aid_eligible
    }
  }
`;

export const GET_AWAITING_SUBMISSION = gql`
  query GetAwaitingSubmission($donarId: String!) {
    gift_aid(where: { donar_id: { _eq: $donarId } }) {
      amount
    }
  }
`;

const DonorGiftAid = () => {
  const { id: donarId } = useParams();

  const { data: giftAidData } = useQuery(GET_GIFT_AID_ID, {
    variables: { donarId }
  });

  const { data: eligibilityData } = useQuery(GET_ELIGIBILITY, {
    variables: { donarId }
  });

  const { data: awaitingSubmissionData } = useQuery(GET_AWAITING_SUBMISSION, {
    variables: { donarId }
  });

  const giftAidId = giftAidData?.gift_aid?.[0]?.id;

  const { data: declarationLogData } = useQuery(GET_DECLARATION_LOG, {
    variables: { giftAidId },
    skip: !giftAidId
  });

  const columns = [
    {
      accessor: 'ref_number',
      Header: 'Ref.',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => (
        <> {dayjs(rowData.row.original.created_at).format('MMM-DD-YYYY')} </>
      )
    },
    {
      accessor: 'started_on',
      Header: 'Started on',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => (
        <> {dayjs(rowData.row.original.started_on).format('MMM-DD-YYYY')} </>
      )
    },
    {
      accessor: 'ended_on',
      Header: 'End on',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => (
        <> {dayjs(rowData.row.original.ended_on).format('MMM-DD-YYYY')} </>
      )
    },
    {
      accessor: 'eligibility',
      Header: 'Eligibility',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'declared_via',
      Header: 'Declared Via',
      cellProps: {
        className: 'py-2'
      }
    }
    // {
    //   accessor: 'id',
    //   DonationHeader: '',
    //   cellProps: {
    //     className: 'py-2 text-end'
    //   },
    //   disableSortBy: true,

    //   Cell: rowData => {
    //     const { id } = rowData.row;
    //     return (
    //       <TableRowAction
    //         onCLickEdit={() => console.log(id)}
    //         onCLickDelete={() => console.log(id)}
    //       />
    //     );
    //   }
    // }
  ];

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="p-4 pb-0">
          <Card.Title className="fs-2 fw-bold">Insights</Card.Title>
        </Card.Header>
        <Card.Body className="p-4">
          <Row>
            <Col md={4} className="pe-4">
              <Flex className={'border-md-end mb-3 mb-md-0'}>
                <FontAwesomeIcon
                  icon={'circle'}
                  className="me-2 text-primary"
                />
                <div className="fw-medium">
                  <p className="mb-2 text-600">Eligible For Gift Aid?</p>
                  <p className="fs-2 mb-0 text-900">
                    {eligibilityData?.orders[0]?.gift_aid_eligible
                      ? 'Yes'
                      : 'No'}{' '}
                    <FontAwesomeIcon
                      icon={'check'}
                      className="text-success border p-1 fs-0 rounded-5 border-success"
                    />
                  </p>
                </div>
              </Flex>
            </Col>
            <Col md={4} className="pe-4">
              <Flex className={'border-md-end mb-3 mb-md-0'}>
                <FontAwesomeIcon
                  icon={'circle'}
                  className="me-2 text-warning"
                />
                <div className="fw-medium">
                  <p className="mb-2 text-600">Awaiting Submission</p>
                  <p className="fs-2 mb-0 text-900">
                    $ {awaitingSubmissionData?.gift_aid[0]?.amount || 0}
                  </p>
                </div>
              </Flex>
            </Col>
            <Col md={4}>
              <Flex>
                <FontAwesomeIcon
                  icon={'circle'}
                  className="me-2 text-warning"
                />
                <div className="fw-medium">
                  <p className="mb-2 text-600">Claimed To Date</p>
                  <p className="fs-2 mb-0 text-900">$0</p>
                </div>
              </Flex>
            </Col>
          </Row>
          <hr />
          <div>
            <p className="fs-2 fw-bold">Current Declarations</p>
            <div className="fw-medium">
              <Flex justifyContent={'between'}>
                <p className="text-800">Eligible</p>
                <p className="text-800">
                  <FontAwesomeIcon
                    icon={'circle'}
                    className={classNames('me-2', {
                      'text-success ':
                        declarationLogData?.declaration_log[0]?.eligibility ===
                        'Yes',
                      'text-danger ':
                        declarationLogData?.declaration_log[0]?.eligibility ===
                        'No'
                    })}
                  />
                  {declarationLogData?.declaration_log[0]?.eligibility}
                </p>
              </Flex>
              <Flex justifyContent={'between'}>
                <p className="text-800">Valid From</p>
                <p className="text-800">
                  {dayjs(
                    declarationLogData?.declaration_log[0]?.started_on
                  ).format('MMM-DD-YYYY')}{' '}
                </p>
              </Flex>
              <Flex justifyContent={'between'}>
                <p className="text-800">Declration Date</p>
                <p className="text-800">
                  {dayjs(
                    declarationLogData?.declaration_log[0]?.created_at
                  ).format('MMM-DD-YYYY')}{' '}
                </p>
              </Flex>
              <Flex justifyContent={'between'}>
                <p className="text-800">End Date</p>
                <p className="text-800">
                  {' '}
                  {dayjs(
                    declarationLogData?.declaration_log[0]?.ended_on
                  ).format('MMM-DD-YYYY')}{' '}
                </p>
              </Flex>
              {/* <Flex justifyContent={'between'}>
                <p className="text-800">Notes</p>
                <FontAwesomeIcon icon={'pen'} className="text-800" />
              </Flex>
              <Flex justifyContent={'between'}>
                <p className="text-800">Attachments</p>
                <FontAwesomeIcon icon={'pen'} className="text-800" />
              </Flex> */}
              <Flex justifyContent={'between'}>
                <p className="text-800 mb-0">Status</p>
                <p
                  className={classNames('mb-0', {
                    'text-success': dayjs().isBefore(
                      declarationLogData?.declaration_log[0]?.ended_on
                    ),
                    'text-danger': dayjs().isAfter(
                      declarationLogData?.declaration_log[0]?.ended_on
                    )
                  })}
                >
                  {' '}
                  {dayjs().isBefore(
                    declarationLogData?.declaration_log[0]?.ended_on
                  )
                    ? 'Active'
                    : 'Not-Active'}{' '}
                </p>
              </Flex>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {declarationLogData?.declaration_log && (
            <CustomAdvanceTable
              data={declarationLogData?.declaration_log}
              columns={columns}
              title="Declarations log"
              showSearchInput
              searchInputPlaceHolder="Search"
              // addNew
              perPage={10}
              subject={permissionsKeyMap.donor.children.donors}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default DonorGiftAid;
