import { useLazyQuery, useMutation } from '@apollo/client';
import TableRowAction from 'components/common/TableRowAction';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import dayjs from 'dayjs';
import { parseRouteParams } from 'helpers/utils';
import useBranchPermission from 'hooks/useBranchPermission';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import { DELETE_SCHOOL } from '../mutations';
import { GET_SCHOOLS } from '../queries';

function SchoolList() {
  const schoolColumns = [
    {
      accessor: 'id',
      Header: 'ID',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id, simple_id } = rowData.row.original;
        return (
          <Link
            to={`/school/${id}/details`}
            className="fw-medium text-1100 hover-primary"
          >
            SCH{simple_id}
          </Link>
        );
      }
    },
    {
      accessor: 'school_name',
      Header: 'School Name',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'students',
      Header: 'Number of students',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData =>
        rowData.row.original.school_students_aggregate.aggregate.count
    },
    {
      accessor: 'created_at',
      Header: 'Registered date',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <>{dayjs(created_at).format('DD-MM-YYYY')}</>;
      }
    },
    {
      accessor: 'school_address',
      Header: 'Address',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'country',
      Header: 'Country',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'school_email',
      Header: 'Email',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { school_email } = rowData.row.original;
        return <a href={`mailto:${school_email}`}>{school_email}</a>;
      }
    },
    {
      accessor: 'school_number',
      Header: 'Phone',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { school_number } = rowData.row.original;
        return <a href={`tel:${school_number}`}>{school_number}</a>;
      }
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickEdit={() =>
              navigate(
                parseRouteParams(paths.editSchool, {
                  id
                })
              )
            }
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.facilities.children.school}
          />
        );
      }
    }
  ];

  const navigate = useNavigate();

  const { where } = useBranchPermission();

  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [getSchool, { data: SchoolData }] = useLazyQuery(GET_SCHOOLS);

  useEffect(() => {
    getSchool({
      variables: { where }
    });
  }, [getSchool, where]);

  const [deleteSchool] = useMutation(DELETE_SCHOOL, {
    onCompleted: () => {
      toast.success('School Deleted Successfully');
    },
    onError: error => {
      toast.error('Something went wrong');
      console.error(error);
    },
    update: cache => {
      const existingSchool = cache.readQuery({
        query: GET_SCHOOLS,
        variables: { where }
      });

      if (!existingSchool) return;

      cache.writeQuery({
        query: GET_SCHOOLS,
        variables: { where },
        data: {
          schools: existingSchool?.schools.filter(
            item => item.id !== selectedId
          )
        }
      });
    }
  });

  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const onDelete = () => {
    // delete call
    deleteSchool({ variables: { id: selectedId } });
    setShowDeleteModal(false);
  };

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        handleConfirm={onDelete}
        title="Delete School"
        body="Are you sure you want to delete this School?"
      />
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {SchoolData?.schools && (
            <CustomAdvanceTable
              data={SchoolData?.schools}
              columns={schoolColumns}
              title="Schools"
              addButtonLabel="Add School"
              onClickAddNew={() => {
                navigate(paths.addSchool);
              }}
              // loading={loading}
              addNew
              addButtonIcon={'book'}
              subject={permissionsKeyMap.facilities.children.school}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default SchoolList;
