import React, { useState } from 'react';
import SelectStudentModal from './SelectStudentModal';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SCHOOL_STUDENTS } from '../queries';
import { useParams } from 'react-router-dom';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import TableRowAction from 'components/common/TableRowAction';
import {
  ADD_SCHOOL_STUDENT_BY_ORPHAN_ID,
  DELETE_SCHOOL_STUDENT_BY_SCHOOL
} from '../mutations';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useAuthContext } from 'context/authContext';

const StudentsTable = () => {
  const { id } = useParams();
  const { organization, selectedBranchId, user } = useAuthContext();
  const [showStudentAddModal, setShowStudentAddModal] = useState(false);

  const { data: schoolStudentData, loading } = useQuery(GET_SCHOOL_STUDENTS, {
    variables: {
      id
    }
  });

  const [deleteStudentSchool] = useMutation(DELETE_SCHOOL_STUDENT_BY_SCHOOL, {
    refetchQueries: ['school_students'],
    onCompleted: () => {
      toast.success('deleted successfully');
    },
    onError: error => {
      toast.error('Something went wrong! Please try again');
      console.error(error);
    }
  });

  const calculateAge = DOB => {
    const birthDate = dayjs(DOB);
    const currentDate = dayjs();
    const age = currentDate.diff(birthDate, 'year');
    return age;
  };

  const removeStudent = orphanId => {
    deleteStudentSchool({
      variables: {
        id: orphanId
      },
      update: cache => {
        const existingStudentSchool = cache.readQuery({
          query: GET_SCHOOL_STUDENTS,
          variables: { id }
        });

        if (existingStudentSchool) {
          const updatedStudents = existingStudentSchool.school_students.filter(
            student => student.orphan.id !== orphanId
          );

          cache.writeQuery({
            query: GET_SCHOOL_STUDENTS,
            variables: { id },
            data: {
              school_students: updatedStudents
            }
          });
        }
      }
    });
  };

  const schoolColumns = [
    {
      accessor: 'full_name',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { first_name, last_name } = rowData.row.original;
        return <>{first_name + ' ' + last_name}</>;
      }
    },
    {
      accessor: 'DOB',
      Header: 'Age',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { DOB } = rowData.row.original;
        return <>{calculateAge(DOB)}</>;
      }
    },
    {
      accessor: 'gender',
      Header: 'Gender',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { gender } = rowData.row.original;
        return <>{gender}</>;
      }
    },
    {
      accessor: 'created_at',
      Header: 'Registration',
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <>{dayjs(created_at).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'inspiration',
      Header: 'Aspiration',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { inspiration } = rowData.row.original;
        return <>{inspiration}</>;
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return <>{status}</>;
      }
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickDelete={() => removeStudent(id)}
            isNotEdit
            subject={permissionsKeyMap.facilities.children.school}
          />
        );
      }
    }
  ];

  const [addSchoolStudent, { addSchoolloading }] = useMutation(
    ADD_SCHOOL_STUDENT_BY_ORPHAN_ID,
    {
      refetchQueries: [{ query: GET_SCHOOL_STUDENTS, variables: { id } }],
      onCompleted: () => {
        toast.success('School Added Successfully');
      },
      onError: error => {
        toast.error('Something went wrong');
        console.error(error);
      }
    }
  );

  const submitStudent = value => {
    addSchoolStudent({
      variables: {
        school_id: id,
        orphan_id: value,
        branch_id: selectedBranchId,
        organisation_id: organization.id,
        user_id: user.id
      }
    });
  };

  return (
    <>
      {!loading && (
        <CustomAdvanceTable
          loading={loading}
          data={
            schoolStudentData?.school_students.map((student, index) => ({
              ...student?.orphan,
              key: `${student?.orphan?.id}-${index}`
            })) || []
          }
          columns={schoolColumns}
          title="Orphans"
          addButtonLabel="Add Students"
          addButtonIcon="user"
          onClickAddNew={() => {
            setShowStudentAddModal(true);
          }}
          addNew
          subject={permissionsKeyMap.facilities.children.school}
        />
      )}

      <SelectStudentModal
        show={showStudentAddModal}
        onHide={() => setShowStudentAddModal(false)}
        onSubmit={submitStudent}
        addSchoolloading={addSchoolloading}
      />
    </>
  );
};

export default StudentsTable;
