import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

import { Controller, useFormContext } from 'react-hook-form';

export function RHFUploadFile({ required, label, name, ...rest }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, ...restFieldProps },
        fieldState: { error }
      }) => {
        return (
          <Form.Group>
            <Form.Label>
              {label} {required && <span className="text-danger">*</span>}
              {!required && <span className="text-500">(optional)</span>}
            </Form.Label>
            <Form.Control
              type="file"
              onChange={e => onChange(e.target.files)}
              files={value}
              {...restFieldProps}
              {...rest}
              isInvalid={!!error}
            />
            {error && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {error.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        );
      }}
    />
  );
}

RHFUploadFile.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  accept: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool
};
