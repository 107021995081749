import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import AuthContext from 'context/authContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { INSERT_HOSPITAL } from '../mutations';
import HospitalForm from './HospitalForm';
import useUpload from 'hooks/useUpload';
import paths from 'routes/paths';
import useBranchPermission from '../../../hooks/useBranchPermission';
import { GET_HOSPITAL_LIST } from '../queries';

function AddHospital() {
  const { organization } = useContext(AuthContext);
  const navigate = useNavigate();
  const { upload, loading: uploadLoading } = useUpload();
  const { where } = useBranchPermission();

  const [createHospital, { loading }] = useMutation(INSERT_HOSPITAL, {
    refetchQueries: [{ query: GET_HOSPITAL_LIST, variables: { where } }],
    onCompleted: () => {
      toast.success('Hospital Created Successfully');
      navigate(paths.hospital);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.error(error);
    }
  });

  const submitAddHospital = async data => {
    if (data.hospital_document && typeof data.hospital_document !== 'string') {
      const hospital_document = await upload(data.hospital_document);
      data.hospital_document = hospital_document;
    }

    createHospital({
      variables: {
        ...data,
        name: data.hospitalName,
        phone: data.contact,
        document: data.hospital_document,
        organisation_id: organization.id
      }
    });
  };

  return (
    <HospitalForm
      onSubmit={submitAddHospital}
      loading={loading || uploadLoading}
    />
  );
}

export default AddHospital;
