function LocationDotIcon() {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.78328 8.32296C7.78328 8.32296 7.92267 8.18345 8.20144 7.90444C8.48021 7.62542 8.6196 7.09157 8.6196 6.30287C8.6196 5.51418 8.34083 4.84082 7.78328 4.28279C7.22573 3.72475 6.55296 3.44573 5.76496 3.44573C4.97695 3.44573 4.30418 3.72475 3.74663 4.28279C3.18908 4.84082 2.91031 5.51418 2.91031 6.30287C2.91031 7.09157 3.18908 7.76493 3.74663 8.32296C4.30418 8.881 4.97695 9.16002 5.76496 9.16002C6.55296 9.16002 7.22573 8.881 7.78328 8.32296ZM11.4742 6.30287C11.4742 7.11389 11.3516 7.77981 11.1063 8.30064L7.04732 16.939C6.92837 17.1846 6.75181 17.378 6.51764 17.5194C6.28347 17.6608 6.03258 17.7314 5.76496 17.7314C5.49733 17.7314 5.24644 17.6608 5.01227 17.5194C4.7781 17.378 4.60526 17.1846 4.49375 16.939L0.423646 8.30064C0.178325 7.77981 0.0556641 7.11389 0.0556641 6.30287C0.0556641 4.72549 0.613212 3.37877 1.72831 2.2627C2.8434 1.14663 4.18895 0.588589 5.76496 0.588589C7.34096 0.588589 8.68651 1.14663 9.8016 2.2627C10.9167 3.37877 11.4742 4.72549 11.4742 6.30287Z"
        fill="currentColor"
      />
    </svg>
  );
}

export { LocationDotIcon };
