import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { useMutation } from '@apollo/client';
import { INSERT_ORDER_DOANTION } from './mutations';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import { DONATION_STEPS } from './data';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import { DonationHeader } from './DonationHeader';
import { FooterImages, PrivacyPolicy } from './DonationFooter';

function DonationPaymentPage() {
  const [date, setDate] = useState('');
  const { organizationId } = useParams();

  const { setDonationStep, basket, setBasket, donor, transactionFeeAmount } =
    useDonationContextProvider();

  const [createDonorOrders, { loading: donationLoading }] = useMutation(
    INSERT_ORDER_DOANTION,
    {
      onCompleted: () => {
        toast.success('Donation created.');
        setDonationStep(DONATION_STEPS.THANKS);
        setBasket([]);
      },
      onError: err => {
        console.log(err);
        toast.error('Something went wrong');
      }
    }
  );

  const formatExpirationDate = e => {
    // Remove any non-digit characters

    const sanitizedDate = e.target.value?.replace(/\D/g, '');
    // Extract the month and year
    const month = sanitizedDate.slice(0, 2);

    const year = sanitizedDate.slice(2, 4);
    // Return the formatted date
    setDate(`${month}/${year}`);
  };

  const onSave = () => {
    const subtotal = basket.reduce(
      (acc, curr) => acc + curr.count * curr.donationAmount.value,
      0
    );

    const orderItems = basket.map(item => {
      const orderItem = {
        amount: item.count * item.donationAmount.value,
        billing_condition: item.paymentType,
        donation_type: item.donationType.id
      };
      if (item.packageId) {
        orderItem.package_id = item.packageId;
      }
      return orderItem;
    });

    const totalAmount = subtotal + transactionFeeAmount;

    createDonorOrders({
      variables: {
        address: [
          {
            city: donor.city,
            county: donor.country,
            postcode: donor.postcode,
            isPrimary: true,
            addressLine1: donor.addressLine1,
            addressLine2: donor.addressLine2
          }
        ],
        last_name: donor.lastName,
        phone: [
          {
            type: 'personal',
            isPrimary: true,
            phoneNumber: donor.phoneNumber
          }
        ],
        organisation_id: organizationId,
        email: [{ email: donor.email, isPrimary: true, type: 'personal' }],
        first_name: donor.firstName,
        title: donor.title,
        order_details: { data: orderItems },
        subtotal,
        transaction_fees: transactionFeeAmount,
        donation_amount: totalAmount
      }
    });
  };

  return (
    <Form onSubmit={e => e.preventDefault()}>
      <DonationHeader />
      <div className="wr-donation-payment-container">
        <div className="rounded-12 p-24 d-flex flex-column gap-2 wr-donation-shadow">
          <div>
            <h2 className="wr-donation-payment-method-header">
              Payment Method
            </h2>
            <div className="d-flex gap-2 mb-4 justify-content-between justify-content-md-start">
              <IconButton
                className="p-2 w-144 rounded-3 fs--1 fw-semi-bold bg-wr-donation-primary"
                icon="credit-card"
                iconClassName="me-2 text-white"
              >
                Credit Card
              </IconButton>
              <IconButton
                className="p-2 w-144 rounded-3 fs--1 fw-semi-bold bg-wr-donation-grey-100 border-1 border-wr-donation-grey-200 wr-donation-text-grey-500"
                icon="fa-brands fa-paypal"
                iconClassName="me-2 wr-donation-text-grey-500"
              >
                PayPal
              </IconButton>
            </div>
            <div className="py-4 px-3 rounded-4 bg-wr-donation-grey-600">
              <p className="wr-donation-subtitle1 wr-donation-text-grey-1000 mb-2">
                Add new card
              </p>
              <Row className="g-3">
                <Form.Group as={Col} md={12} className="position-relative">
                  <FontAwesomeIcon
                    icon="credit-card"
                    className="card-input-icon fs-1 text-500"
                  />
                  <Form.Control
                    required
                    type="number"
                    className="h-52 ps-5"
                    placeholder="Card Number"
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Control
                    required
                    type="text"
                    placeholder="MM/YY"
                    className="h-52"
                    value={date}
                    onChange={formatExpirationDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    EXP DATE required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Control
                    required
                    type="number"
                    className="h-52"
                    placeholder="Cvv"
                  />
                </Form.Group>
              </Row>
            </div>
          </div>
          <p className="wr-donation-text-grey-700">
            Give with confidence. Our secure payment system ensure your personal
            details are kept safe and secure
          </p>
          <Form.Check type="checkbox" id="stay_anonymous" className="my-0">
            <Form.Check.Input type="checkbox" isValid />
            <Form.Check.Label className="ms-1 wr-donation-body1 wr-donation-text-grey-1000">
              Use same personal details for payment
            </Form.Check.Label>
          </Form.Check>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4 mb-5 flex-column flex-md-row">
          <Button
            className="h-52 border-0 w-215px bg-wr-donation-grey-150 wr-donation-text-grey-800"
            onClick={() => setDonationStep(DONATION_STEPS.DONOR_INFORMATION)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="h-52 mt-3 mt-md-0 border-0 w-215px bg-wr-donation-primary"
            onClick={onSave}
          >
            {donationLoading ? 'Loading...' : 'Pay Now'}
          </Button>
        </div>
        <div className="mb-5">
          <FooterImages />
        </div>
        <PrivacyPolicy />
      </div>
    </Form>
  );
}

export { DonationPaymentPage };
