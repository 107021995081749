import React, { useState } from 'react';

import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import { useMutation, useQuery } from '@apollo/client';
import { GET_MOSQUE_ORPHAN } from '../queries';
import { useParams } from 'react-router-dom';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import TableRowAction from 'components/common/TableRowAction';
import {
  ADD_MOSQUE_ORPHAN_BY_ORPHAN_ID,
  DELETE_MOSQUE_ORPHAN_BY_ORPHAN
} from '../mutations';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import SelectStudentModal from '../school/SelectStudentModal';

const OrphanTable = () => {
  const { id } = useParams();

  const [showStudentAddModal, setShowStudentAddModal] = useState(false);

  const { data: mosqueStudentData, loading } = useQuery(GET_MOSQUE_ORPHAN, {
    variables: {
      id
    }
  });

  const [addMosqueOrphan, { addLoading }] = useMutation(
    ADD_MOSQUE_ORPHAN_BY_ORPHAN_ID,
    {
      refetchQueries: [{ query: GET_MOSQUE_ORPHAN, variables: { id } }],
      onCompleted: () => {
        toast.success('Mosque Added Successfully');
      },
      onError: error => {
        toast.error('Something went wrong');
        console.log(error);
      }
    }
  );

  const [deleteOrphanMosque] = useMutation(DELETE_MOSQUE_ORPHAN_BY_ORPHAN, {
    refetchQueries: ['mosque_orphan'],
    onCompleted: () => {
      toast.success('deleted successfully');
    },
    onError: error => {
      toast.error('Something went wrong! Please try again');
      console.log(error);
    }
  });

  const calculateAge = DOB => {
    const birthDate = dayjs(DOB);
    const currentDate = dayjs();
    const age = currentDate.diff(birthDate, 'year');
    return age;
  };

  const removeStudent = orphanId => {
    deleteOrphanMosque({
      variables: {
        id: orphanId
      },
      update: cache => {
        const existingStudentMosque = cache.readQuery({
          query: GET_MOSQUE_ORPHAN,
          variables: { id }
        });

        if (existingStudentMosque) {
          const updatedStudents = existingStudentMosque.mosque_orphan.filter(
            student => student.orphan.id !== orphanId
          );

          cache.writeQuery({
            query: GET_MOSQUE_ORPHAN,
            variables: { id },
            data: {
              mosque_orphan: updatedStudents
            }
          });
        }
      }
    });
  };

  const columns = [
    {
      accessor: 'full_name',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { first_name, last_name } = rowData.row.original;
        return <>{first_name + ' ' + last_name}</>;
      }
    },
    {
      accessor: 'DOB',
      Header: 'Age',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { DOB } = rowData.row.original;
        return <>{calculateAge(DOB)}</>;
      }
    },
    {
      accessor: 'gender',
      Header: 'Gender',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { gender } = rowData.row.original;
        return <>{gender}</>;
      }
    },
    {
      accessor: 'created_at',
      Header: 'Registration',
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <>{dayjs(created_at).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'inspiration',
      Header: 'Aspiration',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { inspiration } = rowData.row.original;
        return <>{inspiration}</>;
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return <>{status}</>;
      }
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickDelete={() => removeStudent(id)}
            isNotEdit
            subject={permissionsKeyMap.facilities.children.mosque}
          />
        );
      }
    }
  ];

  const submitStudent = value => {
    addMosqueOrphan({
      variables: {
        mosque_id: id,
        orphan_id: value
      }
    });
  };

  return (
    <>
      {!loading && (
        <CustomAdvanceTable
          loading={loading}
          data={
            mosqueStudentData?.mosque_orphan.map((student, index) => ({
              ...student?.orphan,
              key: `${student?.orphan?.id}-${index}`
            })) || []
          }
          columns={columns}
          title="Orphans"
          addButtonLabel="Add Students"
          addButtonIcon="user"
          onClickAddNew={() => {
            setShowStudentAddModal(true);
          }}
          addNew
          subject={permissionsKeyMap.facilities.children.mosque}
        />
      )}

      <SelectStudentModal
        show={showStudentAddModal}
        onHide={() => setShowStudentAddModal(false)}
        onSubmit={submitStudent}
        addLoading={addLoading}
      />
    </>
  );
};

export default OrphanTable;
