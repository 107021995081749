import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import PackageForm from './package-form/PackageForm';
import LoadingContainer from 'components/common/LoadingContainer';
import paths from 'routes/paths';

const EDIT_PACKAGE = gql`
  mutation EditPackage(
    #    $currency_id: uuid = ""
    $donation_amount: jsonb = ""
    $donation_amounts: String = ""
    $donation_type: jsonb = ""
    $donation_types: String = ""
    #    $hasSuccessUrl: Boolean = false
    #    $hasTransactionFees: Boolean = false
    $location: jsonb = ""
    $package_description: String = ""
    $package_title: String = ""
    $payment_options: jsonb = ""
    #    $redirect_url: String = ""
    #    $transaction_fee: jsonb = ""
    #    $target_amount: float8 = 0
    $id: uuid = ""
  ) {
    update_packages_by_pk(
      pk_columns: { id: $id }
      _set: {
        #        currency_id: $currency_id
        donation_amount: $donation_amount
        donation_amounts: $donation_amounts
        donation_type: $donation_type
        donation_types: $donation_types
        #        hasSuccessUrl: $hasSuccessUrl
        #        hasTransactionFees: $hasTransactionFees
        location: $location
        #        package_category: $package_category
        package_description: $package_description
        package_title: $package_title
        payment_options: $payment_options
        #        redirect_url: $redirect_url
        #        transaction_fee: $transaction_fee
        #        target_amount: $target_amount
      }
    ) {
      branch_id
      created_at
      #      currency_id
      donation_amount
      donation_type
      #      hasSuccessUrl
      #      hasTransactionFees
      id
      location
      organisation_id
      #      package_category
      package_description
      package_title
      payment_options
      #      redirect_url
      status
      #      target_amount
      #      transaction_fee
    }
  }
`;

const GET_PACKAGE = gql`
  query GetPackage($id: uuid = "") {
    packages_by_pk(id: $id) {
      campaign
      #      currency_id
      donation_amount
      donation_amounts
      donation_type
      #      hasSuccessUrl
      #      hasTransactionFees
      id
      location
      organisation_id
      #      package_category
      package_description
      package_title
      payment_options
      #      redirect_url
      #      target_amount
      #      transaction_fee
    }
  }
`;

function EditPackage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading: dataLoading } = useQuery(GET_PACKAGE, {
    fetchPolicy: 'network-only',
    variables: {
      id
    }
  });
  const [editPackage, { loading: editLoading }] = useMutation(EDIT_PACKAGE, {
    onCompleted: () => {
      toast.success('Package updated successfully');
      navigate(paths.donationPackages);
    },
    onError: error => {
      toast.error(error.message);
      console.log(error);
    }
  });
  const onSubmit = data => {
    editPackage({
      variables: {
        id,
        ...data
      }
    });
  };
  return dataLoading ? (
    <LoadingContainer />
  ) : (
    <PackageForm
      onSubmit={onSubmit}
      loading={editLoading}
      isEdit
      data={data?.packages_by_pk}
    />
  );
}

export default EditPackage;
