import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { GET_DONATION_TYPE } from 'components/Home/queries';

import { Row, Col, Button } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { FormProvider, RHFDatePicker, RHFSelect } from 'components/common/form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useAuthContext } from 'context/authContext';

import { DONATION_STATUS } from './data';

const formSchema = Yup.object().shape({
  type: Yup.string(),
  date: Yup.object().shape({
    from: Yup.string(),
    to: Yup.string()
  })
});

function DonationsFilterForm({ onFilter }) {
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      type: '',
      status: '',
      date: {
        from: '',
        to: ''
      }
    },
    resolver: yupResolver(formSchema)
  });

  const { organization } = useAuthContext();

  const { data: donationTypesData } = useQuery(GET_DONATION_TYPE, {
    variables: {
      organizationId: organization.id
    }
  });

  const donationTypeOptions = useMemo(
    () =>
      donationTypesData?.donation_type?.map(({ id, donation_type_name }) => ({
        label: donation_type_name,
        value: id
      })) || [],
    [donationTypesData]
  );

  const statusOptions = Object.keys(DONATION_STATUS).map(key => ({
    label: key,
    value: DONATION_STATUS[key]
  }));

  return (
    <FormProvider methods={form} onSubmit={form.handleSubmit(onFilter)}>
      <Row className="mb-2">
        <Col md={4}>
          <RHFSelect
            name="type"
            label="Donation Type"
            options={donationTypeOptions}
            noOptional={true}
            isClearable={true}
          />
        </Col>

        <Col md={3}>
          <RHFSelect
            name="status"
            label="Status"
            options={statusOptions}
            noOptional={true}
            isClearable={true}
          />
        </Col>

        <Col md={5}>
          <div className="d-flex flex-column flex-md-row flex-wrap gap-2">
            <RHFDatePicker
              name="date.from"
              label="Date From"
              noOptional={true}
              className="flex-1"
            />
            <RHFDatePicker
              name="date.to"
              label="Date To"
              noOptional={true}
              className="flex-1"
            />
          </div>
        </Col>
      </Row>
      <div className="d-flex gap-2 justify-content-end">
        <Button
          size="sm"
          variant="secondary"
          type="button"
          onClick={() => {
            form.reset();
            form.handleSubmit(onFilter)();
          }}
        >
          Clear
        </Button>
        <Button size="sm" type="submit">
          Filter
        </Button>
      </div>
    </FormProvider>
  );
}

DonationsFilterForm.propTypes = {
  onFilter: PropTypes.func
};

export default DonationsFilterForm;
