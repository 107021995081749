import React from 'react';
import MosqueForm from './MosqueForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CONTACT, GET_MOSQUE, GET_MOSQUE_LIST } from '../queries';
import { INSERT_CONTACT, UPDATE_CONTACT, UPDATE_MOSQUE } from '../mutations';
import { toast } from 'react-toastify';
import useUpload from 'hooks/useUpload';
import ContactForm from '../school/ContactForm';
import useBranchPermission from '../../../hooks/useBranchPermission';

function EditMosque() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { upload, loading: uploadLoading } = useUpload();
  const { where } = useBranchPermission();

  const { data: mosqueData } = useQuery(GET_MOSQUE, {
    fetchPolicy: 'no-cache',
    variables: {
      id
    }
  });

  const { data: contactData } = useQuery(GET_CONTACT, {
    variables: {
      where: {
        mosque_id: { _eq: id }
      }
    }
  });

  const [updateMosque, { loading }] = useMutation(UPDATE_MOSQUE, {
    refetchQueries: [{ query: GET_MOSQUE_LIST, variables: { where } }],
    onCompleted: () => {
      toast.success('Mosque Updated Successfully');
      navigate(`/mosque/${id}/details`);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const [updateContact, { loading: updateContactLoading }] = useMutation(
    UPDATE_CONTACT,
    {
      onCompleted: () => {
        toast.success('Contact Updated Successfully');
        navigate(`/mosque/${id}/details`);
      },
      onError: error => {
        toast.error('Something went wrong');
        console.error(error);
      }
    }
  );

  const [insertContact, { loading: insertContactLoading }] = useMutation(
    INSERT_CONTACT,
    {
      onCompleted: () => {
        toast.success('Contact Added Successfully');
        navigate(`/mosque/${id}/details`);
      },
      onError: error => {
        toast.error('Something went wrong');
        console.error(error);
      }
    }
  );

  const submitContact = values => {
    if (contactData?.contact?.length !== 0) {
      updateContact({
        variables: {
          ...values,
          where: { mosque_id: { _eq: id } }
        }
      });
    } else {
      insertContact({
        variables: {
          object: {
            ...values,
            mosque_id: id
          }
        }
      });
    }
  };

  const submitEditMosque = async data => {
    if (data.document && typeof data.document !== 'string') {
      const document = await upload(data.document);
      data.document = document;
    }

    updateMosque({
      variables: {
        ...data,
        id
      }
    });
  };

  return (
    <>
      <MosqueForm
        onSubmit={submitEditMosque}
        loading={loading || uploadLoading}
        initValues={mosqueData?.mosques_by_pk || {}}
      />

      <ContactForm
        initValues={contactData?.contact}
        onSubmit={submitContact}
        loading={updateContactLoading || insertContactLoading}
      />
    </>
  );
}

export default EditMosque;
