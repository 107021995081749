import { Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LocationDotIcon } from 'assets/icons/location-dot';

import helpYateemLightLogo from 'assets/img/we-raise-images/help-yateem-light.png';
import footerPaymentImage from 'assets/img/we-raise-images/footer-payment.png';
import footerLeftImage from 'assets/img/we-raise-images/frontEndFooterLeft.png';
import footerRightImage from 'assets/img/we-raise-images/frontEndFooterRight.png';

function DonationFooter() {
  return (
    <div className="bg-wr-donation-primary">
      <div className="container pt-5 pb-6">
        <Row>
          <Col sm={6} md={4}>
            <Image
              src={helpYateemLightLogo}
              alt="Help Yateem"
              className="mb-1"
            />
            <p className="m-0 text-white wr-donation-body3">
              Helping Orphans Break Free From the Cycle of
              <span className="wr-donation-text-yellow-300"> Poverty</span>
            </p>
          </Col>
          <Col sm={6} md={4} className="d-flex flex-column gap-3">
            <h3 className="m-0 text-white text-uppercase wr-donation-body3">
              Contact Details
            </h3>
            <p className="m-0 text-white">
              <FontAwesomeIcon className="me-2" icon="envelope" />
              <a
                href="mailto:INFO@HELPYATEEM.ORG"
                className="text-white wr-donation-body4"
              >
                INFO@HELPYATEEM.ORG
              </a>
            </p>
            <p className="m-0 text-white">
              <FontAwesomeIcon className="me-2" icon="phone" />
              <a
                href="tel:(+44) 0203 004 9013"
                className="text-white wr-donation-body4"
              >
                (+44) 0203 004 9013
              </a>
            </p>
            <p className="m-0 text-white">
              <span className="me-2">
                <LocationDotIcon />
              </span>
              ST. LUKES BUSINESS CENTER, SUIT 2, 85 TARLING ROAD, LONDON, E16 1
              HN
            </p>
          </Col>
          <Col sm={12} md={4}>
            <Image src={footerPaymentImage} alt="Payment Methods" />
          </Col>
        </Row>
      </div>
    </div>
  );
}

function FooterImages() {
  return (
    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
      <Image src={footerLeftImage} height="61px" className="mb-4 mb-md-0" />
      <Image
        src={footerRightImage}
        height="84px"
        style={{ maxWidth: '362px' }}
        className="w-100 h-100"
      />
    </div>
  );
}

function PrivacyPolicy() {
  return (
    <p className="wr-donation-privacy-policy">
      Privacy Policy | Other ways to donate (Telephone ,post & bank transfer)
      <br />© Copyrights 2022 Islamic Relief Worldwide, Inc. All rights
      reserved. Registered Charity No. 328158
    </p>
  );
}

export { DonationFooter, FooterImages, PrivacyPolicy };
