import TableRowAction from 'components/common/TableRowAction';
import React, { useContext, useEffect, useState } from 'react';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import SelectStudentModal from '../school/SelectStudentModal';

import { useLazyQuery, useMutation } from '@apollo/client';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import AuthContext from 'context/authContext';
import dayjs from 'dayjs';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { INSERT_ORPHANAGE, INSERT_ORPHANAGE_ORPHAN } from '../mutations';
import { GET_BENEFICIARY_BY_ID, GET_ORPHANAGE_LIST } from '../queries';
import OrphanageForm from './OrphanageForm';
import useUpload from '../../../hooks/useUpload';
import useBranchPermission from '../../../hooks/useBranchPermission';

function AddOrphanage() {
  const [showStudentAddModal, setShowStudentAddModal] = useState(false);
  const { organization } = useContext(AuthContext);
  const navigate = useNavigate();
  const { upload, loading: uploadLoading } = useUpload();
  const { where } = useBranchPermission();

  const schoolColumns = [
    {
      accessor: 'first_name',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'DOB',
      Header: 'Age',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { DOB } = rowData.row.original;
        return <>{dayjs(DOB).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'gender',
      Header: 'Gender'
    },
    {
      accessor: 'created_at',
      Header: 'Registration',
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <>{dayjs(created_at).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'inspiration',
      Header: 'Aspiration'
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickDelete={() => removeStudent(id)}
            isNotEdit
            subject={permissionsKeyMap.facilities.children.orphanage}
          />
        );
      }
    }
  ];
  const [listOfStudents, setListOfStudents] = useState([]);

  const removeStudent = id => {
    setListOfStudents(listOfStudents.filter(item => item.id !== id));
  };
  const [getBeneficiaryById, { data: dataBeneficiary }] = useLazyQuery(
    GET_BENEFICIARY_BY_ID
  );

  useEffect(() => {
    if (dataBeneficiary?.orphans_by_pk) {
      setListOfStudents([...listOfStudents, dataBeneficiary?.orphans_by_pk]);
    }
  }, [dataBeneficiary]);

  const [addOrphanageOrphan] = useMutation(INSERT_ORPHANAGE_ORPHAN, {
    onCompleted: () => {
      toast.success('Orphans Added Successfully');
      navigate(-1);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const [createOrphanage, { loading }] = useMutation(INSERT_ORPHANAGE, {
    refetchQueries: [{ query: GET_ORPHANAGE_LIST, variables: { where } }],
    onCompleted: data => {
      toast.success('Orphanage Created Successfully');
      const tmp = listOfStudents.map(item => {
        return {
          orphanage_id: data.insert_orphanages_one.id,
          orphan_id: item.id
        };
      });
      if (tmp.length !== 0) addOrphanageOrphan({ variables: { objects: tmp } });
      else navigate(-1);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });
  const submitStudent = value => {
    getBeneficiaryById({
      variables: {
        id: value
      }
    });
  };

  const submitAddOrphanage = async data => {
    if (data.document && typeof data.document !== 'string') {
      data.document = await upload(data.document);
    }
    createOrphanage({
      variables: {
        ...data,
        organisation_id: organization.id
      }
    });
  };
  return (
    <>
      <OrphanageForm
        onSubmit={submitAddOrphanage}
        loading={loading || uploadLoading}
      />
      <SelectStudentModal
        show={showStudentAddModal}
        onHide={() => setShowStudentAddModal(false)}
        onSubmit={submitStudent}
      />
      <Row className="mt-3">
        <Col md={12} xxl={12} className="h-md-100">
          {listOfStudents && (
            <CustomAdvanceTable
              data={listOfStudents}
              columns={schoolColumns}
              title="Orphans"
              addButtonLabel="Add Orphan"
              addButtonIcon="user"
              onClickAddNew={() => {
                setShowStudentAddModal(true);
              }}
              addNew
              subject={permissionsKeyMap.facilities.children.school}
              bulkActionsOptions
              handleBulkAction
              selection={true}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default AddOrphanage;
