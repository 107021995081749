import PropTypes from 'prop-types';
import { useState, useRef, useEffect, useMemo, useContext } from 'react';
import { Card, Col, Row, Nav } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { FormProvider } from 'components/common/form';
import { packageScheme } from './packageScheme';
import { PackagesDetails } from './PackagesDetails';
import { ChecksOptionsCard } from '../../../common/ChecksOptionsCard';
import {
  GET_CAMPAIGNS,
  GET_DONATION_AMOUNTS,
  GET_DONATION_TYPES,
  GET_LOCATIONS
  // GET_PACKAGE_CATEGORIES
  // GET_TRANSACTION_FEES
} from './package-options-queries';
import { locationTypesMap } from 'components/donations/donation-settings/Locations';
import AuthContext from 'context/authContext';
import paths from 'routes/paths';
import { donationSettingsSectionsRefMap } from 'components/donations/donation-settings';
import LoadingButton from 'components/common/LoadingButton';

export const PAYMENT_OPTION_ONE_OFF = 'one-off';
export const PAYMENT_OPTION_MONTHLY = 'monthly';
export const PAYMENT_OPTION_YEARLY = 'yearly';

const paymentOptions = [PAYMENT_OPTION_ONE_OFF, PAYMENT_OPTION_MONTHLY];

export const getTrueKeys = obj => {
  const trueKeys = [];
  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      trueKeys.push(key);
    }
  });
  return trueKeys;
};

// const convertArrToObj = arr => {
//   const obj = {};
//   arr.forEach(item => {
//     obj[item] = true;
//   });
//   return obj;
// };

// const getObjInitialValues = (data,checkboxes) => {
//   const obj = {};
//   data.forEach(item => {
//     const itemExists = checkboxes.find(checkbox => checkbox.value === item);

const PackageForm = ({ onSubmit, loading, isEdit, data }) => {
  const navigate = useNavigate();
  const { organization } = useContext(AuthContext);
  const { data: donationLocationsData } = useQuery(GET_LOCATIONS, {
    variables: {
      type: locationTypesMap.project,
      organizationId: organization.id
    }
  });
  /* Hidden for now
  const { data: donationCategoriesData } = useQuery(GET_PACKAGE_CATEGORIES, {
    variables: {
      organizationId: organization.id
    }
  });
   */
  const { data: donationCampaignsData } = useQuery(GET_CAMPAIGNS, {
    variables: {
      organizationId: organization.id
    }
  });
  const { data: donationTypesData } = useQuery(GET_DONATION_TYPES, {
    variables: {
      organizationId: organization.id
    }
  });
  const { data: donationAmountsData } = useQuery(GET_DONATION_AMOUNTS, {
    variables: {
      organizationId: organization.id
    }
  });
  // const { data: transactionFeesData } = useQuery(GET_TRANSACTION_FEES, {
  //   variables: {
  //     organizationId: organization.id,
  //     branchId: selectedBranchId
  //   }
  // });

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      title: '',
      /*
      currency: '',
      targetAmount: '',
       */
      description: '',
      // selectAll: false,
      paymentOptions: {
        [PAYMENT_OPTION_ONE_OFF]: false,
        [PAYMENT_OPTION_MONTHLY]: false,
        [PAYMENT_OPTION_YEARLY]: false
      },
      locations: {
        all: false
      },
      /* categories: {}, Hidden for now */
      campaigns: {},
      donationTypes: {},
      donationAmounts: {},
      // openTransactionFee: false,
      // transactionFees: {},
      // openRedirectUrl: false,
      // redirectUrl: '',
      has_custom_donation_amount: false
    },
    resolver: yupResolver(packageScheme)
  });
  const { handleSubmit: RHFHandleSubmit, setValue } = methods;

  // const openTransactionFee = watch('openTransactionFee');
  // const openRedirectUrl = watch('openRedirectUrl');

  // const handleOpenTransactionFee = e => {
  //   setValue('openTransactionFee', e.target.checked);
  // };
  // const handleOpenRedirectUrl = e => {
  //   setValue('openRedirectUrl', e.target.checked);
  // };
  // useEffect(() => {
  //   if (data) {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  const [stickyStatus, setStickyStatus] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 100) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, []);

  const sideNavLinks = [
    { id: 1, href: '#details', title: 'Packages Details' },
    // { id: 2, href: '#branches', title: 'Branches' },
    { id: 2, href: '#payments', title: 'Payments Options' },
    { id: 3, href: '#location', title: 'Locations' },
    /*
    { id: 4, href: '#category', title: 'Categories' },
     */
    { id: 5, href: '#campaigns', title: 'Campaigns' },
    { id: 6, href: '#donationType', title: 'Donation Types' },
    { id: 7, href: '#donationAmounts', title: 'Donation Amounts' }
    // { id: 8, href: '#transactionFee', title: 'Transaction Fees' },
    // {
    //   id: 9,
    //   href: '#successRedirection',
    //   title: 'Success Redirection'
    // }
  ];
  const locationCheckboxes = useMemo(() => {
    const checkboxes = donationLocationsData?.location?.map(location => {
      if (isEdit) {
        const itemExists = data.location?.find(
          location_id => location_id === location.id
        );
        setValue(`locations.${location.id}`, itemExists ? true : false);
      } else {
        setValue(`locations.${location.id}`, false);
      }
      return {
        name: `locations.${location.id}`,
        label: location.location_name
      };
    });

    return [{ name: 'locations.all', label: 'All' }, ...(checkboxes || [])];
  }, [donationLocationsData, data?.location, isEdit, setValue]);
  /*
  const categoryCheckboxes = useMemo(
    () =>
      donationCategoriesData?.package_category?.map(category => {
        if (isEdit) {
          const itemExists = data.package_category?.find(
            category_id => category_id === category.id
          );
          setValue(`categories.${category.id}`, itemExists ? true : false);
        }
        return {
          name: `categories.${category.id}`,
          label: category.category_name
        };
      }),
    [donationCategoriesData, data?.package_category, isEdit, setValue]
  );
   */
  const campaignCheckboxes = useMemo(
    () =>
      donationCampaignsData?.campaign?.map(campaign => {
        if (isEdit) {
          const itemExists = data.campaigns?.find(
            campaign_id => campaign_id === campaign.id
          );
          setValue(`campaigns.${campaign.id}`, itemExists ? true : false);
        } else {
          setValue(`campaigns.${campaign.id}`, false);
        }

        return {
          name: `campaigns.${campaign.id}`,
          label: campaign.name
        };
      }),
    [donationCampaignsData, data?.campaigns, isEdit, setValue]
  );
  const donationTypeCheckboxes = useMemo(
    () =>
      donationTypesData?.donation_type?.map(donationType => {
        if (isEdit) {
          const itemExists = data.donation_type?.find(
            donation_type_id => donation_type_id === donationType.id
          );
          setValue(
            `donationTypes.${donationType.id}`,
            itemExists ? true : false
          );
        } else {
          setValue(`donationTypes.${donationType.id}`, false);
        }

        return {
          name: `donationTypes.${donationType.id}`,
          label: donationType.donation_type_name
        };
      }),
    [donationTypesData]
  );

  const donationAmountCheckboxes = useMemo(() => {
    const checkboxes = donationAmountsData?.donation_amount?.map(
      donationAmount => {
        if (isEdit) {
          const itemExists = data.donation_amount?.find(
            donation_amount_id => donation_amount_id === donationAmount.id
          );
          setValue(`donationAmounts.${donationAmount.id}`, !!itemExists);
        } else {
          setValue(`donationAmounts.${donationAmount.id}`, false);
        }

        return {
          name: `donationAmounts.${donationAmount.id}`,
          label: `$${donationAmount.amount.toFixed(2)}`
        };
      }
    );
    return [
      {
        name: 'has_custom_donation_amount',
        label: 'Other Amount'
      },
      ...(checkboxes || [])
    ];
  }, [donationAmountsData, isEdit, data, setValue]);
  // const transactionFeeCheckboxes = useMemo(
  //   () =>
  //     transactionFeesData?.transaction_fee?.map(transactionFee => {
  //       if (isEdit) {
  //         const itemExists = data.transaction_fee?.find(
  //           transaction_fee_id => transaction_fee_id === transactionFee.id
  //         );
  //         setValue(
  //           `transactionFees.${transactionFee.id}`,
  //           itemExists ? true : false
  //         );
  //       } else {
  //         setValue(`transactionFees.${transactionFee.id}`, false);
  //       }
  //       return {
  //         name: `transactionFees.${transactionFee.id}`,
  //         label: `${transactionFee.amount.toFixed(2)} ${
  //           transactionFee.fee_type === 'percentage' ? '%' : 'USD'
  //         }`
  //       };
  //     }),
  //   [transactionFeesData]
  // );
  const CARDS = useMemo(
    () => [
      // {
      //   title: 'Branches',
      //   id: 'branches',
      //   check: true,
      //   value: '',
      //   isEdit: true,
      //   switch: false,
      //   checkboxes: campaignCheckboxes
      // },
      {
        title: 'Current Payments Options',
        id: 'current_payments_options',
        value: '',
        check: true,
        isEdit: false,
        switch: false,
        checkboxes: [
          {
            name: `paymentOptions.${PAYMENT_OPTION_ONE_OFF}`,
            label: 'One-off'
          },
          {
            name: `paymentOptions.${PAYMENT_OPTION_MONTHLY}`,
            label: 'Monthly'
          },
          {
            name: `paymentOptions.${PAYMENT_OPTION_YEARLY}`,
            label: 'Yearly'
          }
        ]
      },
      {
        title: 'Current Locations',
        id: 'locations',
        value: '',
        isEdit: true,
        check: true,
        switch: false,
        editHref: donationSettingsSectionsRefMap.location,
        checkboxes: locationCheckboxes
      },
      /*
      {
        title: 'Current Categories List',
        id: 'current_categories_list',
        value: '',
        check: true,
        isEdit: true,
        switch: false,
        editHref: donationSettingsSectionsRefMap.category,
        checkboxes: categoryCheckboxes
      },
      */
      {
        title: 'Current Campaigns List',
        id: 'current_campaigns_list',
        value: '',
        check: true,
        isEdit: true,
        switch: false,
        editHref: donationSettingsSectionsRefMap.campaigns,
        checkboxes: campaignCheckboxes
      },
      {
        title: 'Current Donation Types',
        id: 'current_donation_types',
        value: '',
        check: true,
        isEdit: true,
        switch: false,
        editHref: donationSettingsSectionsRefMap.donationType,
        checkboxes: donationTypeCheckboxes
      },
      {
        title: 'Current Donation Amounts',
        id: 'current_donation_amounts',
        value: '',
        isEdit: true,
        check: true,
        switch: false,
        editHref: donationSettingsSectionsRefMap.donationAmount,
        checkboxes: donationAmountCheckboxes
      }
      // {
      //   title: 'Transaction Fees',
      //   id: 'transaction_fees',
      //   current_title: 'Current Transaction Fees',
      //   value: '',
      //   isEdit: false,
      //   switch: true,
      //   switchValue: openTransactionFee,
      //   handleSwitch: handleOpenTransactionFee,
      //   isSubEdit: true,
      //   editHref: donationSettingsSectionsRefMap.transactionFee,
      //   checkboxes: transactionFeeCheckboxes
      // }
    ],
    [
      locationCheckboxes,
      // categoryCheckboxes,
      campaignCheckboxes,
      donationTypeCheckboxes,
      donationAmountCheckboxes
      // transactionFeeCheckboxes,
      // openTransactionFee
    ]
  );
  const onEditClick = href => {
    navigate(paths.donationSettings + href);
  };
  useEffect(() => {
    if (isEdit) {
      const {
        // currency_id,
        // hasSuccessUrl,
        // hasTransactionFees,
        // redirect_url,
        package_title,
        package_description,
        // target_amount,
        donation_amounts,
        payment_options
      } = data;
      // setValue('currency', currency_id);
      // setValue('openRedirectUrl', hasSuccessUrl);
      // setValue('openTransactionFee', hasTransactionFees);
      // setValue('redirectUrl', redirect_url);
      setValue('title', package_title);
      setValue('description', package_description);
      // setValue('targetAmount', target_amount);
      setValue(
        'has_custom_donation_amount',
        !!JSON.parse(donation_amounts)?.find(amount => amount.id === 'custom')
      );
      payment_options?.forEach(id => {
        const itemExists = paymentOptions.find(
          payment_option_id => payment_option_id === id
        );
        setValue(`paymentOptions.${id}`, itemExists ? true : false);
      });
    }
  }, [data, isEdit, setValue]);

  const handleSubmit = data => {
    const {
      paymentOptions,
      locations,
      /* categories, */
      campaigns,
      donationTypes,
      donationAmounts
      // transactionFees
    } = data;
    const paymentOptionsData = getTrueKeys(paymentOptions);
    let locationsData = [];
    if (
      locations.all &&
      donationLocationsData &&
      donationLocationsData.location
    ) {
      locationsData = donationLocationsData.location.map(
        location => location.id
      );
    } else {
      locationsData = getTrueKeys(locations);
    }
    /* const categoriesData = getTrueKeys(categories); */
    const campaignsData = getTrueKeys(campaigns);
    const donationTypesIds = getTrueKeys(donationTypes);
    const donationTypesWithLabels = [];
    donationTypesIds.forEach(donationId => {
      const donationType = donationTypesData?.donation_type?.find(
        type => type.id === donationId
      );
      if (donationType) {
        donationTypesWithLabels.push({
          id: donationId,
          type: donationType.donation_type_name
        });
      }
    });
    const donationAmountsIds = getTrueKeys(donationAmounts);
    const donationAmountsWithLabels = [];
    donationAmountsIds.forEach(amountId => {
      const donationAmount = donationAmountsData?.donation_amount?.find(
        amount => amount.id === amountId
      );
      if (donationAmount) {
        donationAmountsWithLabels.push({
          id: amountId,
          amount: donationAmount.amount
        });
      }
    });
    if (data.has_custom_donation_amount) {
      donationAmountsWithLabels.push({ id: 'custom', amount: 'custom' });
    }
    // const transactionFeesData = getTrueKeys(transactionFees);
    const dataToSubmit = {
      // branch_id: branchId,
      // currency_id: data.currency,
      donation_amount: donationAmountsIds,
      donation_amounts: JSON.stringify(donationAmountsWithLabels),
      donation_type: donationTypesIds,
      donation_types: JSON.stringify(donationTypesWithLabels),
      // hasSuccessUrl: data.openRedirectUrl,
      // hasTransactionFees: data.openTransactionFee,
      location: locationsData,
      // target_amount: data.targetAmount,
      // organisation_id: organisationId,
      // package_category: categoriesData,
      package_description: data.description,
      package_title: data.title,
      payment_options: paymentOptionsData,
      // redirect_url: data.redirectUrl,
      campaign: campaignsData
      // status:
      // transaction_fee: transactionFeesData
    };
    onSubmit(dataToSubmit);
  };

  return (
    <FormProvider methods={methods} onSubmit={RHFHandleSubmit(handleSubmit)}>
      <Row className="pt-3 gx-3">
        <Col md={9}>
          <PackagesDetails
            sectionRef={sectionRefs[1]}
            sideNavLinks={sideNavLinks[1]?.href?.substring(1)}
          />

          {CARDS.map((card, index) => {
            return (
              <ChecksOptionsCard
                key={index}
                sectionRef={sectionRefs[index + 2]}
                sideNavLinks={sideNavLinks[index + 2]?.href?.substring(1)}
                card={card}
                onEdit={() => onEditClick(card.editHref)}
                // handleChange={handleChange}
                // handleCheck={handleCheck}
                // editData={editData}
                // deleteData={deleteData}
                // addData={addData}
              />
            );
          })}

          {/*<SuccessRedirection*/}
          {/*  switchValue={openRedirectUrl}*/}
          {/*  handleSwitch={handleOpenRedirectUrl}*/}
          {/*  sectionRef={sectionRefs[8]}*/}
          {/*  sideNavLinks={sideNavLinks[8]?.href?.substring(1)}*/}
          {/*/>*/}

          <Card className="border shadow-none">
            <Card.Body className="d-flex flex-row justify-content-end">
              <LoadingButton
                variant="primary"
                className="w-100 w-sm-25"
                type="submit"
                loading={loading}
              >
                {isEdit ? 'Update' : 'Add'}
              </LoadingButton>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="order-0 order-md-1 d-none d-md-block">
          <Card
            className={'mb-3 sticky-top border shadow-none'}
            style={{ top: stickyStatus ? '5rem' : '0' }}
          >
            <Card.Body>
              <div>
                <Nav className="flex-column" variant="pills">
                  {sideNavLinks?.map(item => (
                    <Nav.Link
                      key={item.id}
                      href={item.href}
                      className={`${
                        activeSection === item.id
                          ? 'current custom-nav-link'
                          : 'custom-nav-link'
                      }`}
                      active={activeSection >= item.id}
                    >
                      {item.title}
                    </Nav.Link>
                  ))}
                </Nav>
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* <DataModal
          show={modal}
          setShow={setModal}
          page="packages_details"
          data={{
            ...allData,
            packagesDetails: packagesDetails,
            successRedirection: successRedirection
          }}
        /> */}
      </Row>
    </FormProvider>
  );
};

PackageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.object,
  isEdit: PropTypes.bool
};

export default PackageForm;
