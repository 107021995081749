export default {
  dashboard: '/dashboard',
  register: '/register',
  registerOwnerInfo: '/register/admin-info',
  registerBusinessInfo: '/register/organization-info',
  login: '/login',
  sessionOut: '/session-out',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  // verifyEmail: '/verify-email',
  verifyEmailSuccess: '/verify-email-success',
  verifyEmailFailure: '/verify-email-failure',
  notFound: '/404',
  notAuthorized: '/401',
  serverError: '/500',
  verifyEmail: '/api/email/verify/:userId/:hash',
  verifyEmailAlert: '/verifyEmailAlert',

  // Donors
  donors: '/donors',
  donorDetails: '/donors/:id',
  donorProfile: '/donors/:id/profile',
  donorInsights: '/donors/:id/insights',
  donorGiving: '/donors/:id/giving',
  donorGivingDetails: '/donors/:id/giving/:givingId',
  donorSponsorships: '/donors/:id/sponsorships',
  donorCommunications: '/donors/:id/communications',
  donorRelationManagement: '/donors/:id/relative-management',
  donorGiftAid: '/donors/:id/gift-aid',
  donorFeedback: '/donors/:id/notes',
  donorDetailsOverview: '/donors/:id/overview',
  addNewDonar: '/donors/add-new-donor',
  editDonor: '/donors/edit-donor/:id',
  addNewDonation: '/donors/:id/add-new-donation',
  configNewDonation: '/donors/:id/add-new-donation/config',
  payNewDonation: '/donors/:id/add-new-donation/pay',

  // Beneficiaries
  beneficiaries: '/beneficiaries',
  beneficiaryDetails: '/beneficiaries/:id',
  beneficiaryProfile: '/beneficiaries/:id/profile',
  beneficiaryEducation: '/beneficiaries/:id/education',
  beneficiaryMedical: '/beneficiaries/:id/medical',
  beneficiaryDonors: '/beneficiaries/:id/donors',
  beneficiaryGallery: '/beneficiaries/:id/gallery',
  beneficiaryServices: '/beneficiaries/:id/services',
  beneficiaryDonorEdit: '/beneficiaries/:id/donors/:donorId/edit',
  beneficiaryCreateNewSponsorships:
    '/beneficiaries/:id/donors/:donorId/sponsorships/create',
  beneficiaryShool: '/beneficiaries/:id/school',
  beneficiaryHospital: '/beneficiaries/:id/hospital',
  beneficiaryMosque: '/beneficiaries/:id/mosque',
  beneficiaryOrphanage: '/beneficiaries/:id/orphanage',

  beneficiaryAddSchool: '/beneficiaries/:id/school/add',
  addBeneficiary: '/sponsorship/beneficiaries/add',
  editBeneficiary: '/sponsorship/beneficiaries/edit/:id',
  sponsoredList: '/sponsorship/sponsored-list',
  unsponsoredList: '/sponsorship/unsponsored-list',
  sponsorshipValues: '/sponsorship/sponsorship-values',
  createNewSponsorship: '/sponsorship/create',

  school: '/school',
  addSchool: '/school/add',
  editSchool: '/school/:id/edit',
  schoolDetails: '/school/:id/details',

  mosque: '/mosque',
  addMosque: '/mosque/add',
  editMosque: '/mosque/:id/edit',
  mosqueDetails: '/mosque/:id/details',

  hospital: '/hospital',
  addHospital: '/hospital/add',
  editHospital: '/hospital/:id/edit',
  hospitalDetails: '/hospital/:id/details',

  orphanage: '/orphanage',
  addOrphanage: '/orphanage/add',
  editOrphanage: '/orphanage/:id/edit',

  gallery: '/gallery',

  // Donations
  allDonations: '/donations',
  addDonation: '/donations/add-donation',
  addDonationConfig: '/donations/add-donation/config',
  addDonationPayment: '/donations/add-donation/pay',
  donationSettings: '/donations/settings',
  createPackage: '/donations/packages/create',
  editPackage: '/donations/packages/edit/:id',
  donationPackages: '/donations/packages',
  urgentAppeals: '/donations/urgent-appeals',
  createUrgentAppeal: '/donations/urgent-appeals/create',
  editUrgentAppeal: '/donations/urgent-appeals/edit/:id',

  // Projects
  projects: '/projects',
  projectsKanban: '/projects/kanban',
  projectsList: '/projects/list',
  createProject: '/projects/create-project',
  editProject: '/projects/:id/edit-project',
  projectDetails: '/projects/:id',
  projectProposal: '/projects/:id/proposal',
  createProjectReport: '/projects/:id/create-report',
  projectReport: '/projects/:id/report',
  projectMilestones: '/projects/:id/milestones',
  projectReports: '/projects/:id/reports',
  projectReportDetails: '/projects/:id/reports/:reportId',
  editProjectReport: '/projects/:id/reports/:reportId/edit',
  projectProgressCreateReport: '/projects/:id/progress/create-report',
  completeProject: '/projects/:id/complete',
  createCompleteReport: '/projects/:id/complete/create',
  editCompleteReport: '/projects/:id/complete/edit',
  viewCompleteReport: '/projects/:id/complete/view',

  // gift aid
  giftAid: '/gift-aid',
  giftAidApplicationsList: '/gift-aid/applications',
  giftAidApplicationCreate: '/gift-aid/application/create',
  giftAidApplicationDetails: '/gift-aid/application/:id',

  // settings
  settings: '/settings',
  organizationSettings: '/settings/organization',
  personalSettings: '/settings/personal',
  branchesSetting: '/settings/branches',
  createBranch: '/settings/branches/create',
  editBranch: '/settings/branches/edit/:id',
  userRolesSetting: '/settings/user-roles',
  createNewUser: '/settings/user-roles/create',
  editUser: '/settings/user-roles/:id/edit',
  paymentSettings: '/settings/payment',
  smtpSettings: '/settings/smtp',

  branchSelection: '/branch-selection',
  branchCreate: '/branch-create',
  branchEdit: '/branch-edit/:id',
  // editOrganization: '/organization-edit'

  donationForm: '/home/*',

  eventSchedule: '/event-schedule'
};
