import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { GET_PACKAGES_BY_ORGANIZATION } from 'components/donations/donation-packages';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import packageImage from 'assets/img/we-raise-images/donation-package.png';

import { Button, Image } from 'react-bootstrap';
import LoadingContainer from 'components/common/LoadingContainer';
import { PackageDrawer } from './PackageDrawer';

function PackageList() {
  const [selectedPackage, setSelectedPackage] = useState();

  const { organizationId } = useParams();

  const { data, loading } = useQuery(GET_PACKAGES_BY_ORGANIZATION, {
    variables: { organizationId: organizationId }
  });

  const { setIsPackageDrawerOpen } = useDonationContextProvider();

  if (loading) {
    return <LoadingContainer />;
  }

  const packageList = data.packages;

  const donateNowHandler = packageItem => {
    setSelectedPackage(packageItem);
    setIsPackageDrawerOpen(true);
  };

  return (
    <>
      <div className="row">
        {packageList.map(item => (
          <div
            key={item.id}
            className="d-flex justify-content-center col-6 col-md-3"
          >
            <div style={{ maxWidth: '300px' }}>
              <Image
                src={packageImage}
                alt={item.package_title}
                className="img-fluid"
              />
              <div className="bg-wr-donation-primary text-center px-3 py-4 d-flex flex-column gap-4">
                <h3 className="wr-donation-header4 text-white">
                  {item.package_title}
                </h3>
                <Button
                  className="align-self-center bg-white wr-donation-body7 wr-donation-text-primary"
                  onClick={() => donateNowHandler(item)}
                >
                  Donate Now &gt;
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedPackage && (
        <PackageDrawer
          packageId={selectedPackage.id}
          packageName={selectedPackage.package_title}
          paymentTypeOptions={selectedPackage.payment_options || []}
          amountOptions={JSON.parse(selectedPackage.donation_amounts)?.filter(
            amount => amount.id !== 'custom'
          )}
          hasCustomAmount={
            !!JSON.parse(selectedPackage.donation_amounts)?.find(
              amount => amount.id === 'custom'
            )
          }
          typeOptions={JSON.parse(selectedPackage.donation_types)}
        />
      )}
    </>
  );
}

export { PackageList };
