import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import propTypes from 'prop-types';
import classNames from 'classnames';

export const RHFDatePicker = ({
  name,
  label,
  required,
  type = 'date',
  max = true,
  noOptional,
  className,
  ...rest
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Form.Group className={classNames('d-flex flex-column', className)}>
          {!!label && (
            <Form.Label>
              {label} {required && <span className="text-danger">*</span>}
              {!required && (
                <span className="text-500">{!noOptional && '(optional)'}</span>
              )}
            </Form.Label>
          )}
          <Form.Control
            onChange={date => onChange(date)}
            {...rest}
            value={value}
            type={type}
            max={max && new Date().toISOString().slice(0, 10)}
          />

          {error && (
            <Form.Control.Feedback className="d-block" type="invalid">
              {error.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      )}
    />
  );
};

RHFDatePicker.propTypes = {
  name: propTypes.string,
  label: propTypes.string,
  required: propTypes.bool,
  type: propTypes.string,
  max: propTypes.bool,
  noOptional: propTypes.bool,
  className: propTypes.string
};
