import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import * as Yup from 'yup';
import {
  FormProvider,
  RHFControl,
  RHFPhoneInput,
  RHFSelect
} from 'components/common/form';

import {
  getCitiesOptions,
  getCountriesOptions
} from '../../../data/options/countris-and-cities';
import { PHONE_NUMBER_TYPE_OPTIONS, TITLE_OPTIONS } from '../data';

import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import IconButton from 'components/common/IconButton';

function DonorForm({ form }) {
  const { watch, setValue } = form;

  const country = watch('country');
  const countriesOptions = useMemo(() => getCountriesOptions(), []);
  const citiesOptions = useMemo(() => {
    if (country) {
      return getCitiesOptions(country);
    }
    return [];
  }, [country]);

  const [addressType, setAddressType] = useState('UK_Address');
  const [postCode, setPostCode] = useState('');
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAddresses = async postCode => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.getaddress.io/autocomplete/${postCode}?api-key=${process.env.REACT_APP_GET_ADDRESS_APIKEY}&all=true`
      );

      const data = await response.json();
      setAddressList(data.suggestions);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const addressOptions = addressList?.map(({ address, id }) => ({
    label: address,
    value: id
  }));

  const fetchAddressDetails = async addressId => {
    try {
      const response = await fetch(
        `https://api.getaddress.io/get/${addressId}?api-key=${process.env.REACT_APP_GET_ADDRESS_APIKEY}`
      );

      const data = await response.json();

      setSelectedAddress(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleManualAddressEntry = () => {
    setAddressType('Non-UK_Address');
    setSelectedAddress(null); // Clear the selected address when switching to manual entry
  };

  const handleAddressSelect = e => {
    fetchAddressDetails(e?.value);
  };

  useEffect(() => {
    if (selectedAddress) {
      setValue('addressLine1', selectedAddress.line_1);
      setValue('addressLine2', selectedAddress.line_2);
      setValue('city', selectedAddress.town_or_city);
      setValue('country', 'United Kingdom');
      setValue('postcode', selectedAddress.postcode);
    }
  }, [selectedAddress, setValue]);

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchAddresses(postCode);
    }
  };

  return (
    <FormProvider
      methods={form}
      onSubmit={form.handleSubmit(data => console.log(data))}
    >
      <h2 className="text-uppercase wr-donation-header2 wr-donation-donor-form-header pb-4 mb-3">
        your information
      </h2>
      <Row className="mb-3">
        <Col md={6}>
          <RHFSelect
            name="title"
            label="Title"
            options={TITLE_OPTIONS}
            required
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <RHFControl
            name="firstName"
            placeholder="First name"
            label="First name"
            required
          />
        </Col>
        <Col md={6}>
          <RHFControl
            name="lastName"
            placeholder="Last name"
            label="Last name"
            required
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          <RHFControl
            name="email"
            type="email"
            placeholder="Email"
            label="Email"
            required
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <RHFPhoneInput label="Phone" name="phoneNumber" required />
        </Col>
        <Col md={3}>
          <RHFSelect
            name="type"
            label="Type"
            options={PHONE_NUMBER_TYPE_OPTIONS}
            required
          />
        </Col>
      </Row>
      <Flex direction="row" gap="2" className="mb-3">
        <Button
          variant={
            addressType === 'UK_Address'
              ? 'outline-primary'
              : 'outline-secondary'
          }
          onClick={() => setAddressType('UK_Address')}
        >
          UK Address
        </Button>
        <Button
          variant={
            addressType === 'Non-UK_Address'
              ? 'outline-primary'
              : 'outline-secondary'
          }
          onClick={() => setAddressType('Non-UK_Address')}
        >
          Non-UK Address
        </Button>
      </Flex>

      {addressType === 'UK_Address' && (
        <div className="mb-3">
          <Form.Label>
            Post Code <span className="text-danger">*</span>
          </Form.Label>
          <InputGroup className="mb-1">
            <Form.Control
              type="text"
              placeholder="Enter Post Code"
              onChange={e => setPostCode(e.target.value)}
              onKeyDown={handleKeyDown}
            />

            <LoadingButton
              loading={isLoading}
              variant="primary"
              onClick={() => fetchAddresses(postCode)}
            >
              Search
            </LoadingButton>
          </InputGroup>
          <p className="mb-2 wr-donation-helper-text">
            The Post Code search only works for UK, if the billing address is
            outside of the UK, Enter it manually below
          </p>

          {!!addressList.length && (
            <RHFSelect
              name="type"
              label="Select your address"
              options={addressOptions}
              required
              onChange={handleAddressSelect}
              className="mb-2"
            />
          )}
        </div>
      )}

      {(addressType === 'Non-UK_Address' || selectedAddress) && (
        <div className="mb-3">
          <Row className="g-3">
            <Col md="12">
              <RHFControl
                name="postcode"
                label="Post Code"
                placeholder="add Zip/post Code"
                required
              />
            </Col>
            <Col md="12">
              <RHFControl
                name="addressLine1"
                label="First Line Address"
                placeholder="add Address Line 1"
                required
              />
            </Col>
            <Col md="12">
              <RHFControl
                name="addressLine2"
                label="Second Line Address"
                placeholder="add Address Line 2"
              />
            </Col>
            <Col md="6">
              <RHFSelect
                label="Country"
                name="country"
                options={countriesOptions}
                required
              />
            </Col>
            <Col md="6">
              <RHFSelect
                label="City"
                name="city"
                options={citiesOptions}
                disabled={!country}
                required
              />
            </Col>
          </Row>
        </div>
      )}

      {addressType === 'UK_Address' && (
        <IconButton
          icon="plus"
          iconClassName="me-2"
          variant="link"
          className="w-100 mx-auto py-2 text-primary fw-semi-bold"
          onClick={handleManualAddressEntry}
        >
          Enter Address Manually
        </IconButton>
      )}
      <hr />
    </FormProvider>
  );
}

const donorFormDefaultValues = {
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  type: 'personal',
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: '',
  postcode: '',
  isPrimary: true
};

const donorSchema = Yup.object().shape({
  title: Yup.string().required('Required Field'),
  firstName: Yup.string().required('Required Field'),
  lastName: Yup.string().required('Required Field'),

  addressLine1: Yup.string().required('Required Field'),
  addressLine2: Yup.string(),
  city: Yup.string().required('Required Field'),
  country: Yup.string().required('Required Field'),
  postcode: Yup.string().required('Required Field'),
  isPrimary: Yup.boolean().required('Required Field'),

  email: Yup.string().email().required('Required Field'),

  phoneNumber: Yup.string().required('Required Field'),
  type: Yup.string().required('Required Field')
});

DonorForm.propTypes = {
  form: PropTypes.object.isRequired
};

export { DonorForm, donorSchema, donorFormDefaultValues };
