import React, { useEffect, useState } from 'react';
import { Button, Card, Row } from 'react-bootstrap';
// import ImageGrid from './ImageGrid';

import { useMutation, useQuery } from '@apollo/client';
import { faBorderAll, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import { useAuthContext } from 'context/authContext';
import useUpload from 'hooks/useUpload';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ADD_GALLERY, DELETE_GALLERY_IMAGE } from '../mutations';
import { GET_ALL_GALLERY, GET_GALLERY } from '../queries';
import AddImageModal from './AddImageModal';
import ImageGrid from './ImageGrid';
import ImageList from './ImageList';
import Flex from 'components/common/Flex';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';

const PAGE_SIZE = 2;

function Gellery() {
  const isList = 'list';
  const isGrid = 'grid';
  const [layout, setLayout] = useState('list');
  const [addImageShow, setAddImageShow] = useState(false);
  const { upload, loading: uploadLoading } = useUpload();
  const { organization, selectedBranchId, user } = useAuthContext();
  const { id } = useParams();

  const [addGallery] = useMutation(ADD_GALLERY, {
    onCompleted: () => {
      toast.success('Image Added Successfully');
      setAddImageShow(false);
      void refetchGallery();
    },
    onError: error => {
      toast.error('Something went wrong! Please Try again later!');
      console.log(error);
    }
  });

  const [deleteGallery] = useMutation(DELETE_GALLERY_IMAGE, {
    onCompleted: () => {
      toast.success('Image Deleted Successfully');
      setAddImageShow(false);
      void refetchGallery();
    },
    onError: error => {
      toast.error('Something went wrong! Please Try again later!');
      console.log(error);
    }
  });

  const onSubmit = async values => {
    try {
      if (values.image && typeof values.image !== 'string') {
        const profileUrl = await upload(values.image);
        values.image = profileUrl;
        addGallery({
          variables: {
            branch_id: selectedBranchId,
            gallery_image: values.image,
            organisation_id: organization.id,
            orphan_id: id,
            user_id: user.id,
            image_description: values.description
          }
        });
      }
    } catch (error) {
      toast.error('Something went wrong! Please Try again later!');
      return;
    }
  };
  const [globalFilter, setGlobalFilter] = useState('');
  const [pageOffset, setPageOffset] = useState(0);
  const { data: galleryDate, refetch: refetchGallery } = useQuery(
    id ? GET_GALLERY : GET_ALL_GALLERY,
    {
      variables: {
        id,
        organisation_id: organization.id,
        search: `%${globalFilter || ''}%`
      },
      fetchPolicy: 'network-only'
    }
  );

  const [selectedId, setSelectedId] = useState();
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);

  const handleDeleteImageHide = () => {
    setShowDeleteImageModal(false);
  };
  const handleConfirmDelete = () => {
    deleteGallery({
      variables: {
        id: selectedId
      }
    });
    setShowDeleteImageModal(false);
  };

  const data = galleryDate?.gallery || [];
  const start = pageOffset * PAGE_SIZE;
  const end = start + PAGE_SIZE;
  const galleryItems = data.slice(start, end);

  const totalPages = Math.ceil(data.length / PAGE_SIZE);

  const handleNext = () => {
    setPageOffset(pageOffset + 1);
  };

  const handlePrev = () => {
    setPageOffset(pageOffset - 1);
  };

  useEffect(() => {
    setPageOffset(0);
  }, [globalFilter]);

  return (
    <>
      <ConfirmationModal
        show={showDeleteImageModal}
        onHide={handleDeleteImageHide}
        title={'Delete Image'}
        handleConfirm={handleConfirmDelete}
        body={'Are you sure you want to remove selected Image?'}
      />

      <Card className="mt-3">
        <Card.Header className="d-flex justify-content-between">
          <Flex gap="4">
            <div className="text-nowrap h4">Gallery</div>

            <AdvanceTableSearchBox
              className="table-input-search-width w-75"
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              // placeholder={searchInputPlaceHolder}
              showSearchIcon={false}
            />
          </Flex>

          <Flex gap="4" alignItems="center">
            <FontAwesomeIcon
              icon={faList}
              role="button"
              className={classNames({
                'text-primary': layout === 'layout'
              })}
              onClick={() => {
                setLayout('list');
              }}
            />
            <FontAwesomeIcon
              icon={faBorderAll}
              role="button"
              className={classNames({
                'text-primary': layout === 'grid'
              })}
              onClick={() => {
                setLayout('grid');
              }}
            />

            {id && (
              <Button
                variant="primary"
                size="md"
                onClick={() => {
                  setAddImageShow(true);
                }}
              >
                Add Image
              </Button>
            )}
          </Flex>
        </Card.Header>
        <Card.Body
          className={classNames('ps-3', {
            'p-0 overflow-hidden': isList,
            'pb-0': isGrid
          })}
        >
          <Row
            className={classNames({
              'g-0': isList
            })}
          >
            {galleryItems.map((image, index) =>
              layout === 'list' ? (
                <>
                  <ImageList
                    ImageObj={image}
                    key={image.id}
                    index={index}
                    setSelectedId={setSelectedId}
                    setShowDeleteImageModal={setShowDeleteImageModal}
                  />
                </>
              ) : (
                <>
                  <ImageGrid
                    ImageObj={image}
                    key={image.id}
                    index={index}
                    setSelectedId={setSelectedId}
                    setShowDeleteImageModal={setShowDeleteImageModal}
                  />
                </>
              )
            )}
          </Row>
          <hr />
          <div className="mb-3">
            <AdvanceTablePagination
              pageIndex={pageOffset}
              pageCount={totalPages}
              gotoPage={page => setPageOffset(page)}
              canNextPage={pageOffset < totalPages - 1}
              nextPage={handleNext}
              canPreviousPage={pageOffset !== 0}
              previousPage={handlePrev}
            />
          </div>
        </Card.Body>
      </Card>
      <AddImageModal
        show={addImageShow}
        onSubmit={onSubmit}
        onHide={() => {
          setAddImageShow(false);
        }}
        loading={uploadLoading}
        title={'Add Photo'}
      />
    </>
  );
}

export default Gellery;
