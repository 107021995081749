import { Button, ButtonGroup, Offcanvas } from 'react-bootstrap';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import IconButton from '../common/IconButton';

function CartDrawer() {
  const {
    basket,
    decreaseDonationCount,
    increaseDonationCount,
    isCartDrawerOpen,
    setIsCartDrawerOpen,
    setIsGiftAidDrawerOpen
  } = useDonationContextProvider();

  const basketTotal = basket.reduce(
    (acc, curr) => acc + curr.count * curr.donationAmount.value,
    0
  );

  const onAddAnotherDonation = () => {
    // setIsCartDrawerOpen(false);
    // if (isPackageDrawerOpen) {
    //   setIsPackageDrawerOpen(false);
    // }
    // if (isGiftAidDrawerOpen) {
    //   setIsGiftAidDrawerOpen(false);
    // }
    setIsCartDrawerOpen(false);
  };

  // let rightPosition = 0;
  // let drawerWidth = '500px';
  // if (isGiftAidDrawerOpen) {
  //   drawerWidth = '400px';
  //   rightPosition = '400px';
  // }

  return (
    <Offcanvas
      show={isCartDrawerOpen}
      onHide={() => setIsCartDrawerOpen(false)}
      placement="end"
      backdrop="static"
      className="bg-wr-donation-grey-50"
      style={{ width: '90%', maxWidth: 500 }}
    >
      <Offcanvas.Header>
        {/*{!isPackageDrawerOpen && (*/}
        <Button
          className="bg-transparent shadow-none border-0 fs-2 p-0 text-danger"
          onClick={() => {
            setIsCartDrawerOpen(false);
            // if (isGiftAidDrawerOpen) {
            //   setIsGiftAidDrawerOpen(false);
            // }
          }}
        >
          &times;
        </Button>
        {/*)}*/}
      </Offcanvas.Header>
      <Offcanvas.Body>
        <h2 className="text-uppercase wr-donation-header2 wr-donation-cart-header pb-3 mb-4">
          DONATION ADDED
        </h2>

        {basket.length === 0 && (
          <p className="wr-donation-body2 text-uppercase text-center my-10">
            Your basket is empty
          </p>
        )}
        {basket.length > 0 && (
          <div className="d-flex flex-column gap-3">
            {basket.map(item => (
              <div
                key={item.id}
                className="wr-donation-summary-card d-flex flex-column gap-2"
              >
                <header className="d-flex justify-content-between align-items-center wr-donation-text-brand-secondary">
                  <h3 className="text-capitalize mb-0 fw-semi-bold">
                    {item.packageId ? item.packageName : 'Quick Donation'}
                  </h3>

                  <span className="fs-2 fw-semi-bold ms-auto">
                    {item.count * item.donationAmount.value}
                  </span>
                </header>
                <ul>
                  <li>{item.paymentType}</li>
                  <li>{item.donationType.name}</li>
                </ul>
                <hr className="my-0" />
                <ButtonGroup className="align-self-start wr-donation-border-primary">
                  <Button
                    variant="text"
                    className="wr-donation-border-primary"
                    onClick={() => decreaseDonationCount(item.id, 1)}
                  >
                    -
                  </Button>
                  <Button
                    variant="text"
                    className="text-black wr-donation-border-primary"
                  >
                    {item.count}
                  </Button>
                  <Button
                    variant="text"
                    className="wr-donation-border-primary"
                    onClick={() => increaseDonationCount(item.id, 1)}
                  >
                    +
                  </Button>
                </ButtonGroup>
              </div>
            ))}
            <div className="wr-donation-summary-card d-flex flex-column gap-3">
              <header className="wr-donation-text-brand-secondary fw-bold d-flex justify-content-between align-items-center">
                <span>Your Donations</span>
                <span>{basketTotal}</span>
              </header>
            </div>
            <Button
              className="border-0 bg-wr-donation-primary py-3"
              onClick={() => {
                setIsCartDrawerOpen(false);
                setIsGiftAidDrawerOpen(true);
              }}
            >
              Checkout &gt;
            </Button>
            <IconButton
              className="py-3 bg-white wr-donation-text-primary wr-donation-border-primary"
              icon="hand-holding-heart"
              iconAlign="right"
              iconClassName="wr-donation-text-primary ms-2"
              onClick={onAddAnotherDonation}
            >
              Add Another Donation
            </IconButton>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

CartDrawer.propTypes = {};

export { CartDrawer };
