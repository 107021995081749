import { useState } from 'react';

import classNames from 'classnames';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { Button, Col, Image, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';

const ImageGrid = ({
  ImageObj,
  index,
  setSelectedId,
  setShowDeleteImageModal
}) => {
  const [show, setShow] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const {
    created_at,
    orphan,
    gallery_image,
    image_description,
    id: imageId
  } = ImageObj;

  const handleShow = src => {
    setPreviewImage(src);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <Col md={2} key={index} className="center-text">
        <Flex
          direction="column"
          justifyContent="center"
          className="rounded-1 h-100"
        >
          <div className="overflow-hidden" style={{ alignSelf: 'center' }}>
            <Image
              src={gallery_image}
              rounded={true}
              style={{ width: '100px', height: '100px' }}
              className={classNames(' fit-cover cursor-pointer')}
              role="button"
              onClick={() => handleShow(gallery_image)}
            />
            <div className="mt-2">
              <p className="text-black fs-1">{image_description}</p>
              <div className=" mt-0">
                <p className="mb-1 mb-md-1">{created_at.split('T')[0]} By </p>{' '}
                <p className="mb-1 mb-md-1 text-primary">
                  <Link to={paths.beneficiaries + '/' + orphan.id}>
                    {'  ' + orphan.first_name + ' ' + orphan.last_name}
                  </Link>
                </p>
              </div>
              <Button
                variant="link"
                className="ps-0"
                style={{ fontSize: '12px' }}
                onClick={() => {
                  setSelectedId(imageId);
                  setShowDeleteImageModal(true);
                }}
              >
                Remove
              </Button>
              {/* <p className="fs--1 mb-3">
              <Link to="#!" className="text-500">
                {category}
              </Link>
            </p>
            <h5 className="fs-md-2 text-warning mb-0 d-flex align-items-center mb-3">
              {`$${salePrice ? salePrice : price}`}
              {salePrice && <del className="ms-2 fs--1 text-500">${price}</del>}
            </h5>
            <p className="fs--1 mb-1">
              Shipping Cost: <strong>${shippingCost}</strong>
            </p>
            <p className="fs--1 mb-1">
              Stock:{' '}
              <strong
                className={classNames({
                  'text-success': isInStock,
                  'text-danger': !isInStock
                })}
              >
                {isInStock ? 'Available' : 'Sold-Out'}
              </strong>
            </p> */}
            </div>
          </div>
        </Flex>
      </Col>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Body className="text-center">
          {previewImage && <Image src={previewImage} fluid />}
        </Modal.Body>
      </Modal>
    </>
  );
};

ImageGrid.propTypes = {
  ImageObj: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    created_at: PropTypes.date,
    owner: PropTypes.string,
    gallery_image: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object,
    orphan: PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    image_description: PropTypes.string
  }),
  index: PropTypes.number,
  setSelectedId: PropTypes.func,
  setShowDeleteImageModal: PropTypes.func
};

export default ImageGrid;
