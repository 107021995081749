import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';

import classNames from 'classnames';

import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { useDonationContextProvider } from 'context/DonationContextProvider';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  PAYMENT_OPTION_ONE_OFF,
  PAYMENT_OPTION_MONTHLY
} from 'components/donations/donation-packages/package-form/PackageForm';

import { useQuery } from '@apollo/client';
import { GET_DONATION_AMOUNTS, GET_DONATION_TYPE } from './queries';

import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { FormProvider, RHFSelect } from 'components/common/form';

function QuickDonationForm() {
  const [isMonthlySupportSectionVisible, setIsMonthlySupportSectionVisible] =
    useState(true);
  const [isCustomAmountSelected, setIsCustomAmountSelected] = useState(false);

  const { organizationId } = useParams();

  const { addDonationToBasket } = useDonationContextProvider();

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      paymentType: PAYMENT_OPTION_ONE_OFF,
      isTenMonthlySupportChecked: false,
      donationType: null,
      donationAmount: {
        id: null,
        value: null
      }
    },
    resolver: yupResolver(quickDonationSchema)
  });
  const {
    watch,
    setValue,
    reset,
    formState: { errors },
    handleSubmit
  } = form;
  const paymentType = watch('paymentType');
  const donationAmount = watch('donationAmount');

  const { data: donationTypesData } = useQuery(GET_DONATION_TYPE, {
    variables: {
      organizationId
    }
  });
  const donationTypeOptions = useMemo(
    () =>
      donationTypesData?.donation_type?.map(({ id, donation_type_name }) => ({
        label: donation_type_name,
        value: id
      })),
    [donationTypesData]
  );
  const { data: donationAmountsData } = useQuery(GET_DONATION_AMOUNTS, {
    variables: {
      organizationId
    }
  });
  const donationAmountOptions = donationAmountsData?.donation_amount || [];

  const selectedPaymentTypeBtnClassNames =
    'bg-wr-donation-blue-200 wr-donation-text-primary border-0';
  const unselectedPaymentTypeBtnClassNames =
    'bg-wr-donation-grey-0 wr-donation-text-grey-900 border border-2';

  const isOneOffPaymentTypeSelected = paymentType === PAYMENT_OPTION_ONE_OFF;
  const isMonthlyPaymentTypeSelected = paymentType === PAYMENT_OPTION_MONTHLY;

  const resetForm = () => {
    setValue('isTenMonthlySupportChecked', false);
    setIsCustomAmountSelected(false);
    reset();
  };

  const onAddDonation = data => {
    const donationTypeId = data.donationType;
    addDonationToBasket({
      ...data,
      donationType: {
        id: donationTypeId,
        name: donationTypesData?.donation_type?.find(
          type => type.id === donationTypeId
        )?.donation_type_name
      },
      count: 1,
      id: uuid()
    });
    resetForm();
    toast.success('Donation added to the basket.');
  };

  return (
    <FormProvider methods={form} onSubmit={handleSubmit(onAddDonation)}>
      <div className="wr-donation-shadow-1 rounded-3 p-4 d-flex flex-column gap-4">
        <p className="m-0 text-black wr-donation-body5">
          Please select a donation type
        </p>
        <div className="d-flex flex-column flex-md-row gap-4">
          <Button
            className={classNames('outline-none rounded-3', {
              [selectedPaymentTypeBtnClassNames]: isOneOffPaymentTypeSelected,
              [unselectedPaymentTypeBtnClassNames]: isMonthlyPaymentTypeSelected
            })}
            style={{ width: '245px', height: '64px' }}
            onClick={() => {
              setValue('paymentType', PAYMENT_OPTION_ONE_OFF);
              setValue('isTenMonthlySupportChecked', false);
              setIsMonthlySupportSectionVisible(true);
            }}
          >
            Single Payment
            {isOneOffPaymentTypeSelected && (
              <Form.Check
                type="checkbox"
                inline
                className="ms-2 wr-donation-payment-type-checkbox"
                defaultChecked
              />
            )}
          </Button>

          <Button
            className={classNames('outline-none rounded-3', {
              [selectedPaymentTypeBtnClassNames]: isMonthlyPaymentTypeSelected,
              [unselectedPaymentTypeBtnClassNames]: isOneOffPaymentTypeSelected
            })}
            style={{ width: '245px', height: '64px' }}
            onClick={() => {
              setValue('paymentType', PAYMENT_OPTION_MONTHLY);
            }}
          >
            Monthly Donation
            {isMonthlyPaymentTypeSelected && (
              <Form.Check
                type="checkbox"
                inline
                className="ms-2 wr-donation-payment-type-checkbox"
                defaultChecked
              />
            )}
          </Button>
        </div>

        {isOneOffPaymentTypeSelected && isMonthlySupportSectionVisible && (
          <div className="bg-wr-donation-secondary rounded-3 p-3 d-flex flex-column gap-3">
            <Flex justifyContent="between" alignItems="middle">
              <p className="mb-0 fs-0 fw-medium wr-donation-text-brand-secondary">
                Become a monthly supporter
              </p>
              <FalconCloseButton
                className="bg-transparent border-0 p-0 wr-donation-text-grey-500"
                onClick={() => {
                  setIsMonthlySupportSectionVisible(false);
                  setValue('isTenMonthlySupportChecked', false);
                }}
              />
            </Flex>
            <p className="m-0 wr-donation-text-grey-900">
              Will you consider becoming one of our Valued Monthly supporters by
              converting your $50 Contribution into a monthly donation?
            </p>
            <p className="m-0 wr-donation-text-grey-900">
              Ongoing monthly donations allow us to better focus on our mission.
            </p>

            <Flex className="flex-column flex-md-row" gap="2">
              <IconButton
                className="fs--1 fw-medium rounded-pill bg-wr-donation-primary border-0"
                icon="hand-holding-heart"
                iconClassName="text-white"
                transform="shrink-3"
                onClick={() => {
                  setValue('paymentType', PAYMENT_OPTION_MONTHLY);
                  setValue('isTenMonthlySupportChecked', true);
                }}
              >
                Donate $10/Month
              </IconButton>
              <Button
                className="fs--1 fw-medium rounded-pill bg-wr-donation-grey-0 wr-donation-text-grey-900 border border-1"
                onClick={() => {
                  setIsMonthlySupportSectionVisible(false);
                  setValue('isTenMonthlySupportChecked', false);
                }}
              >
                No thanks, Keep it as one time gift
              </Button>
            </Flex>
          </div>
        )}
        <div>
          <RHFSelect
            name="donationType"
            label={
              <span className="wr-donation-body6 text-black me-1">
                Donation Type
              </span>
            }
            required
            options={donationTypeOptions}
          />
        </div>

        <div>
          <span className="wr-donation-body6 text-black d-inline-block mb-2 me-1">
            Donation Amount
          </span>
          <span className="text-danger">*</span>
          <Flex wrap="wrap" gap="3">
            {donationAmountOptions.map(amount => (
              <Button
                key={amount.id}
                style={{ width: '120px', height: '50px' }}
                className={classNames(
                  'wr-donation-text-primary fw-bold border-0 rounded-3',
                  {
                    'bg-wr-donation-primary text-white':
                      donationAmount.id === amount.id,
                    'bg-wr-donation-blue-200': donationAmount.id !== amount.id
                  }
                )}
                onClick={() => {
                  setValue('donationAmount', {
                    id: amount.id,
                    value: amount.amount
                  });
                  setIsCustomAmountSelected(false);
                }}
              >
                {amount.amount}
              </Button>
            ))}
            <Button
              className="bg-white wr-donation-text-primary"
              onClick={() => {
                setIsCustomAmountSelected(true);
                setValue('donationAmount', { id: null, value: null });
              }}
            >
              Custom Amount
            </Button>
          </Flex>
          {isCustomAmountSelected && (
            <Form.Control
              className="mt-3"
              required
              type="number"
              placeholder="Custom Amount"
              onChange={e => {
                setValue('donationAmount', {
                  id: 'other',
                  value: e.target.value
                });
              }}
            />
          )}
          {errors.donationAmount?.value && (
            <small className="text-danger">
              {errors.donationAmount.value.message}
            </small>
          )}
        </div>

        <Button
          className="align-self-start px-6 py-2 fw-bold text-white bg-wr-donation-primary border-0"
          type="submit"
        >
          Add Donation
        </Button>
      </div>
    </FormProvider>
  );
}

const quickDonationSchema = Yup.object().shape({
  paymentType: Yup.string().required('Payment type is required'),

  isTenMonthlySupportChecked: Yup.boolean(),

  donationType: Yup.string().required('Donation type is required'),

  donationAmount: Yup.object().shape({
    id: Yup.string().nullable(),
    value: Yup.number().nullable().required('Donation amount value is required')
  })
});

export { QuickDonationForm };
