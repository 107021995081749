import { gql } from '@apollo/client';

export const GET_BENEFICIARY_BY_ID = gql`
  query GetBeneficiaryByID($id: uuid = "") {
    orphans_by_pk(id: $id) {
      id
      first_name
      gender
      DOB
      inspiration
      created_at
    }
  }
`;

export const GET_SCHOOL_STUDENT_BY_ID = gql`
  query GetSchoolStudentByID($id: uuid = "") {
    school_students_by_pk(id: $id) {
      school_id
      start_date
      end_date
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountries {
    countries {
      id
      name
    }
  }
`;

export const GET_SCHOOLS = gql`
  query GetSchool($where: schools_bool_exp = {}) {
    schools(where: $where) {
      id
      serial_number
      school_number
      school_address
      school_city
      country
      school_email
      school_name
      created_at
      simple_id
      school_students_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_SCHOOL_STUDENT = gql`
  query GetSchoolStudent($orphanId: uuid = "") {
    school_students(where: { orphan_id: { _eq: $orphanId } }) {
      branch_id
      id
      organisation_id
      orphan_id
      school {
        school_city
        school_number
        school_name
        school_headteacher
        school_email
        school_document
        school_category_id
        school_address
        organisation_id
        id
        # country {
        #   name
        #   id
        # }
        branch_id
        serial_number
      }
      user {
        last_name
        first_name
      }
      start_date
      end_date
    }
  }
`;

export const GET_SCHOOL_BY_ID = gql`
  query GetSchoolByID($id: uuid = "") {
    schools_by_pk(id: $id) {
      id
      serial_number
      school_number
      school_address
      school_city
      school_email
      school_name
      country
      created_at
      school_headteacher
      school_document
    }
  }
`;

export const GET_SCHOOL_STUDENTS = gql`
  query GetSchoolStudents($id: uuid = "") {
    school_students(where: { school_id: { _eq: $id } }) {
      orphan {
        id
        first_name
        last_name
        gender
        DOB
        created_at
        inspiration
        status
      }
    }
  }
`;

export const GET_SCHOOL_REPORT = gql`
  query GetSchoolReport($id: uuid = "") {
    school_reports(where: { orphan_id: { _eq: $id } }) {
      id
      evaluation
      reporter
      comment
      created_at
      report_date
      school_id
      report_name
      report_document
      user {
        first_name
        last_name
      }
    }
  }
`;

export const GET_MEDICAL_REPORT = gql`
  query GetMedialReport($id: uuid = "") {
    medical_reports(where: { orphan_id: { _eq: $id } }) {
      hosptial
      id
      medical_note
      review_date
      inspection_date
    }
  }
`;

export const GET_GALLERY = gql`
  query GetGallery($id: uuid = "", $search: String) {
    gallery(
      where: {
        orphan: { id: { _eq: $id } }
        image_description: { _ilike: $search }
      }
      order_by: { created_at: desc }
    ) {
      id
      gallery_image
      user {
        first_name
        last_name
      }
      orphan {
        id
        first_name
        last_name
      }
      created_at
      image_description
    }
  }
`;

export const GET_SPONSORSHIP = gql`
  query GetSponsorship($id: uuid = "") {
    sponsorship(where: { orphan_id: { _eq: $id } }) {
      id
      donar {
        id
        address
        consented_date
        first_name
        email
        gift_aid_elgibility
        gift_aid_file
        id
        last_name
        lead_source
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export const GET_MOSQUE_LIST = gql`
  query GetMosque($where: mosques_bool_exp = {}) {
    mosques(where: $where) {
      id
      mosques_location {
        location_name
      }
      orphans_aggregate {
        aggregate {
          count
        }
      }
      name
      serial_number
      address
      city
      email
      phone
      country
      document
      created_at
      simple_id
    }
  }
`;

export const GET_LOCATION = gql`
  query GetLocation($where: location_bool_exp = {}) {
    location(where: $where) {
      id
      location_name
    }
  }
`;

export const GET_MOSQUE = gql`
  query GetMosque($id: uuid = "") {
    mosques_by_pk(id: $id) {
      name
      mosques_location {
        location_name
      }
      location_id
      organisation_id
      name
      serial_number
      address
      city
      email
      phone
      country
      document
      created_at
    }
  }
`;

export const GET_MOSQUE_ORPHAN = gql`
  query GetMosqueOrphan($id: uuid = "") {
    mosque_orphan(where: { mosque_id: { _eq: $id } }) {
      mosque_id
      orphan_id
      orphan {
        id
        first_name
        last_name
        gender
        DOB
        created_at
        inspiration
        status
      }
    }
  }
`;

export const GET_ORPHAN_BY_MOSQUE = gql`
  query GetOrphanByMosque($_in: [uuid!] = "") {
    orphans(where: { id: { _in: $_in } }) {
      id
      first_name
      gender
      DOB
      inspiration
      created_at
    }
  }
`;

export const GET_MOSQUE_BY_ORPHAN = gql`
  query GetMosqueByOrphan($id: uuid = "") {
    mosque_orphan(where: { orphan_id: { _eq: $id } }) {
      orphan_id
      mosque_id
      mosque {
        id
        name
        mosques_location {
          location_name
        }
        location_id
        organisation_id
        name
        serial_number
        address
        city
        email
        phone
        country
        document
        created_at
      }
    }
  }
`;

export const GET_MOSQUE_BY_IDS = gql`
  query GetMosqueByIds($_in: [uuid!] = "") {
    mosques(where: { id: { _in: $_in } }) {
      name
      id
      mosques_location {
        location_name
      }
    }
  }
`;

export const GET_HOSPITAL_LIST = gql`
  query GetHospital($where: hospitals_bool_exp = {}) {
    hospitals(where: $where) {
      id
      hospitals_location {
        location_name
      }
      orphans_aggregate {
        aggregate {
          count
        }
      }
      name
      simple_id
      country
      address
      email
      phone
    }
  }
`;

export const GET_HOSPITAL = gql`
  query GetHospital($id: uuid = "") {
    hospitals_by_pk(id: $id) {
      name
      hospitals_location {
        location_name
      }
      location_id
      organisation_id
      name
      serial_number
      address
      city
      email
      phone
      country
      document
      created_at
    }
  }
`;

export const GET_HOSPITAL_ORPHAN = gql`
  query GetHospitalOrphan($id: uuid = "") {
    hospital_orphan(where: { hospital_id: { _eq: $id } }) {
      hospital_id
      orphan_id
      orphan {
        id
        first_name
        last_name
        gender
        DOB
        created_at
        inspiration
        status
      }
    }
  }
`;

export const GET_ORPHAN_BY_HOSPITAL = gql`
  query GetOrphanByHospital($_in: [uuid!] = "") {
    orphans(where: { id: { _in: $_in } }) {
      id
      first_name
      gender
      DOB
      inspiration
      created_at
    }
  }
`;

export const GET_HOSPITAL_BY_IDS = gql`
  query GetHospitalByIds($_in: [uuid!] = "") {
    hospitals(where: { id: { _in: $_in } }) {
      __typename
      name
      id
      hospitals_location {
        location_name
      }
    }
  }
`;

export const GET_HOSPITAL_BY_ORPHAN = gql`
  query GetHospitalByOrphan($id: uuid = "") {
    hospital_orphan(where: { orphan_id: { _eq: $id } }) {
      orphan_id
      hospital_id
      hospital {
        id
        name
        hospitals_location {
          location_name
        }
        location_id
        organisation_id
        name
        serial_number
        address
        city
        email
        phone
        country
        document
        created_at
      }
    }
  }
`;

export const GET_ORPHANAGE_LIST = gql`
  query GetOrphanage($where: orphanages_bool_exp = {}) {
    orphanages(where: $where) {
      id
      simple_id
      orphanages_location {
        location_name
      }
      orphanages_organisation {
        orphans_aggregate {
          aggregate {
            count
          }
        }
      }
      name
      address
      country
      email
      phone
    }
  }
`;

export const GET_ORPHANAGE = gql`
  query GetOrphanage($id: uuid = "") {
    orphanages_by_pk(id: $id) {
      name
      email
      phone
      city
      country
      address
      documents
      orphanages_location {
        location_name
      }
      location_id
    }
  }
`;

export const GET_ORPHANAGE_ORPHAN = gql`
  query GetOrphanageOrphan($id: uuid = "") {
    orphan_orphanage(where: { orphanage_id: { _eq: $id } }) {
      orphanage_id
      orphan_id
    }
  }
`;

export const GET_ORPHAN_BY_ORPHANAGE = gql`
  query GetOrphanByOrphanage($_in: [uuid!] = "") {
    orphans(where: { id: { _in: $_in } }) {
      id
      first_name
      gender
      DOB
      inspiration
      created_at
    }
  }
`;

export const GET_ORPHANAGE_BY_IDS = gql`
  query GetOrphanageByIds($_in: [uuid!] = "") {
    orphanages(where: { id: { _in: $_in } }) {
      __typename
      name
      id
      orphanages_location {
        location_name
      }
    }
  }
`;

export const GET_ORPHANAGE_BY_ORPHAN = gql`
  query GetOrphanageByOrphan($id: uuid = "") {
    orphan_orphanage(where: { orphan_id: { _eq: $id } }) {
      orphan_id
      orphanage_id
      orphanage {
        id
        name
        orphanages_location {
          location_name
        }
        location_id
        organisation_id
        name
        created_at
      }
    }
  }
`;

export const GET_SHORTCUT = gql`
  query GetShortcut($organisation_id: uuid = "") {
    shortcuts(where: { organisation_id: { _eq: $organisation_id } }) {
      id
      link
      name
      description
    }
  }
`;

export const GET_CONTACT = gql`
  query GetContact($where: contact_bool_exp!) {
    contact(where: $where) {
      id
      email
      first_name
      last_name
      phone
      title
    }
  }
`;

export const GET_LOGS = gql`
  query GetLogs($where: all_logs_bool_exp!) {
    all_logs(where: $where) {
      id
      message
      subject
      created_at
    }
  }
`;

export const GET_WERAISE_MODULE = gql`
  query GetWeraiseModule($where: weraise_module_bool_exp!) {
    weraise_module(where: $where) {
      id
      name
    }
  }
`;

export const GET_ALL_GALLERY = gql`
  query GetGallery($organisation_id: uuid!, $search: String) {
    gallery(
      where: {
        organisation_id: { _eq: $organisation_id }
        image_description: { _ilike: $search }
      }
      order_by: { created_at: desc }
    ) {
      id
      gallery_image
      user {
        first_name
        last_name
      }
      orphan {
        id
        first_name
        last_name
      }
      created_at
      image_description
    }
  }
`;

export const GET_LOCATION_CONDITIONS = gql`
  query GetLocationConditions {
    location_condition {
      name
      id
    }
  }
`;

export const GET_GUARDIAN_FATHER_STATUS = gql`
  query GetGuardianFatherStatus {
    guardian_father_status {
      id
      name
    }
  }
`;

export const GET_GUARDIAN_MOTHER_STATUS = gql`
  query GetGuardianMotherStatus {
    guardian_mother_status {
      id
      name
    }
  }
`;

export const GET_GUARDIAN_RELATIONSHIP = gql`
  query GetGuardianRelationship {
    guardian_relationShip {
      id
      name
    }
  }
`;
