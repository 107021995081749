import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React, { useContext } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableRow from 'components/we-raise-common/session-by-browser/TableRow';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import AuthContext from 'context/authContext';

// Query for Lifetime Contribution
export const GET_DONOR_LIFETIME_CONTRIBUTION = gql`
  query GetDonorLifeTimeContribution($donorId: uuid) {
    orders_aggregate(where: { donar_id: { _eq: $donorId } }) {
      aggregate {
        sum {
          donation_amount
        }
      }
    }
  }
`;

// Query for YTD Contribution
export const GET_DONOR_YEAR_TO_DAY_CONTRIBUTION = gql`
  query GetDonorYearToDayContribution(
    $donorId: uuid
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    orders_aggregate(
      where: {
        donar_id: { _eq: $donorId }
        created_at: { _gte: $startDate, _lte: $endDate }
      }
    ) {
      aggregate {
        sum {
          donation_amount
        }
      }
    }
  }
`;

// Query for First Donation
export const GET_DONOR_FIRST_DONATION = gql`
  query GetDonorFirstDonation($donorId: uuid!) {
    orders(where: { donar_id: { _eq: $donorId } }, limit: 1) {
      donation_amount
    }
  }
`;

// Query for Most Recent Donation
export const GET_DONOR_MOST_RECENT_DONATION = gql`
  query GetDonorMostRecentDonation($donorId: uuid!) {
    orders(
      where: { donar_id: { _eq: $donorId } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      donation_amount
    }
  }
`;

// Query for Created Date
export const GET_DONOR_CREATION_DATE = gql`
  query GetDonorCreationDate($donorId: uuid!) {
    donars_by_pk(id: $donorId) {
      created_at
    }
  }
`;

// Query for Average Donation
export const GET_DONOR_AVERAGE_DONATION_AMOUNTS = gql`
  query GetDonorAverageDonationAmounts($donorId: uuid!) {
    orders_aggregate(where: { donar_id: { _eq: $donorId } }) {
      aggregate {
        avg {
          donation_amount
        }
      }
    }
  }
`;

// Query for Last Donation Date
export const GET_LAST_DONATION_DATE = gql`
  query lastDonation($id: uuid!) {
    donars_by_pk(id: $id) {
      orders(order_by: { created_at: desc }, limit: 1) {
        created_at
      }
    }
  }
`;

// Query for last three years donation amounts

const generateDonorYearlyDonationAmounts = years => {
  return gql`
    query GetLastThreeYearsAggregates($donorId: uuid!) {
      ${years
        .map(
          year => `
          year${year}: orders_aggregate(
            where: {
              donar_id: { _eq: $donorId },
              created_at: { _gte: "${year}-01-01", _lte: "${year}-12-31" }
            }
          ) {
            aggregate {
              sum {
                donation_amount
              }
            }
          }
        `
        )
        .join('\n')}
    }
  `;
};
const currentYear = dayjs().year();
const years = [currentYear - 2, currentYear - 1, currentYear];
const GET_DONOR_YEARLY_DONATIONS_AMOUNTS =
  generateDonorYearlyDonationAmounts(years);

export const GET_DONATION_CATEGORIES = gql`
  query DonationCategories($organisation_id: uuid!) {
    donation_type(where: { organisation_id: { _eq: $organisation_id } }) {
      donation_type_name
      organisation {
        donation_amounts_aggregate {
          aggregate {
            sum {
              amount
            }
          }
        }
      }
    }
  }
`;

export const getOptions = () => ({
  color: [
    getColor('primary'),
    getColor('success'),
    getColor('info'),
    getColor('danger')
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: params =>
      `<strong>${params.data.name}:</strong> ${params.data.value}%`
  },

  legend: { show: false },
  series: [
    {
      type: 'pie',
      radius: ['100%', '65%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: false
      },
      emphasis: {
        scale: false
      }
      // data: donationCategoriesData?.donation_type?.map(item => ({
      //   label: item.donation_type_name,
      //   name: item.donation_type_name,
      //   value:
      //     item.organisation.donation_amounts_aggregate.aggregate.sum.amount,
      //   caret: 'fas fa-caret-down text-danger',
      //   color: 'primary',
      //   symbol: '%'
      // }))
    }
  ]
});

export const DonationData = ({ data, isLastItem }) => (
  <Flex className={!isLastItem ? 'border-md-end me-md-4 me-lg-5' : 'border-0'}>
    <FontAwesomeIcon
      icon={'circle'}
      className={classNames('me-2 mt-1', data.statusColor)}
      size="xs"
    />
    <div className="fw-medium">
      <p className="mb-0 text-600">{data.title}</p>
      {data.amount ? (
        <p className="mb-0 fs-2">
          {data.amount}{' '}
          {data.notification ? (
            <span
              className="border d-inline-flex fs--1 ms-1 justify-content-center rounded-circle"
              style={{ height: 20, width: 20 }}
            >
              {data.notification}
            </span>
          ) : null}
        </p>
      ) : null}

      {data.info && (
        <p className={classNames('mb-0 fs-2', data.statusColor)}>{data.info}</p>
      )}

      {!data.donationCount ? (
        <div>
          {data.lastDonation ? (
            <>
              <p className="mb-0 text-500">last Donation</p>
              <p className="mb-0">{data.lastDonation}</p>
            </>
          ) : (
            <p>No Period Comparison</p>
          )}
        </div>
      ) : (
        <p className="mb-0 text-500">
          <span className="text-900">{data.donationCount}</span> one-off
          donations per year
        </p>
      )}
    </div>
  </Flex>
);

const ProfileInsights = () => {
  const { id: donorId } = useParams();
  const { organization } = useContext(AuthContext);

  const { data: donorLifeTimeContributionResponse } = useQuery(
    GET_DONOR_LIFETIME_CONTRIBUTION,
    {
      variables: { donorId }
    }
  );

  const startDate = dayjs().startOf('year').format('YYYY-MM-DD');
  const endDate = dayjs().format('YYYY-MM-DD');
  const { data: donorYearToDayContributionResponse } = useQuery(
    GET_DONOR_YEAR_TO_DAY_CONTRIBUTION,
    {
      variables: { donorId, startDate, endDate }
    }
  );
  const { data: donorFirstDonationResponse } = useQuery(
    GET_DONOR_FIRST_DONATION,
    {
      variables: { donorId }
    }
  );
  const { data: donorMostRecentDonationResponse } = useQuery(
    GET_DONOR_MOST_RECENT_DONATION,
    {
      variables: { donorId }
    }
  );
  const { data: donorCreationDateResponse } = useQuery(
    GET_DONOR_CREATION_DATE,
    {
      variables: { donorId }
    }
  );
  const { data: donorAverageDonationAmountsResponse } = useQuery(
    GET_DONOR_AVERAGE_DONATION_AMOUNTS,
    {
      variables: { donorId }
    }
  );
  const { data: lastDonationDateData } = useQuery(GET_LAST_DONATION_DATE, {
    variables: { donorId }
  });

  const { data: donorYearlyDonationAmountsResponse } = useQuery(
    GET_DONOR_YEARLY_DONATIONS_AMOUNTS,
    {
      variables: { donorId }
    }
  );

  const { data: donationCategoriesData } = useQuery(GET_DONATION_CATEGORIES, {
    variables: { organisation_id: organization.id }
  });

  const yarns = years;
  const data = [
    donorYearlyDonationAmountsResponse?.[`year${years[0]}`]?.aggregate.sum
      .donation_amount || 0,
    donorYearlyDonationAmountsResponse?.[`year${years[1]}`]?.aggregate.sum
      .donation_amount || 0,
    donorYearlyDonationAmountsResponse?.[`year${years[2]}`]?.aggregate.sum
      .donation_amount || 0
  ];

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'category',
      data: yarns,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'solid'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-800'),
        formatter: value => value.substring(0, 4),
        margin: 15
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        color: getColor('gray-800'),
        margin: 15
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 0,
      minInterval: 500
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        data,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          color: getColor('primary'),
          borderRadius: [3, 3, 0, 0]
        },
        barWidth: 13,
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      }
    ],
    grid: { right: '3%', left: '20%', bottom: '12%', top: '5%' }
  });

  const donorLifeTimeContribution =
    donorLifeTimeContributionResponse?.orders_aggregate.aggregate.sum
      .donation_amount || 0;
  const donorYearToDayContribution =
    donorYearToDayContributionResponse?.orders_aggregate.aggregate.sum
      .donation_amount || 0;
  const donorFirstDonationAmount =
    donorFirstDonationResponse?.orders[0]?.donation_amount || 0;
  const donorMostRecentDonationAmount =
    donorMostRecentDonationResponse?.orders[0]?.donation_amount || 0;
  const donorCreationDate =
    donorCreationDateResponse?.donars_by_pk.created_at || '';
  const donorAverageDonationAmounts =
    donorAverageDonationAmountsResponse?.orders_aggregate.aggregate.avg
      .donation_amount || 0;
  const lastDonationDate =
    lastDonationDateData?.donars_by_pk.orders[0]?.created_at || '';

  const profileDetails = [
    {
      id: 1,
      title: 'LifeTime Contribution',
      value: `$${donorLifeTimeContribution}`
    },
    {
      id: 2,
      title: 'YTD Contribution',
      value: `$${donorYearToDayContribution}`
    },
    { id: 3, title: 'Outstanding Pledges', value: '$0' },
    { id: 4, title: 'First Donation', value: `$${donorFirstDonationAmount}` },
    {
      id: 5,
      title: 'Most recent Donation',
      value: `$${donorMostRecentDonationAmount}`
    },
    {
      id: 6,
      title: 'Created',
      value: dayjs(donorCreationDate).format('DD-MMM-YYYY'),
      valueClassName: 'text-400'
    }
    // { id: 7, title: 'Created By', value: 'Ahmed Adel' },
    // {
    //   id: 8,
    //   title: 'Current Assignee',
    //   value: 'Ali Mohamed',
    //   valueClassName: 'text-primary',
    //   link: '/'
    // }
  ];

  const donationInfo = [
    {
      id: 1,
      statusColor: 'text-primary',
      title: 'Average Donations',
      amount: `$${donorAverageDonationAmounts}`,
      notification: 0,
      lastDonation: lastDonationDate
        ? dayjs(lastDonationDate).format('DD-MMM-YYYY')
        : ''
    },
    {
      id: 2,
      statusColor: 'text-warning',
      title: 'Donated to date',
      amount: `$${donorLifeTimeContribution}`,
      notification: 0,
      lastDonation: ''
    },
    {
      id: 3,
      statusColor: 'text-success',
      title: 'Frequency',
      amount: '',
      info: 'Moderate',
      notification: 0,
      donationCount: 12
    }
  ];

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader
          title={<h4 className="fw-bold">Profile Overview</h4>}
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
          // endEl={<CardDropdown btnRevealClass="btn-falcon-default" />}
        />
        <Card.Body>
          <Row>
            <Col md={6}>
              <Table size="sm" borderless className="font-sans-serif mb-0">
                <tbody>
                  {profileDetails?.map(detail => (
                    <tr key={detail.id}>
                      <td className="text-start ps-0">{detail.title}</td>
                      <td
                        className={classNames(
                          'text-start',
                          detail.valueClassName
                        )}
                      >
                        {detail.link ? (
                          <a href={detail.link}>{detail.value}</a>
                        ) : (
                          detail.value
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col md={1} />
            <Col md={5}>
              <div>
                <ReactEChartsCore
                  echarts={echarts}
                  option={getOption()}
                  style={{ height: '14.75rem' }}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3 ">
        <Card.Header>
          <Card.Title className="fw-bold fs-2">Insights</Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          <Row className="g-3">
            {donationInfo?.map((info, index) => (
              <Col key={info.id} md={4}>
                <DonationData
                  data={info}
                  isLastItem={index === donationInfo.length - 1}
                />
              </Col>
            ))}
          </Row>
          <hr />
          <Row className="g-3">
            <Col md={12}>
              <p className="mb-0 fw-medium">
                <FontAwesomeIcon icon={'user'} className="me-1" />
                Giving Categories Snapshot
              </p>

              <Row className="g-3 mt-0">
                <Col md={9}>
                  <div className="border-top">
                    <Table size="sm" className="mb-0">
                      <tbody>
                        {donationCategoriesData?.donation_type?.map(
                          (item, index) => (
                            <TableRow
                              endToEnd={true}
                              key={item.donation_type_name}
                              data={{
                                label: item.donation_type_name,
                                name: item.donation_type_name,
                                value:
                                  item.organisation.donation_amounts_aggregate
                                    .aggregate.sum.amount,
                                caret: 'fas fa-caret-down text-danger',
                                color:
                                  item.donation_type_name === 'Zakat'
                                    ? 'danger'
                                    : 'primary'
                              }}
                              isLastItem={
                                index ===
                                donationCategoriesData?.donation_type?.length -
                                  1
                              }
                            />
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
                {/* <Col md={3}>
                  <div className="my-auto">
                    <ReactEChartsCore
                      echarts={echarts}
                      option={getOptions(donationCategoriesData)}
                      style={{ height: 150 }}
                    />
                  </div>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <hr />
          {/* <Row>
            <Col md={12}>
              <Flex className={'mb-3'}>
                <div
                  style={{ height: 32, width: 32 }}
                  className="border rounded-circle d-flex align-items-center justify-content-center bg-soft-success"
                >
                  <FontAwesomeIcon icon={'trophy'} className="text-success" />
                </div>
                <div className="ms-3">
                  <p className="fw-semi-bold mb-0 text-800">
                    Donor Score and status
                  </p>
                  <p className="fw-semi-bold mb-0 text-1000">
                    Active Donor,{' '}
                    <span className="fw-medium text-800">
                      Among the top 32% within your orgniazation
                    </span>
                  </p>
                </div>
              </Flex>
              <Flex>
                <div
                  style={{ height: 32, width: 32 }}
                  className="border rounded-circle d-flex align-items-center justify-content-center bg-soft-primary"
                >
                  <FontAwesomeIcon icon={'envelope'} className="text-primary" />
                </div>
                <div className="ms-3">
                  <p className="fw-semi-bold mb-0 text-800">Smart Ask</p>
                  <p className="fw-medium mb-0">
                    This donor is most likely to donate between{' '}
                    <span className="fw-semi-bold text-1000">
                      $5,500 to $8,000.
                    </span>
                    We recommend asking for{' '}
                    <span className="fw-semi-bold text-primary">$7,000</span>
                  </p>
                </div>
              </Flex>
            </Col>
          </Row> */}
        </Card.Body>
      </Card>
    </>
  );
};

DonationData.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    statusColor: PropTypes.string,
    info: PropTypes.string,
    lastDonation: PropTypes.string,
    notification: PropTypes.number,
    donationCount: PropTypes.number
  }),

  isLastItem: PropTypes.bool
};

export default ProfileInsights;
