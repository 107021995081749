export const permissionsKeyMap = {
  dashboard: {
    parent: 'dashboardParent',
    children: {
      dashboard: 'dashboard'
    }
  },
  donor: {
    parent: 'donorsParent',
    children: {
      donors: 'donors'
    }
  },
  project: {
    parent: 'projectsParent',
    children: {
      projects: 'projects'
    }
  },
  sponsorship: {
    parent: 'sponsorshipParent',
    children: {
      sponsorships: 'sponsorships',
      beneficiaries: 'beneficiaries',
      sponsoredList: 'sponsoredList',
      unsponsoredList: 'unsponsoredList',
      sponsorshipValues: 'sponsorshipValues'
    }
  },
  facilities: {
    parent: 'sponsorshipParent',
    children: {
      school: 'school',
      mosque: 'mosque',
      hospital: 'hospital',
      orphanage: 'orphanage'
    }
  },
  donation: {
    parent: 'sponsorshipValues',
    children: {
      donations: 'donations',
      allDonations: 'allDonations',
      addDonation: 'addDonation',
      donationSettings: 'donationSettings',
      donationPackages: 'donationPackages',
      urgentAppeals: 'urgentAppeals'
    }
  },
  giftAid: {
    parent: 'giftAid',
    children: {
      giftAid: 'giftAid'
    }
  },
  eventScheduler: {
    parent: 'eventScheduler',
    children: {
      eventScheduler: 'eventScheduler'
    }
  },
  settings: {
    parent: 'settingsParent',
    children: {
      organizationDetails: 'organizationDetails',
      users: 'users',
      personal: 'personal',
      branches: 'branches',
      payment: 'payment',
      smtp: 'smtp'
    }
  }
};

export const userActions = {
  read: 'read',
  create: 'create',
  update: 'update',
  delete: 'delete',
  manage: 'manage'
};
