import { gql, useMutation } from '@apollo/client';
import PackageForm from './package-form/PackageForm';
import { toast } from 'react-toastify';
import { GET_PACKAGES_BY_ORGANIZATION, PACKAGE_STATUS_ACTIVE } from '.';
import AuthContext from 'context/authContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';

const CREATE_PACKAGE = gql`
  mutation CreatePackage(
    #    $currency_id: uuid = ""
    $donation_amount: jsonb = ""
    $donation_amounts: String = ""
    $donation_type: jsonb = ""
    $donation_types: String = ""
    #    $hasSuccessUrl: Boolean = false
    #    $hasTransactionFees: Boolean = false
    $location: jsonb = ""
    $organisation_id: uuid = ""
    #    $package_category: jsonb = ""
    $package_description: String = ""
    $package_title: String = ""
    $payment_options: jsonb = ""
    #    $redirect_url: String = ""
    #    $transaction_fee: jsonb = ""
    #    $target_amount: float8 = ""
    $status: String = ""
  ) {
    insert_packages_one(
      object: {
        #        currency_id: $currency_id
        donation_amount: $donation_amount
        donation_amounts: $donation_amounts
        donation_type: $donation_type
        donation_types: $donation_types
        #        hasSuccessUrl: $hasSuccessUrl
        #        hasTransactionFees: $hasTransactionFees
        location: $location
        organisation_id: $organisation_id
        #        package_category: $package_category
        package_description: $package_description
        package_title: $package_title
        payment_options: $payment_options
        #        redirect_url: $redirect_url
        status: $status
        #        transaction_fee: $transaction_fee
        #        target_amount: $target_amount
      }
    ) {
      created_at
      #      currency_id
      donation_amount
      donation_type
      #      hasSuccessUrl
      #      hasTransactionFees
      id
      location
      organisation_id
      #      package_category
      package_description
      package_title
      payment_options
      #      redirect_url
      status
      #      target_amount
      #      transaction_fee
      updated_at
    }
  }
`;

function CreatePackage() {
  const navigate = useNavigate();
  const { organization } = useContext(AuthContext);
  const [createPackage, { loading }] = useMutation(CREATE_PACKAGE, {
    onCompleted: () => {
      toast.success('Package created successfully');
      navigate(paths.donationPackages);
    },
    onError: error => {
      toast.error(error.message);
      console.log(error);
    },
    update: (cache, data) => {
      const newPackage = data.data.insert_packages_one;
      const existingPackages = cache.readQuery({
        query: GET_PACKAGES_BY_ORGANIZATION,
        variables: { organizationId: organization.id }
      });
      if (existingPackages) {
        cache.writeQuery({
          query: GET_PACKAGES_BY_ORGANIZATION,
          variables: { organizationId: organization.id },
          data: {
            packages: [newPackage, ...existingPackages.packages]
          }
        });
      }
    }
  });
  const onSubmit = data => {
    createPackage({
      variables: {
        ...data,
        organisation_id: organization.id,
        status: PACKAGE_STATUS_ACTIVE
      }
    });
  };
  return <PackageForm onSubmit={onSubmit} loading={loading} />;
}

export default CreatePackage;
