import { yupResolver } from '@hookform/resolvers/yup';
import { TITLE_OPTIONS } from 'components/Home/data';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import {
  FormProvider,
  RHFControl,
  RHFPhoneInput,
  RHFSelect
} from 'components/common/form';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

/* -------------------------------------------------------------------------- */

export const contactSchema = Yup.object().shape({
  title: Yup.string().required('Required Field'),
  first_name: Yup.string().required('Required Field'),
  last_name: Yup.string().required('Required Field'),
  phone: Yup.string(),
  email: Yup.string().email()
});
/* -------------------------------------------------------------------------- */
export default function ContactForm({ onSubmit, initValues, loading }) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      title: '',
      first_name: '',
      last_name: '',
      phone: '',
      email: ''
    },
    resolver: yupResolver(contactSchema)
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (initValues) {
      reset({
        ...initValues[0]
      });
    }
  }, [reset, initValues]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card className="my-3">
          <Card.Header>
            <Card.Title className="fw-medium"> Person to contact </Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <Row className="mb-3 g-3">
              <Col md={6}>
                <RHFSelect
                  name="title"
                  label="Title"
                  placeholder="Title"
                  options={TITLE_OPTIONS}
                  required
                />
              </Col>

              <Col md={6}></Col>

              <Col md={6}>
                <RHFControl
                  name="first_name"
                  placeholder="First Name"
                  label="First Name"
                  required
                />
              </Col>

              <Col md={6}>
                <RHFControl
                  name="last_name"
                  placeholder="Last Name"
                  label="Last Name"
                  required
                />
              </Col>

              <Col md={6}>
                <RHFPhoneInput
                  name="phone"
                  placeholder="Phone Number"
                  label="Phone Number"
                />
              </Col>

              <Col md={6}>
                <RHFControl name="email" placeholder="Email" label="Email" />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <Flex justifyContent={'between'} className="align-items-center">
              <p className="mb-0 fs-2 fw-medium">
                {initValues?.length !== 0 ? 'Update Contact' : 'Create Contact'}
              </p>
              <LoadingButton
                loading={loading}
                type="submit"
                className="px-5 py-2"
              >
                Submit
              </LoadingButton>
            </Flex>
          </Card.Body>
        </Card>
      </FormProvider>
    </>
  );
}

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initValues: PropTypes.func.array,
  loading: PropTypes.bool
};
