import { useMutation, useQuery } from '@apollo/client';
import TableRowAction from 'components/common/TableRowAction';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import AuthContext from 'context/authContext';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import { DELETE_ORPHANAGE_ORPHAN, INSERT_ORPHANAGE_ORPHAN } from '../mutations';
import { GET_ORPHANAGE_BY_ORPHAN } from '../queries';
import AddOrphanageModal from './AddOrphanageModal';

function BeneficiaryOrphanage() {
  const orphanageColumns = [
    {
      accessor: 'id',
      Header: 'ID',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'Address',
      Header: 'Location',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const {
          orphanages_location: { location_name }
        } = rowData.row.original;
        return <>{location_name}</>;
      }
    },
    {
      accessor: 'sponsor',
      Header: 'Sponsor',
      cellProps: {
        className: 'py-2'
      },
      Cell: () => {
        return <>{organization.organisation_name}</>;
      }
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <>{dayjs(created_at).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            isNotEdit
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.facilities.children.orphanage}
          />
        );
      }
    }
  ];

  const { organization } = useContext(AuthContext);
  const { id } = useParams();
  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddOrphanage, setShowAddOrphanage] = useState(false);
  const { data: orphanageByOrphanListData, loading: orphanageOrphanLoading } =
    useQuery(GET_ORPHANAGE_BY_ORPHAN, {
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    });

  const [deleteOrphanage] = useMutation(DELETE_ORPHANAGE_ORPHAN, {
    refetchQueries: ['GetOrphanageByOrphan'],
    onCompleted: () => {
      toast.success('Orphanage Deleted Successfully');
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const [addOrphanageOrphan] = useMutation(INSERT_ORPHANAGE_ORPHAN, {
    refetchQueries: ['GetOrphanageByOrphan'],
    onCompleted: () => {
      toast.success('Orphans Added Successfully');
      setShowAddOrphanage(false);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
      setShowAddOrphanage(false);
    }
  });

  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const onDelete = () => {
    // delete call
    deleteOrphanage({ variables: { id: selectedId } });
    setShowDeleteModal(false);
  };

  const submitAddOrphanage = data => {
    addOrphanageOrphan({
      variables: { objects: [{ orphanage_id: data.orphanage, orphan_id: id }] }
    });
  };

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        handleConfirm={onDelete}
        title="Delete Orphanage"
        body="Are you sure you want to delete this Orphanage?"
      />

      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {orphanageByOrphanListData?.orphan_orphanage && (
            <CustomAdvanceTable
              data={
                orphanageByOrphanListData?.orphan_orphanage.map(
                  (orphanageOrphan, index) => ({
                    ...orphanageOrphan?.orphanage,
                    key: `${orphanageOrphan?.orphanage?.id}-${index}`
                  })
                ) || []
              }
              columns={orphanageColumns}
              title="Orphanage"
              addButtonLabel="Add Orphanage"
              onClickAddNew={() => {
                setShowAddOrphanage(true);
              }}
              loading={orphanageOrphanLoading}
              addNew
              addButtonIcon={'plus'}
              subject={permissionsKeyMap.facilities.children.orphanage}
            />
          )}
        </Col>
      </Row>

      {showAddOrphanage && (
        <AddOrphanageModal
          show={showAddOrphanage}
          onSubmit={data => {
            submitAddOrphanage(data);
          }}
          onHide={() => {
            setShowAddOrphanage(false);
          }}
          title={'Add orphanage'}
        />
      )}
    </>
  );
}

export default BeneficiaryOrphanage;
