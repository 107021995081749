import { useEffect } from 'react';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import { DONATION_STEPS } from './data';

import { DonorInformationPage } from './donor-information';
import { DonationPaymentPage } from './DonationPayment';
import { DonationThanksPage } from './DonationThanks';
import { DonationHeader } from './DonationHeader';
import { QuickDonationForm } from './QuickDonationForm';
import { PackageList } from './PackageList';
import { DonationFooter } from './DonationFooter';

const Home = () => {
  const { donationStep } = useDonationContextProvider();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [donationStep]);

  useEffect(() => {
    document.body.style.backgroundColor = '#fff';
  }, []);

  switch (donationStep) {
    case DONATION_STEPS.DONOR_INFORMATION:
      return <DonorInformationPage />;
    case DONATION_STEPS.PAYMENT:
      return <DonationPaymentPage />;
    case DONATION_STEPS.THANKS:
      return <DonationThanksPage />;
  }

  return (
    <div className="d-flex flex-column min-vh-100">
      <DonationHeader />
      <main className="flex-1 d-flex flex-column">
        <div className="container-fluid px-3 px-md-6 pt-7 pb-6">
          <QuickDonationForm />
        </div>
        <div className="flex-1 container-fluid py-6 px-md-6 bg-wr-donation-grey-120">
          <PackageList />
        </div>
      </main>
      <DonationFooter />
    </div>
  );
};

export default Home;
