import { gql } from '@apollo/client';

export const CREATE_DONOR = gql`
  mutation CreateDonor(
    $address: jsonb = ""
    $last_name: String = ""
    $phone: jsonb = ""
    $organisation_id: uuid = ""
    $email: jsonb = ""
    $first_name: String = ""
    $title: String = ""
  ) {
    insert_donars_one(
      object: {
        address: $address
        email: $email
        first_name: $first_name
        gift_aid_elgibility: true
        last_name: $last_name
        phone: $phone
        organisation_id: $organisation_id
        title: $title
      }
    ) {
      id
    }
  }
`;

export const CREATE_DONATION = gql`
  mutation InsertOrder(
    $donar_id: uuid
    $organisation_id: uuid
    $order_details: [order_details_insert_input!] = {
      amount: ""
      billing_condition: ""
      donation_type: ""
      package_id: ""
    }
    $payment_status: String = "Pending"
    $subtotal: float8 = ""
    $transaction_fees: float8 = ""
    $donation_amount: float8 = ""
  ) {
    insert_orders_one(
      object: {
        donar_id: $donar_id
        organisation_id: $organisation_id
        order_details: { data: $order_details }
        payment_status: $payment_status
        subtotal: $subtotal
        transaction_fees: $transaction_fees
        donation_amount: $donation_amount
      }
    ) {
      donation_amount
      gift_aid_eligible
      payment_status
      id
      created_at
      updated_at
      donar_id
      transaction_fees
      subtotal
      organisation_id
    }
  }
`;
export const INSERT_ORDER_DOANTION = gql`
  mutation InsertOrderOne(
    $address: jsonb = ""
    $last_name: String = ""
    $phone: jsonb = ""
    $organisation_id: uuid = ""
    $email: jsonb = ""
    $first_name: String = ""
    $title: String = ""
    $order_details: order_details_arr_rel_insert_input! = {
      amount: ""
      billing_condition: ""
      donation_type: ""
      package_id: ""
    }
    $subtotal: float8 = ""
    $transaction_fees: float8 = ""
    $donation_amount: float8 = ""
  ) {
    insert_orders_one(
      object: {
        donar: {
          data: {
            address: $address
            last_name: $last_name
            phone: $phone
            organisation_id: $organisation_id
            email: $email
            first_name: $first_name
            title: $title
          }
        }
        organisation_id: $organisation_id
        payment_status: "Pending"
        subtotal: $subtotal
        transaction_fees: $transaction_fees
        donation_amount: $donation_amount
        order_details: $order_details
      }
    ) {
      donation_amount
      gift_aid_eligible
      payment_status
      id
      created_at
      updated_at
      donar_id
      transaction_fees
      subtotal
      organisation_id
    }
  }
`;
