/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { RHFCheck } from 'components/common/form';

export const ChecksOptionsCard = ({
  card = {
    title: '',
    id: '',
    current_title: '',
    switch: false,
    switchValue: false,
    handleSwitch: () => {},
    checkType: 'checkbox',
    isEdit: true,
    isSubEdit: false,
    checkboxes: [],
    handleChange: () => {}
  },
  sectionRef,
  sideNavLink,
  onEdit,
  children,
  error
}) => {
  return (
    <Card
      key={card.id}
      className="border shadow-none mb-3"
      ref={sectionRef}
      id={sideNavLink}
    >
      <Card.Header>
        <Row>
          <Col>
            <Card.Title>{card.title}</Card.Title>
          </Col>
          {error && (
            <p className="text-danger " style={{ fontSize: '14px' }}>
              Required Field
            </p>
          )}

          <Col className="d-flex justify-content-end">
            {card?.switch ? (
              <Card.Title>
                <Form.Group as={Row}>
                  <Col>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={card?.switchValue}
                      onChange={e => card.handleSwitch(e)}
                    />
                  </Col>
                </Form.Group>
              </Card.Title>
            ) : card?.isEdit ? (
              <button
                type="button"
                onClick={onEdit}
                className="btn btn-link"
                style={{
                  fontWeight: 500,
                  fontSize: '13px',
                  color: '#5D6E82'
                }}
              >
                <FontAwesomeIcon icon="pen" style={{ marginRight: '5px' }} />
                Edit
              </button>
            ) : null}
          </Col>
        </Row>
      </Card.Header>
      {card?.switchValue || !card.switch ? (
        <Card.Body className="pt-0">
          {card.current_title ? (
            <Row className="">
              <Col>
                <Card.Title>{card.current_title}</Card.Title>
              </Col>
              <Col className="d-flex justify-content-end">
                <button
                  type="button"
                  onClick={onEdit}
                  className="btn btn-link"
                  style={{
                    fontWeight: 500,
                    fontSize: '13px',
                    color: '#5D6E82'
                  }}
                >
                  <FontAwesomeIcon icon="pen" style={{ marginRight: '5px' }} />
                  Edit
                </button>
              </Col>
            </Row>
          ) : null}
          {card?.checkboxes?.map(({ name, value, label }) => {
            return (
              <RHFCheck
                key={value ?? name}
                name={name}
                label={label}
                type={card.checkType ?? 'checkbox'}
              />
            );
          })}
          {card?.checkboxes?.length === 0 && (
            <h5 className="text-center text-500">No options available</h5>
          )}
          {children && <div className="mt-3">{children}</div>}
        </Card.Body>
      ) : null}
    </Card>
  );
};
