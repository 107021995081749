import { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import {
  PAYMENT_OPTION_MONTHLY,
  PAYMENT_OPTION_ONE_OFF
} from '../donations/donation-packages/package-form/PackageForm';

import { Button, Form, Offcanvas } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FormProvider, RHFSelect } from 'components/common/form';

import { useDonationContextProvider } from 'context/DonationContextProvider';

function PackageDrawer(props) {
  const {
    paymentTypeOptions,
    amountOptions,
    typeOptions,
    hasCustomAmount,
    packageId,
    packageName
  } = props;

  const [isCustomAmountSelected, setIsCustomAmountSelected] = useState(false);

  const { addDonationToBasket, isPackageDrawerOpen, setIsPackageDrawerOpen } =
    useDonationContextProvider();

  const availablePaymentTypeOptions = [
    PAYMENT_OPTION_ONE_OFF,
    PAYMENT_OPTION_MONTHLY
  ];
  const visiblePaymentTypeOptions = paymentTypeOptions.filter(option =>
    availablePaymentTypeOptions.includes(option)
  );
  const defaultPaymentType =
    visiblePaymentTypeOptions.length > 0
      ? visiblePaymentTypeOptions.find(
          option => option === PAYMENT_OPTION_ONE_OFF
        ) || visiblePaymentTypeOptions[0]
      : PAYMENT_OPTION_ONE_OFF;

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      paymentType: defaultPaymentType,
      donationType: null,
      donationAmount: {
        id: null,
        value: null
      }
    },
    resolver: yupResolver(donationPackageSchema)
  });
  const {
    watch,
    setValue,
    reset,
    formState: { errors },
    handleSubmit
  } = form;
  const paymentType = watch('paymentType');
  const donationAmount = watch('donationAmount');

  const resetForm = useCallback(() => {
    setIsCustomAmountSelected(false);
    reset();
  }, [setIsCustomAmountSelected, reset]);

  const onAddDonation = data => {
    const donationTypeId = data.donationType;
    addDonationToBasket({
      ...data,
      packageId,
      packageName,
      donationType: {
        id: donationTypeId,
        name: typeOptions.find(type => type.id === donationTypeId)?.type
      },
      count: 1,
      id: uuid()
    });
    resetForm();
    toast.success('Donation added to the basket.');
    setIsPackageDrawerOpen(false);
  };

  const closeDrawerHandler = () => {
    setIsPackageDrawerOpen(false);
    // if (isCartDrawerOpen) {
    //   setIsCartDrawerOpen(false);
    // }
    // if (isGiftAidDrawerOpen) {
    //   setIsGiftAidDrawerOpen(false);
    // }
  };

  useEffect(() => {
    if (isPackageDrawerOpen) {
      resetForm();
    }
  }, [isPackageDrawerOpen, resetForm]);

  // let rightPosition = 0;
  // let drawerWidth = '500px';
  // if (isCartDrawerOpen) {
  //   rightPosition = '500px';
  // }
  // if (isGiftAidDrawerOpen) {
  //   drawerWidth = '400px';
  //   rightPosition = '800px';
  // }

  return (
    <Offcanvas
      show={isPackageDrawerOpen}
      onHide={() => setIsPackageDrawerOpen(false)}
      placement="end"
      backdrop="static"
      style={{ width: '90%', maxWidth: 500 }}
    >
      <Offcanvas.Header>
        <Button
          className="bg-transparent shadow-none border-0 fs-2 p-0 text-danger"
          onClick={closeDrawerHandler}
        >
          &times;
        </Button>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <h2 className="text-uppercase wr-donation-header2 wr-donation-donor-form-header pb-3 mb-4">
          Add Your Donation
        </h2>
        <FormProvider methods={form} onSubmit={handleSubmit(onAddDonation)}>
          <div className="px-2 d-flex flex-column gap-4">
            <div className="d-flex flex-column flex-md-row gap-4">
              {visiblePaymentTypeOptions.map(option => (
                <Button
                  key={option}
                  className={classNames('outline-none rounded-3', {
                    'bg-wr-donation-blue-200 wr-donation-text-primary border-0':
                      paymentType === option,
                    'bg-wr-donation-grey-0 wr-donation-text-grey-900 border border-2':
                      paymentType !== option
                  })}
                  style={{ width: '245px', height: '64px' }}
                  onClick={() => {
                    setValue('paymentType', option);
                  }}
                >
                  <span className="text-capitalize">
                    {option === PAYMENT_OPTION_ONE_OFF ? 'single' : option}{' '}
                  </span>
                  Payment
                  {paymentType === option && (
                    <Form.Check
                      type="checkbox"
                      inline
                      className="ms-2 wr-donation-payment-type-checkbox"
                      defaultChecked
                    />
                  )}
                </Button>
              ))}
            </div>

            <div>
              <span className="wr-donation-body6 text-black d-inline-block mb-2 me-1">
                Donation Amount
              </span>
              <span className="text-danger">*</span>
              <Flex wrap="wrap" gap="3">
                {amountOptions.map(amount => (
                  <Button
                    key={amount.id}
                    style={{ width: '120px', height: '50px' }}
                    className={classNames(
                      'wr-donation-text-primary fw-bold border-0 rounded-3',
                      {
                        'bg-wr-donation-primary text-white':
                          donationAmount.id === amount.id,
                        'bg-wr-donation-blue-200':
                          donationAmount.id !== amount.id
                      }
                    )}
                    onClick={() => {
                      setValue('donationAmount', {
                        id: amount.id,
                        value: amount.amount
                      });
                      setIsCustomAmountSelected(false);
                    }}
                  >
                    {amount.amount}
                  </Button>
                ))}
                {hasCustomAmount && (
                  <Button
                    className="bg-white wr-donation-text-primary"
                    onClick={() => {
                      setIsCustomAmountSelected(true);
                      setValue('donationAmount', { id: null, value: null });
                    }}
                  >
                    Custom Amount
                  </Button>
                )}
              </Flex>
              {isCustomAmountSelected && (
                <Form.Control
                  className="mt-3"
                  required
                  type="number"
                  placeholder="Custom Amount"
                  onChange={e => {
                    setValue('donationAmount', {
                      id: 'other',
                      value: e.target.value
                    });
                  }}
                />
              )}
              {errors.donationAmount?.value && (
                <small className="text-danger">
                  {errors.donationAmount.value.message}
                </small>
              )}
            </div>

            <div>
              <RHFSelect
                name="donationType"
                label={
                  <span className="wr-donation-body6 text-black me-1">
                    Donation Type
                  </span>
                }
                required
                options={typeOptions.map(item => ({
                  label: item.type,
                  value: item.id
                }))}
              />
            </div>

            <Button
              className="px-6 py-2 fw-bold text-white bg-wr-donation-primary border-0"
              type="submit"
            >
              Add Donation &gt;
            </Button>
          </div>
        </FormProvider>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

const donationPackageSchema = Yup.object().shape({
  packageName: Yup.string(),
  packageId: Yup.string(),
  paymentType: Yup.string().required('Payment type is required'),
  donationAmount: Yup.object().shape({
    id: Yup.string().nullable(),
    value: Yup.number().nullable().required('Donation amount value is required')
  }),
  donationType: Yup.string().required('Donation type is required')
});

PackageDrawer.propTypes = {
  paymentTypeOptions: PropTypes.arrayOf(PropTypes.string),
  amountOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, amount: PropTypes.number })
  ),
  typeOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, type: PropTypes.string })
  ),
  hasCustomAmount: PropTypes.bool,
  packageId: PropTypes.string,
  packageName: PropTypes.string
};

export { PackageDrawer };
