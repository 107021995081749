import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from './AdvanceTableFooter';
import AdvanceTablePagination from './AdvanceTablePagination';
import AdvanceTableSearchBox from './AdvanceTableSearchBox';
import { Can } from 'routes/permissions/Can';
import { userActions } from 'routes/permissions/permissionKeys';

export const BULK_ACTION_EDIT = 'EDIT';
export const BULK_ACTION_DELETE = 'DELETE';

const CustomAdvanceTable = ({
  columns,
  data,
  title = 'Selection Example',
  addButtonLabel = 'New',
  addButtonIcon = 'plus',
  showSearchInput = false,
  searchInputPlaceHolder = 'Search by name',
  onClickAddNew = () => {},
  addNew = false,
  rowsPerPageSelection = false,
  viewAllBtn = true,
  perPage = 10,
  showTablePagination = false,
  loading,
  bulkActionsOptions,
  handleBulkAction,
  selection = false,
  hasFilters,
  hasExport,
  handleExport,
  headerContent,
  subject,
  handleFilter,
  pagination = {
    showInfo: true,
    showNavButtons: true
  }
}) => {
  // eslint-disable-next-line react/prop-types
  function BulAction({ selectedRowIds, globalFilter, setGlobalFilter }) {
    const objectLength = Object.keys(selectedRowIds).length;
    const [selectBulkAction, setSelectBulkAction] = useState();
    const selectedIds = Object.keys(selectedRowIds).map(id => id);
    return (
      <Row className="flex-between-center mb-3 ">
        <Col
          sm="auto"
          className="d-md-flex align-items-center justify-content-md-start pe-md-0"
        >
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            {objectLength > 0
              ? 'You have selected ' +
                objectLength +
                ` row${objectLength > 1 ? 's' : ''}`
              : title}
          </h5>

          {showSearchInput && (
            <div className="ms-md-4 mt-2 mt-md-0">
              <AdvanceTableSearchBox
                className="table-input-search-width"
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                placeholder={searchInputPlaceHolder}
                showSearchIcon={false}
              />
            </div>
          )}
        </Col>
        <Col sm="auto" className="text-md-end ps-md-0 mt-3 mt-md-0">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select
                onChange={e => setSelectBulkAction(e.target.value)}
                value={selectBulkAction}
                size="sm"
                aria-label="Bulk actions"
              >
                <option>Bulk Actions</option>
                {bulkActionsOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
                onClick={() => handleBulkAction(selectBulkAction, selectedIds)}
              >
                Apply
              </Button>
            </div>
          ) : (
            <div id="orders-actions">
              {!!headerContent && headerContent}
              {addNew && (
                <Can I={userActions.create} a={subject}>
                  <IconButton
                    variant="primary"
                    size="sm"
                    icon={addButtonIcon}
                    iconClassName="text-light"
                    transform="shrink-3"
                    onClick={onClickAddNew}
                    className="me-2"
                  >
                    <span className="text-light d-none d-lg-inline-block ms-2">
                      {addButtonLabel}
                    </span>
                  </IconButton>
                </Can>
              )}
              {hasFilters && (
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="filter"
                  transform="shrink-3"
                  className="me-2"
                  onClick={() => handleFilter()}
                >
                  <span className="d-none d-lg-inline-block ms-2">Filter</span>
                </IconButton>
              )}
              {hasExport && (
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={handleExport}
                >
                  <span className="d-none d-lg-inline-block ms-2">Export</span>
                </IconButton>
              )}
            </div>
          )}
        </Col>
      </Row>
    );
  }

  return (
    <Card className="border shadow-none">
      <Card.Body>
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          sortable
          pagination
          perPage={perPage}
          selection={selection}
          selectionColumnWidth={30}
        >
          <BulAction table />
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden',
              size: 'sm'
            }}
            loading={loading}
          />
          <div className="mt-3">
            {showTablePagination ? (
              <AdvanceTablePagination table />
            ) : (
              <AdvanceTableFooter
                rowCount={data.length}
                table
                rowInfo={pagination.showInfo}
                navButtons={pagination.showNavButtons}
                onClickViewAll={pagination.onClickViewAll}
                rowsPerPageSelection={rowsPerPageSelection}
                viewAllBtn={viewAllBtn}
                perPage={perPage}
              />
            )}
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

CustomAdvanceTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  title: PropTypes.string,
  addButtonLabel: PropTypes.string,
  addButtonIcon: PropTypes.string,
  searchInputPlaceHolder: PropTypes.string,
  showSearchInput: PropTypes.bool,
  onClickAddNew: PropTypes.func,
  rowClickEvent: PropTypes.func,
  addNew: PropTypes.bool,
  content: PropTypes.node,
  rowsPerPageSelection: PropTypes.bool,
  viewAllBtn: PropTypes.bool,
  showTablePagination: PropTypes.bool,
  perPage: PropTypes.number,
  loading: PropTypes.bool,
  bulkActionsOptions: PropTypes.array,
  handleBulkAction: PropTypes.func,
  selection: PropTypes.bool,
  hasFilters: PropTypes.bool,
  hasExport: PropTypes.bool,
  handleExport: PropTypes.func,
  headerContent: PropTypes.node,
  subject: PropTypes.string,
  handleFilter: PropTypes.func,
  pagination: PropTypes.shape({
    showInfo: PropTypes.bool,
    showNavButtons: PropTypes.bool,
    onClickViewAll: PropTypes.func
  })
};

export default CustomAdvanceTable;
