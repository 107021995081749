import SoftBadge from 'components/common/SoftBadge';
import TableRowAction from 'components/common/TableRowAction';
import DonorCounter from 'components/donor/DonorCounter';
import { weDonationItems } from 'data/weraise-data/donor-data';
import { useState, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import AuthContext from 'context/authContext';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import paths from 'routes/paths';
import { parseRouteParams } from 'helpers/utils';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import { toast } from 'react-toastify';
import ExportModal from 'components/common/ExportModal';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

export const PACKAGE_STATUS_ACTIVE = 'active';
export const PACKAGE_STATUS_INACTIVE = 'inactive';

const packageStatusMap = {
  [PACKAGE_STATUS_ACTIVE]: {
    label: 'Active',
    color: 'success'
  },
  [PACKAGE_STATUS_INACTIVE]: {
    label: 'Inactive',
    color: 'muted'
  }
};

export const GET_PACKAGES_BY_ORGANIZATION = gql`
  query GetPackagesByOrganization($organizationId: uuid!) {
    packages(
      order_by: { created_at: desc }
      where: {
        organisation_id: { _eq: $organizationId }
        is_hidden: { _eq: false }
      }
    ) {
      currency_id
      donation_amount
      donation_amounts
      donation_type
      donation_types
      location
      id
      simple_id
      organisation_id
      package_category
      package_description
      package_title
      payment_options
      redirect_url
      status
      transaction_fee
      branch_id
      branch {
        name
      }
    }
  }
`;
const DELETE_PACKAGE = gql`
  mutation DeletePackage($id: uuid!) {
    update_packages_by_pk(pk_columns: { id: $id }, _set: { is_hidden: true }) {
      id
    }
  }
`;

const DonationPackages = () => {
  const { organization } = useContext(AuthContext);
  const { data, loading } = useQuery(GET_PACKAGES_BY_ORGANIZATION, {
    variables: { organizationId: organization.id }
  });

  const [deletePackage, { loading: deleteLoading }] = useMutation(
    DELETE_PACKAGE,
    {
      refetchQueries: ['GetPackagesByOrganization'],
      onCompleted: () => {
        handleDeleteModalHide();
        toast.success('Package deleted successfully');
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const navigate = useNavigate();
  const [showExportModal, setShowExportModal] = useState(false);

  const handleExport = () => {
    setShowExportModal(true);
  };
  const columns = [
    {
      accessor: 'simple_id',
      Header: 'Reference',
      cellProps: {
        className: 'py-2 fw-medium '
      }
    },
    {
      accessor: 'package_title',
      Header: 'Package Title',
      cellProps: {
        className: 'py-2 fw-medium '
      }
      // Cell: rowData => {
      //   const { package_title } = rowData.row.original;
      //   return (
      //     <Flex alignItems="center">
      //       <Button
      //         variant="link"
      //         // onClick={() => setModalShow(true)}
      //         className="fw-medium fs--1 p-0 text-1100 hover-primary"
      //       >
      //         {package_title}
      //       </Button>
      //     </Flex>
      //   );
      // }
    },
    {
      accessor: 'package_description',
      Header: 'Description',
      cellProps: {
        className: 'py-2 fw-medium '
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'fw-medium  py-2'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        const { label, color } = packageStatusMap[status] || {
          label: '-',
          color: 'muted'
        };
        return status ? (
          <SoftBadge pill bg={color} className="me-2">
            {label}
          </SoftBadge>
        ) : (
          <>&mdash;</>
        );
      }
    },
    {
      accessor: 'id_d',
      Header: '',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end fw-medium py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickEdit={() =>
              navigate(parseRouteParams(paths.editPackage, { id }))
            }
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.donation.children.donationPackages}
          />
        );
      }
    }
  ];
  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };
  const handleDeleteModalHide = () => {
    setSelectedId(null);
    setShowDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    deletePackage({
      variables: {
        id: selectedId
      }
    });
  };

  const fileName = 'Donation Packages';
  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={handleDeleteModalHide}
        loading={deleteLoading}
        title="Delete Package"
        handleConfirm={handleConfirmDelete}
        body="Are you sure you want to delete selected package?"
      />
      <Row>
        <Col>
          <DonorCounter
            counterData={weDonationItems({ packages: data?.packages ?? [] })}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {data?.packages && (
            <CustomAdvanceTable
              data={data?.packages}
              columns={columns}
              title={'Packages'}
              addButtonLabel="Create New Package"
              showSearchInput
              searchInputPlaceHolder="Search Packages"
              showTablePagination={false}
              perPage={10}
              onClickAddNew={() => navigate(paths.createPackage)}
              loading={loading}
              addNew
              hasExport
              handleExport={handleExport}
              subject={permissionsKeyMap.donation.children.donationPackages}
            />
          )}
        </Col>
      </Row>
      <ExportModal
        data={data?.packages ?? []}
        columns={columns.slice(0, columns.length - 1)}
        show={showExportModal}
        onHide={() => setShowExportModal(false)}
        fileName={fileName}
      />
    </>
  );
};

export default DonationPackages;
