import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import DonorCommunicationModal from './modals/DonorCommunicationModal';
import { gql, useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CommunicationModal from './CommunicationModal';

const GET_DONOR_COMMUNICATION = gql`
  query GetDonorCommunication($id: uuid!) {
    donars_communication(
      order_by: { created_at: desc }
      where: { donar_id: { _eq: $id } }
    ) {
      communication_id
      communication_type
      created_at
      donar_id
      id
      messsage_content
      status
      subject
      updated_at
    }
  }
`;

const CREATE_COMMUNICATION = gql`
  mutation CreateCommunication(
    $donar_id: uuid!
    $subject: String!
    $messsage_content: String!
  ) {
    insert_donars_communication_one(
      object: {
        donar_id: $donar_id
        subject: $subject
        messsage_content: $messsage_content
      }
    ) {
      id
      donar_id
      subject
      messsage_content
      created_at
      status
    }
  }
`;

const DonorCommunications = () => {
  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);

  const { loading, data } = useQuery(GET_DONOR_COMMUNICATION, {
    variables: { id }
  });

  const columns = [
    {
      accessor: 'ref',
      Header: 'Ref',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return id;
      }
    },
    {
      accessor: 'date',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return dayjs(created_at).format('MMM-DD-YYYY');
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { communication_type } = rowData.row.original;
        return communication_type;
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        return (
          <>
            {rowData.row.original.status ? (
              <SoftBadge pill bg={'success'} className="me-2">
                {rowData.row.original.status} <FontAwesomeIcon icon={'check'} />
              </SoftBadge>
            ) : (
              <></>
            )}{' '}
          </>
        );
      }
    },
    {
      accessor: 'title',
      Header: 'Title',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { subject } = rowData.row.original;
        return subject;
      }
    }
    // {
    //   accessor: 'id',
    //   DonationHeader: '',
    //   cellProps: {
    //     className: 'py-2 text-end'
    //   },
    //   disableSortBy: true,

    //   Cell: rowData => {
    //     const { id } = rowData.row;
    //     return (
    //       <TableRowAction
    //         onCLickEdit={() => console.log(id)}
    //         onCLickDelete={() => console.log(id)}
    //       />
    //     );
    //   }
    // }
  ];

  const tableData = data?.donars_communication;

  const [showCommunicationForm, setShowCommunicationForm] = useState(false);

  const [createCommunication, { loading: createCommunicationLoading }] =
    useMutation(CREATE_COMMUNICATION, {
      onCompleted: () => {
        setShowCommunicationForm(false);
        toast.success('Comment created successfully');
      },
      onError: () => {
        toast.error('Something went wrong! Please try again');
      },
      update: (cache, { data: { insert_donars_communication_one } }) => {
        const { donars_communication } = cache.readQuery({
          query: GET_DONOR_COMMUNICATION,
          variables: { id }
        });
        if (!donars_communication) return;
        cache.writeQuery({
          query: GET_DONOR_COMMUNICATION,
          variables: { id },
          data: {
            donars_communication: [
              insert_donars_communication_one,
              ...donars_communication
            ]
          }
        });
      }
    });

  const handleCreateCommunication = data => {
    createCommunication({
      variables: {
        subject: data.subject,
        messsage_content: data.messsage_content,
        donar_id: id
      }
    });
  };

  return (
    <>
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {tableData && (
            <CustomAdvanceTable
              data={tableData}
              columns={columns}
              title="Communications"
              showSearchInput
              searchInputPlaceHolder="Search"
              loading={loading}
              addNew
              onClickAddNew={() => setShowCommunicationForm(true)}
              subject={permissionsKeyMap.donor.children.donors}
            />
          )}
        </Col>
      </Row>

      {modalShow && (
        <DonorCommunicationModal
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}

      {showCommunicationForm && (
        <CommunicationModal
          show={showCommunicationForm}
          onHide={() => setShowCommunicationForm(false)}
          onSubmit={handleCreateCommunication}
          loading={createCommunicationLoading}
        />
      )}
    </>
  );
};

export default DonorCommunications;
