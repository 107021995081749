const DONATION_STEPS = Object.freeze({
  BASKET_CREATION: 'BASKET_CREATION',
  DONOR_INFORMATION: 'DONOR_INFORMATION',
  PAYMENT: 'PAYMENT',
  THANKS: 'THANKS'
});

const TITLE_OPTIONS = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Dr', label: 'Dr' },
  { value: 'Prof', label: 'Prof' },
  { value: 'Sheikh', label: 'Sheikh' },
  { value: 'Imam', label: 'Imam' }
];

const PHONE_NUMBER_TYPE_OPTIONS = [
  { value: 'personal', label: 'Personal' },
  { value: 'home', label: 'Home' },
  { value: 'office', label: 'Office' },
  { value: 'office_2', label: 'Office 2' }
];

export { DONATION_STEPS, TITLE_OPTIONS, PHONE_NUMBER_TYPE_OPTIONS };
