// TODO: Revisit

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AuthContext from 'context/authContext';
import CrudCard from '../../common/CrudCard/CrudCard';
import { getCountriesOptions } from 'data/options/countris-and-cities';

// import LocationsModal from './LocationsModal';

export const locationTypesMap = {
  project: 'Project',
  sponsorship: 'Sponsorship'
};

/* -------------------------------------------------------------------------- */
const CREATE_LOCATION = gql`
  mutation CreateLocation(
    $location_name: String = ""
    $organisation_id: uuid = ""
    $type: String = ""
  ) {
    insert_location_one(
      object: {
        location_name: $location_name
        organisation_id: $organisation_id
        type: $type
      }
    ) {
      id
      created_at
      location_name
      updated_at
      type
      organisation_id
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation UpdateLocation($id: uuid = "", $location_name: String = "") {
    update_location(
      where: { id: { _eq: $id } }
      _set: { location_name: $location_name }
    ) {
      returning {
        id
        created_at
        location_name
        updated_at
        type
        organisation_id
      }
    }
  }
`;

const DELETE_LOCATION = gql`
  mutation DeleteLocation($id: uuid = "") {
    delete_location_by_pk(id: $id) {
      id
    }
  }
`;

const GET_LOCATIONS = gql`
  query GetLocations($where: location_bool_exp!) {
    location(where: $where) {
      id
      created_at
      location_name
      updated_at
      type
      organisation_id
    }
  }
`;

/* -------------------------------------------------------------------------- */
function Locations({ sectionRef, sideNavLink, type, subject }) {
  const cardRef = useRef();
  const { organization } = useContext(AuthContext);

  // const [isLocationsModalOpen, setIsLocationsModalOpen] = useState(false);

  const [where, setWhere] = useState({});

  useEffect(() => {
    setWhere({
      where: { organisation_id: { _eq: organization.id }, type: { _eq: type } }
    });
  }, [organization, type]);
  const [createLocation, { loading: createLoading }] = useMutation(
    CREATE_LOCATION,
    {
      refetchQueries: ['GetLocations'],
      onCompleted: () => {
        toast.success('Location created successfully');
        cardRef.current.onCreationSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [updateLocation, { loading: updateLoading }] = useMutation(
    UPDATE_LOCATION,
    {
      refetchQueries: ['GetLocations'],
      onCompleted: () => {
        toast.success('Location updated successfully');
        cardRef.current.onEditSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [deleteLocation, { loading: deleteLoading }] = useMutation(
    DELETE_LOCATION,
    {
      refetchQueries: ['GetLocations'],
      onCompleted: () => {
        toast.success('Location deleted successfully');
        cardRef.current.onDeleteSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [getLocations, { data }] = useLazyQuery(GET_LOCATIONS);

  useEffect(() => {
    if (Object.keys(where).length) getLocations({ variables: { ...where } });
  }, [where]);

  const handleCreate = ({ location_name }) => {
    createLocation({
      variables: {
        location_name,
        type,
        organisation_id: organization.id
      }
    });
  };
  const handleEdit = ({ id, data }) => {
    updateLocation({
      variables: {
        id,
        location_name: data.location_name
      }
    });
  };
  const handleDelete = id => {
    deleteLocation({
      variables: {
        id
      }
    });
  };
  const list = data?.location.map(location => ({
    id: location.id,
    values: [
      {
        value: location.location_name,
        name: 'location_name'
      }
    ]
  }));

  const countriesOptions = useMemo(() => getCountriesOptions(), []);

  return (
    <>
      <CrudCard
        ref={cardRef}
        sectionRef={sectionRef}
        sideNavLink={sideNavLink}
        createData={handleCreate}
        editData={handleEdit}
        deleteData={handleDelete}
        createLoading={createLoading}
        updateLoading={updateLoading}
        deleteLoading={deleteLoading}
        formTitle={'Add Locations'}
        listTitle={'Current Locations'}
        title={`${type} Locations`}
        inputs={[
          {
            name: 'location_name',
            placeholder: `Add ${type} Location`,
            type: 'select',
            // hasAddOption: true,
            // onClickAdd: () => setIsLocationsModalOpen(true),
            required: true,
            value: '',
            validation: yup.string().required('Location name is required'),
            options: countriesOptions
          }
        ]}
        list={list}
        subject={subject}
      />
      {/*{isLocationsModalOpen && (*/}
      {/*  <LocationsModal*/}
      {/*    show={isLocationsModalOpen}*/}
      {/*    onClose={() => setIsLocationsModalOpen(false)}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
}

Locations.propTypes = {
  sectionRef: PropTypes.string,
  sideNavLink: PropTypes.string,
  type: PropTypes.string,
  subject: PropTypes.string
};

export default Locations;
