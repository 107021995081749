// For Dev ENV: https://dev-api.weraisecrm.com/api/upload-file
// For Prod ENV: http://weraise-api.weraise.svc.cluster.local/api/upload-file

import { useState } from 'react';

export const useUploadFile = () => {
  const [loading, setLoading] = useState(false);

  const uploadFile = async file => {
    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    try {
      const res = await fetch(
        'http://weraise-api.weraise.svc.cluster.local/api/upload-file',
        {
          method: 'POST',
          body: formData
        }
      );
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const response = await res.json();
      return response.url;
    } catch {
      throw new Error('Error happened while uploading the report document');
    } finally {
      setLoading(false);
    }
  };

  return {
    uploadFile,
    loading
  };
};
