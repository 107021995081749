import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Flex from 'components/common/Flex';
import useBulkSelect from 'hooks/useBulkSelect';
import usePagination from 'hooks/usePagination';
import React, { useState } from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

import SponsoredHeader from './SponsoredHeader';
import Avatar from 'components/common/Avatar';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SponsoredCardLayout from './SponsoredCardLayout';
import DonorSponsorshipModal from 'components/donor-details/modals/DonorSponsorshipModal';
import SelectBeneficiaryModal from 'components/beneficiaries/sponsorships/sponsorship-form/SelectBeneficiaryModal';
import SelectDonorModal from '../sponsorships/sponsorship-form/SelectDonorModal';
import { parseRouteParams } from 'helpers/utils';
import paths from 'routes/paths';
import { useNavigate } from 'react-router-dom';

const SponsorShipList = ({
  data,
  initialLayout = 'listView',
  title = 'List'
}) => {
  const navigate = useNavigate();
  const [layout, setLayout] = useState(initialLayout);
  const [modalShow, setModalShow] = useState(false);
  const [beneficiaryModalShow, setBeneficiaryModalShow] = useState(false);
  const [donorModalShow, setDonorModalShow] = useState(false);
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState(null);
  const [parPageData] = useState(10);
  const ticketIds = data?.map(ticket => ticket.id);
  const { selectedItems, isSelectedItem, toggleSelectedItem } =
    useBulkSelect(ticketIds);
  const [primaryTickets, setPrimaryTickets] = useState(data);
  const {
    paginationState: {
      data: paginatedTicket,
      totalItems,
      canNextPage,
      currentPage,
      canPreviousPage,
      paginationArray
    },
    nextPage,
    prevPage,
    goToPage
  } = usePagination(primaryTickets, parPageData);

  const handleTicketsSearch = text => {
    const filteredTickets = data.filter(
      ticket => ticket.name.toLowerCase().includes(text.toLowerCase())
      // || ticket.subject.toLowerCase().includes(text.toLowerCase())
    );
    setPrimaryTickets(filteredTickets);
  };

  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      cellProps: {
        className: 'py-2 text-600'
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'ps-2', style: { height: '46px' } },
      cellProps: {
        className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
      },
      Cell: rowData => {
        const { name, image } = rowData.row.original;
        return (
          <Flex alignItems="center" className="position-relative py-1">
            <Avatar src={image} name={name} size="xl" className="me-2" />
            <h6 className="mb-0">
              <Button
                variant="link"
                onClick={() => setModalShow(true)}
                className="stretched-link text-900 p-0 fs--1 hover-primary"
              >
                {name}
              </Button>
            </h6>
          </Flex>
        );
      }
    },
    {
      accessor: 'age',
      Header: 'Age',
      cellProps: {
        className: 'py-2 fw-semi-bold text-600',
        style: { minWidth: '70px' }
      }
    },
    {
      accessor: 'location',
      Header: 'Location',
      cellProps: {
        className: 'py-2 fw-semi-bold text-600',
        style: { minWidth: '150px' }
      }
    },
    {
      accessor: 'gender',
      Header: 'Gender',
      cellProps: {
        className: 'py-2 fw-semi-bold text-600',
        style: { minWidth: '80px' }
      }
    },
    {
      accessor: 'sponsor',
      Header: 'Sponsor',
      cellProps: {
        className: 'py-2 text-900',
        style: { minWidth: '100px' }
      }
    },
    {
      accessor: 'renewal',
      Header: 'Renewal',
      cellProps: {
        className: 'py-2 fw-semi-bold text-600',
        style: { minWidth: '100px' }
      }
    },
    {
      accessor: 'duration',
      Header: 'Duration',
      cellProps: {
        className: 'py-2 fw-semi-bold text-600',
        style: { minWidth: '90px' }
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      cellProps: {
        className: 'py-2 text-900',
        style: { minWidth: '100px' }
      }
    }
    // {
    //   accessor: 'id_d',
    //   DonationHeader: '',
    //   headerProps: {
    //     className: 'text-end'
    //   },
    //   cellProps: {
    //     className: 'text-end fw-medium py-2'
    //   },
    //   disableSortBy: true,
    //   Cell: rowData => {
    //     const { id } = rowData.row;
    //     return (
    //       <TableRowAction
    //         onCLickEdit={() => console.log(id)}
    //         onCLickDelete={() => console.log(id)}
    //       />
    //     );
    //   }
    // }
  ];

  const footerPagination = (
    <>
      <div>
        <Button
          variant="falcon-default"
          size="sm"
          className={classNames('me-2', {
            disabled: !canPreviousPage
          })}
          onClick={prevPage}
        >
          {data.image !== '' ? (
            <Image src={data.image} />
          ) : (
            <FontAwesomeIcon icon="chevron-left" />
          )}
        </Button>
      </div>

      <ul className="pagination mb-0">
        {paginationArray.map(page => (
          <li
            key={page}
            className={classNames({ active: currentPage === page })}
          >
            <Button
              size="sm"
              variant="falcon-default"
              className="page me-2"
              onClick={() => goToPage(page)}
            >
              {page}
            </Button>
          </li>
        ))}
      </ul>
      <div>
        <Button
          variant="falcon-default"
          size="sm"
          className={classNames({ disabled: !canNextPage })}
          onClick={nextPage}
        >
          <FontAwesomeIcon icon="chevron-right" />
        </Button>
      </div>
    </>
  );

  const header = (
    <Card.Header className="border-bottom border-200 px-0">
      <SponsoredHeader
        table
        setLayout={setLayout}
        layout={layout}
        selectedItems={selectedItems}
        handleTicketsSearch={handleTicketsSearch}
        totalItems={totalItems}
        title={title}
        sponsorLick={() => setBeneficiaryModalShow(true)}
      />
    </Card.Header>
  );
  const hideSponsorshipModal = () => {
    setBeneficiaryModalShow(false);
  };
  const hideDonorModal = () => {
    setDonorModalShow(false);
  };
  const handleSelectBeneficiarySubmit = selectedBeneficiaryId => {
    setSelectedBeneficiaryId(selectedBeneficiaryId);
    setDonorModalShow(true);
  };
  const handleSelectDonorSubmit = selectedDonorId => {
    navigate(
      parseRouteParams(
        paths.createNewSponsorship,
        {},
        {
          beneficiaryId: selectedBeneficiaryId,
          donorId: selectedDonorId
        }
      )
    );
  };
  return (
    <>
      <SelectBeneficiaryModal
        onHide={hideSponsorshipModal}
        show={beneficiaryModalShow}
        onSubmit={handleSelectBeneficiarySubmit}
      />
      <SelectDonorModal
        onHide={hideDonorModal}
        show={donorModalShow}
        onSubmit={handleSelectDonorSubmit}
      />
      <AdvanceTableWrapper
        columns={columns}
        data={paginatedTicket?.length ? paginatedTicket : primaryTickets}
        // selection
        selectionColumnWidth={52}
        sortable
      >
        <>
          {layout === 'listView' ? (
            <Card>
              {header}
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-light text-800 align-middle"
                  rowClassName="btn-reveal-trigger align-middle"
                  tableProps={{
                    size: 'sm',
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>

              <Card.Footer className="d-flex justify-content-center">
                {footerPagination}
              </Card.Footer>
            </Card>
          ) : (
            <>
              <Card className="mb-3">{header}</Card>

              <SponsoredCardLayout
                data={paginatedTicket}
                isSelectedItem={isSelectedItem}
                toggleSelectedItem={toggleSelectedItem}
              />
              <Flex justifyContent={'center'} className={'mt-3'}>
                {footerPagination}
              </Flex>
            </>
          )}
        </>
      </AdvanceTableWrapper>

      {modalShow && (
        <DonorSponsorshipModal
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
    </>
  );
};

SponsorShipList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  initialLayout: PropTypes.string,
  title: PropTypes.string
};

export default SponsorShipList;
