import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import propTypes from 'prop-types';
import ReactSelect, { components, createFilter } from 'react-select';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Menu = ({ onClickAdd, hasAddOption, children, ...rest }) => (
  <components.MenuList {...rest}>
    {children}
    {hasAddOption && (
      <Button
        variant="secondary"
        type="button"
        onClick={onClickAdd}
        className="w-100 font-weight-bold rounded-0 mt-1 bg-hover-light-primary"
      >
        Add New
      </Button>
    )}
  </components.MenuList>
);
Menu.propTypes = {
  onClickAdd: propTypes.func,
  hasAddOption: propTypes.bool,
  children: propTypes.node
};

const dummyOptions = [
  {
    label: 'Option 1',
    value: '1'
  },
  {
    label: 'Option 2',
    value: '2'
  },
  {
    label: 'Option 3',
    value: '3'
  }
];

export const RHFSelect = ({
  name,
  label,
  isMulti,
  options,
  hasAddOption,
  onClickAdd,
  customMenu: CustomMenu,
  groupStyles,
  groupClassName,
  required,
  hasDummyOptions,
  noOptional,
  infoMessage,
  allowSelectAll,
  ...rest
}) => {
  const { control } = useFormContext();
  const selectOptions = hasDummyOptions ? dummyOptions : options;
  const getSelectValue = value => {
    if (!value || !selectOptions) return '';
    if (isMulti) {
      return selectOptions.filter(option => value?.includes(option.value));
    }
    return selectOptions.filter(option => option.value === value)[0];
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Form.Group className={groupClassName} style={groupStyles}>
          {!!label && (
            <Form.Label className="d-flex align-items-center">
              <div className="me-1">
                {!!infoMessage && <InfoTooltip content={infoMessage} />}
              </div>
              {label} {required && <span className="text-danger">*</span>}
              {!required && (
                <span className="text-500">{!noOptional && '(optional)'}</span>
              )}
            </Form.Label>
          )}

          <ReactSelect
            value={getSelectValue(value)}
            onChange={(option, event) => {
              if (isMulti) {
                if (
                  event.action === 'select-option' &&
                  allowSelectAll &&
                  option.find(option => option.value === '*')
                ) {
                  onChange(
                    options.slice(1, options.length).map(option => option.value)
                  );
                } else {
                  onChange(option.map(item => item.value));
                }
              } else {
                onChange(option?.value);
              }
            }}
            classNamePrefix="react-select"
            isMulti={isMulti}
            options={selectOptions}
            closeMenuOnSelect={!isMulti}
            menuPlacement="auto"
            {...rest}
            components={{
              IndicatorSeparator: () => null,
              MenuList: props =>
                CustomMenu ? (
                  <CustomMenu {...props} />
                ) : (
                  <Menu
                    {...props}
                    onClickAdd={onClickAdd}
                    hasAddOption={hasAddOption}
                  />
                )
            }}
            filterOption={createFilter({ ignoreAccents: false })}
          />

          {error && (
            <Form.Control.Feedback className="d-block" type="invalid">
              {error.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      )}
    />
  );
};

RHFSelect.propTypes = {
  name: propTypes.string,
  label: propTypes.oneOfType([propTypes.string, propTypes.node]),
  isMulti: propTypes.bool,
  options: propTypes.array,
  hasAddOption: propTypes.bool,
  onClickAdd: propTypes.func,
  customMenu: propTypes.func,
  groupStyles: propTypes.object,
  groupClassName: propTypes.string,
  required: propTypes.bool,
  hasDummyOptions: propTypes.bool,
  noOptional: propTypes.bool,
  infoMessage: propTypes.string,
  allowSelectAll: propTypes.bool
};

const InfoTooltip = ({ content }) => {
  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      {content}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="auto"
      flip
      delay={{ show: 150, hide: 400 }}
      overlay={renderTooltip}
      popperConfig={{
        strategy: 'fixed'
      }}
    >
      <span className="info-icon" title={content}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
    </OverlayTrigger>
  );
};

InfoTooltip.propTypes = {
  content: propTypes.string.isRequired
};
