import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AuthContext from 'context/authContext';
import CrudCard from '../../common/CrudCard/CrudCard';

/* -------------------------------------------------------------------------- */
const CREATE_DONATION_TYPE = gql`
  mutation CreateDonationType(
    $donation_type_name: String = ""
    $organisation_id: uuid = ""
  ) {
    insert_donation_type_one(
      object: {
        donation_type_name: $donation_type_name
        organisation_id: $organisation_id
      }
    ) {
      id
      donation_type_name
      organisation_id
    }
  }
`;

const UPDATE_DONATION_TYPE = gql`
  mutation UpdateDonationType(
    $id: uuid = ""
    $donation_type_name: String = ""
  ) {
    update_donation_type(
      where: { id: { _eq: $id } }
      _set: { donation_type_name: $donation_type_name }
    ) {
      returning {
        id
        donation_type_name
        organisation_id
      }
    }
  }
`;

const DELETE_DONATION_TYPE = gql`
  mutation DeleteDonationType($id: uuid = "") {
    delete_donation_type_by_pk(id: $id) {
      id
    }
  }
`;

const GET_DONATION_TYPES = gql`
  query GetDonationTypes($where: donation_type_bool_exp = {}) {
    donation_type(order_by: { created_at: desc }, where: $where) {
      id
      donation_type_name
      organisation_id
    }
  }
`;

/* -------------------------------------------------------------------------- */
function DonationTypes({ sectionRef, sideNavLink, subject }) {
  const cardRef = useRef();
  const { organization } = useContext(AuthContext);

  const [where, setWhere] = useState({});

  useEffect(() => {
    setWhere({
      where: { organisation_id: { _eq: organization.id } }
    });
  }, [organization]);

  const [createDonationType, { loading: createLoading }] = useMutation(
    CREATE_DONATION_TYPE,
    {
      refetchQueries: ['GetDonationTypes'],
      onCompleted: () => {
        toast.success('Donation Type created successfully');
        cardRef.current.onCreationSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [updateDonationType, { loading: updateLoading }] = useMutation(
    UPDATE_DONATION_TYPE,
    {
      refetchQueries: ['GetDonationTypes'],

      onCompleted: () => {
        toast.success('Donation Type updated successfully');
        cardRef.current.onEditSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [deleteDonationType, { loading: deleteLoading }] = useMutation(
    DELETE_DONATION_TYPE,
    {
      refetchQueries: ['GetDonationTypes'],
      onCompleted: () => {
        toast.success('Donation Type deleted successfully');
        cardRef.current.onDeleteSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );

  const [getDonationTypes, { data: donationTypeList }] =
    useLazyQuery(GET_DONATION_TYPES);

  useEffect(() => {
    if (Object.keys(where).length)
      getDonationTypes({ variables: { ...where } });
  }, [where, getDonationTypes]);

  const handleCreate = ({ donation_type_name }) => {
    createDonationType({
      variables: {
        donation_type_name,
        organisation_id: organization.id
      }
    });
  };
  const handleEdit = ({ id, data }) => {
    updateDonationType({
      variables: {
        id,
        donation_type_name: data.donation_type_name
      }
    });
  };
  const handleDelete = id => {
    deleteDonationType({
      variables: {
        id
      }
    });
  };

  const donationTypes = donationTypeList?.donation_type || [];
  const sortedDonationType = [...donationTypes].sort((a, b) => {
    if (a.donation_type_name === 'General') return -1;
    if (b.donation_type_name === 'General') return 1;
    return new Date(b.created_at) - new Date(a.created_at);
  });
  const list = sortedDonationType.map(type => ({
    id: type.id,
    values: [
      {
        value: type.donation_type_name,
        name: 'donation_type_name',
        isActionsHidden: type.donation_type_name === 'General'
      }
    ]
  }));

  return (
    <CrudCard
      ref={cardRef}
      sectionRef={sectionRef}
      sideNavLink={sideNavLink}
      createData={handleCreate}
      editData={handleEdit}
      deleteData={handleDelete}
      createLoading={createLoading}
      updateLoading={updateLoading}
      deleteLoading={deleteLoading}
      formTitle={'Add Donation Types'}
      listTitle={'Current Donation Types'}
      title={'Donation Types'}
      inputs={[
        {
          name: 'donation_type_name',
          placeholder: 'Add Donation Type',
          type: 'text',
          required: true,
          value: '',
          validation: yup.string().required('Donation Type is required')
        }
      ]}
      list={list}
      subject={subject}
    />
  );
}

DonationTypes.propTypes = {
  sectionRef: PropTypes.any,
  sideNavLink: PropTypes.any,
  subject: PropTypes.string
};

export default DonationTypes;
