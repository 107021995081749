import { useLazyQuery, useMutation } from '@apollo/client';
import TableRowAction from 'components/common/TableRowAction';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';

import dayjs from 'dayjs';
import { parseRouteParams } from 'helpers/utils';
import useBranchPermission from 'hooks/useBranchPermission';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import { DELETE_MOSQUE } from '../mutations';
import { GET_MOSQUE_LIST } from '../queries';

function MosqueList() {
  const mosqueColumns = [
    {
      accessor: 'id',
      Header: 'ID',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { simple_id, id } = rowData.row.original;

        return (
          <Link
            to={`/mosque/${id}/details`}
            className="fw-medium ms-2 text-1100 hover-primary"
          >
            MSQ{simple_id}
          </Link>
        );
      }
    },
    {
      accessor: 'name',
      Header: 'Mosque name',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'orphans',
      Header: 'Number of orphans',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => rowData.row.original.orphans_aggregate.aggregate.count
    },
    {
      accessor: 'created_at',
      Header: 'Registered date',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <>{dayjs(created_at).format('DD-MM-YYYY')}</>;
      }
    },
    {
      accessor: 'address',
      Header: 'Address',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'country',
      Header: 'Country',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'email',
      Header: 'Email',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { email } = rowData.row.original;
        return <a href={`mailto:${email}`}>{email}</a>;
      }
    },
    {
      accessor: 'phone',
      Header: 'Phone',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { phone } = rowData.row.original;
        return <a href={`tel:${phone}`}>{phone}</a>;
      }
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickEdit={() =>
              navigate(
                parseRouteParams(paths.editMosque, {
                  id
                })
              )
            }
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.facilities.children.mosque}
          />
        );
      }
    }
  ];

  const navigate = useNavigate();

  const { where } = useBranchPermission();
  const { id } = useParams();
  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [getMosque, { data: MosqueData }] = useLazyQuery(GET_MOSQUE_LIST);

  useEffect(() => {
    if (id) {
      where.mosque_id = { _eq: id };
    }
    getMosque({
      variables: { where }
    });
  }, [where, getMosque, id]);

  const [deleteMosque] = useMutation(DELETE_MOSQUE, {
    refetchQueries: ['GetMosque'],
    onCompleted: () => {
      toast.success('Mosque Deleted Successfully');
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const onDelete = () => {
    // delete call
    deleteMosque({ variables: { id: selectedId } });
    setShowDeleteModal(false);
  };

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        handleConfirm={onDelete}
        title="Delete Mosque"
        body="Are you sure you want to delete this Mosque?"
      />
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {MosqueData?.mosques && (
            <CustomAdvanceTable
              data={MosqueData?.mosques}
              columns={mosqueColumns}
              title="Mosque"
              addButtonLabel="Add Mosque"
              onClickAddNew={() => {
                navigate(paths.addMosque);
              }}
              // loading={loading}
              addNew
              addButtonIcon={'plus'}
              subject={permissionsKeyMap.facilities.children.mosque}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default MosqueList;
