import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import {
  FormProvider,
  RHFControl,
  RHFFileUpload,
  RHFPhoneInput,
  RHFSelect
} from 'components/common/form';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import AuthContext from 'context/authContext';
import { getCountriesOptions } from '../beneficiary-form/LocationCard';
import { GET_LOCATIONS } from '../school/SchoolForm';
import { locationTypesMap } from 'components/donations/donation-settings/Locations';

/* -------------------------------------------------------------------------- */
const MAX_FILE_SIZE = 10048576;

export const mosqueSchema = Yup.object().shape({
  name: Yup.string().required('Required Field'),
  email: Yup.string().email().required('Required Field'),
  phone: Yup.string().required('Required Field'),
  address: Yup.string().required('Required Field'),
  country: Yup.string().required('Required Field'),
  city: Yup.string(),
  document: Yup.mixed().test({
    message: "File too big, can't exceed 10MB",
    test: file => {
      if (typeof file !== 'string' && file) {
        const isValid = file?.size < MAX_FILE_SIZE;
        return isValid;
      }
      return true;
    }
  })
});

/* -------------------------------------------------------------------------- */
export default function MosqueForm({ onSubmit, initValues, loading }) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      address: '',
      country: '',
      city: '',
      document: ''
    },
    resolver: yupResolver(mosqueSchema)
  });

  const { organization } = useContext(AuthContext);
  const { handleSubmit, reset } = methods;

  const { data } = useQuery(GET_LOCATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: organization.id,
      type: locationTypesMap.sponsorship
    }
  });

  const countriesOptions = useMemo(() => {
    return data?.location ? getCountriesOptions(data.location) : [];
  }, [data]);

  useEffect(() => {
    if (initValues) {
      reset({
        phone: initValues.phone,
        address: initValues.address,
        city: initValues.city,
        email: initValues.email,
        name: initValues.name,
        country: initValues.country,
        document: initValues.document
      });
    }
  }, [reset, initValues]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card className="mb-3">
          <Card.Header>
            <Card.Title className="fw-medium">Primary Information</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <Row className="mb-3 g-3">
              <Col md={12}>
                <RHFControl
                  name="name"
                  placeholder="Hospital Name"
                  label="Mosque Name"
                  required
                />
              </Col>

              <Col md="6">
                <RHFControl
                  name="email"
                  placeholder="Email"
                  label="Email"
                  required
                />
              </Col>
              <Col md="6">
                <RHFPhoneInput
                  name="phone"
                  placeholder="Contact Number"
                  label="Contact Number"
                  required
                />
              </Col>
              <Col md="12">
                <RHFControl
                  name="address"
                  placeholder="Address"
                  label="Address"
                  required
                />
              </Col>

              <Col md="6">
                <RHFSelect
                  label={'Country'}
                  name={'country'}
                  options={countriesOptions}
                  required
                />
              </Col>
              <Col md="6">
                <RHFControl
                  name="city"
                  label="city"
                  placeholder="Mosque City"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <Card.Header>
            <Card.Title className="fw-medium">Documents</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <RHFFileUpload label="Document" name="document" rows={5} />
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <Flex justifyContent={'between'} className="align-items-center">
              <p className="mb-0 fs-2 fw-medium">
                {initValues ? 'Update Mosque' : 'Create Mosque'}
              </p>
              <LoadingButton
                loading={loading}
                type="submit"
                className="px-5 py-2"
              >
                Submit
              </LoadingButton>
            </Flex>
          </Card.Body>
        </Card>
      </FormProvider>
    </>
  );
}

MosqueForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initValues: PropTypes.func.object,
  loading: PropTypes.bool
};
