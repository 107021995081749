import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { PHONE_NUMBER_TYPE_OPTIONS } from 'components/Home/data';
import { RHFCheck, RHFControl, RHFSelect } from 'components/common/form';

const EmailInputs = ({ addNewEmail, deleteEmail, emails }) => {
  return (
    <Card className="mb-3">
      <Card.Header>
        <Card.Title className="fw-medium">E-mail Address</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          {emails.map((item, index) => (
            <Col key={item.id} md={12}>
              <Form.Label>Email # {index + 1}</Form.Label>
              <Row className="g-3">
                <Col md={6} controlId="exampleFirstName">
                  <RHFControl name={`emails.${index}.email`} required />
                </Col>
                <Col md={3}>
                  <RHFSelect
                    name={`emails.${index}.type`}
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    required
                  />
                </Col>

                <Col md={3}>
                  <Flex justifyContent="between">
                    <Col controlId={`email-control-${item.id}`}>
                      <div className="mt-2">
                        <RHFCheck
                          name={`emails.${index}.isPrimary`}
                          label="Primary"
                        />
                      </div>
                    </Col>
                    {index !== 0 && (
                      <IconButton
                        variant="light"
                        icon="trash"
                        className={classNames(
                          'mb-1 bg-transparent border-0 shadow-none',
                          { 'v-hidden': item.isDefault }
                        )}
                        iconAlign="left"
                        transform="shrink-3"
                        iconClassName="text-danger me-2"
                        onClick={() => deleteEmail(index)}
                        disabled={item.isDefault}
                      >
                        Delete
                      </IconButton>
                    )}
                  </Flex>
                </Col>
              </Row>
              {index !== emails.length - 1 && <hr className="mb-2" />}
            </Col>
          ))}
        </Row>
      </Card.Body>
      <div className="p-3 pt-0">
        <Button
          variant="light"
          className="bg-transparent border-0 shadow-none fs--1 fs-md-0 text-primary p-1"
          onClick={addNewEmail}
        >
          Add New Email
        </Button>
      </div>
    </Card>
  );
};

EmailInputs.propTypes = {
  addNewEmail: PropTypes.func.isRequired,
  deleteEmail: PropTypes.func.isRequired,
  emails: PropTypes.array.isRequired
};

export default EmailInputs;
