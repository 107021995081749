import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import { DONATION_STEPS } from '../data';

import { DonationHeader, HEADER_HEIGHT } from '../DonationHeader';
import { DonorForm, donorSchema, donorFormDefaultValues } from './donor-form';
import { GiftAidCard } from '../GiftAidCard';
import { FooterImages } from '../DonationFooter';
import { DonationSummary } from './donation-summary';

function DonorInformationPage() {
  const { setDonor, setDonationStep } = useDonationContextProvider();

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: donorFormDefaultValues,
    resolver: yupResolver(donorSchema)
  });

  return (
    <div>
      <DonationHeader />
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-12 col-md-8 py-6 ps-4 ps-md-8 pe-4"
            style={{ minHeight: `calc(100vh - ${HEADER_HEIGHT}px)` }}
          >
            <DonorForm form={form} />
            <div className="mb-4">
              <GiftAidCard logoPosition="right" />
            </div>
            <div className="py-4">
              <FooterImages />
            </div>
          </div>
          <div
            className="col-12 col-md-4 py-8 ps-6 pe-6 bg-wr-donation-grey-50"
            style={{ minHeight: `calc(100vh - ${HEADER_HEIGHT}px)` }}
          >
            <DonationSummary
              onCheckout={form.handleSubmit(data => {
                setDonor(data);
                setDonationStep(DONATION_STEPS.PAYMENT);
              })}
              onClickBack={() =>
                setDonationStep(DONATION_STEPS.BASKET_CREATION)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export { DonorInformationPage };
