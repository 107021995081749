/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Card, Col, InputGroup, Row, Nav } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import Locations, { locationTypesMap } from './Locations';
// import ProjectCategories from './ProjectCategories';
import DonationTypes from './DonationType';
import DonationAmounts from './DonationAmounts';
import TransactionFees from './TransactionFees';
import ProjectCurrencies from './ProjectCurrencies';
import Campaigns from './Campaigns';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';
import { Can } from 'routes/permissions/Can';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormProvider, RHFControl } from '../../common/form';
import LoadingButton from '../../common/LoadingButton';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import AuthContext from '../../../context/authContext';

export const donationSettingsSectionsRefMap = {
  location: '#location',
  category: '#category',
  campaigns: '#campaigns',
  donationType: '#donationType',
  donationAmount: '#donationAmount',
  transactionFee: '#transactionFee',
  projectCurrency: '#projectCurrency',
  successRedirection: '#successRedirection'
};

const DonationSettings = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  const [stickyStatus, setStickyStatus] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 100) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, [window.scrollY]);

  const sideNavLinks = [
    {
      id: 1,
      href: donationSettingsSectionsRefMap.location,
      title: 'Projects Location'
    },
    {
      id: 2,
      href: donationSettingsSectionsRefMap.category,
      title: 'Project Category'
    },
    {
      id: 3,
      href: donationSettingsSectionsRefMap.campaigns,
      title: 'Campaigns'
    },
    {
      id: 4,
      href: donationSettingsSectionsRefMap.donationType,
      title: 'Donation Type'
    },
    {
      id: 5,
      href: donationSettingsSectionsRefMap.donationAmount,
      title: 'Donation Amounts'
    },
    {
      id: 6,
      href: donationSettingsSectionsRefMap.transactionFee,
      title: 'Add transaction Fee'
    },
    {
      id: 7,
      href: donationSettingsSectionsRefMap.projectCurrency,
      title: 'Projects Currency'
    },
    {
      id: 8,
      href: donationSettingsSectionsRefMap.successRedirection,
      title: 'Success Redirection'
    }
  ];

  return (
    <Row className="pt-3 gx-3">
      <Col md={9}>
        {/* {cardsData.map((card, index) => {
          return (
            <>
              <DonationCard
                sectionRef={sectionRefs[index + 1]}
                sideNavLinks={sideNavLinks[index]?.href?.substring(1)}
                card={card}
                addData={addData}
                editData={editData}
                deleteData={deleteData}
              />
            </>
          );
        })} */}
        <Locations
          sectionRef={sectionRefs[1]}
          sideNavLink={sideNavLinks[0]?.href?.substring(1)}
          type={locationTypesMap.project}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        {/*
        <ProjectCategories
          sectionRef={sectionRefs[2]}
          sideNavLink={sideNavLinks[1]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        */}
        <Campaigns
          sectionRef={sectionRefs[3]}
          sideNavLink={sideNavLinks[2]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        <DonationTypes
          sectionRef={sectionRefs[4]}
          sideNavLink={sideNavLinks[3]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        <DonationAmounts
          sectionRef={sectionRefs[5]}
          sideNavLinks={sideNavLinks[4]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        <TransactionFees
          sectionRef={sectionRefs[6]}
          sideNavLinks={sideNavLinks[5]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />

        <ProjectCurrencies
          sectionRef={sectionRefs[7]}
          sideNavLinks={sideNavLinks[6]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />

        <SuccessRedirection
          sectionRef={sectionRefs[7]}
          sideNavLinks={sideNavLinks[7]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        {/* <Card>
          <Card.Body className="d-flex flex-row justify-content-end">
            <Button
              variant="primary"
              className="w-100 w-sm-25"
              onClick={() => setModal(true)}
            >
              Update
            </Button>
          </Card.Body>
        </Card> */}
      </Col>
      <Col md={3} className="order-0 order-md-1 d-none d-md-block">
        <Card
          className={'mb-3 sticky-top border shadow-none'}
          style={{ top: stickyStatus ? '5rem' : '0' }}
        >
          <Card.Body>
            <div>
              <Nav className="flex-column" variant="pills">
                {sideNavLinks?.map(item => (
                  <Nav.Link
                    key={item.id}
                    href={item.href}
                    className={`${
                      activeSection === item.id
                        ? 'current custom-nav-link'
                        : 'custom-nav-link'
                    }`}
                    active={activeSection >= item.id}
                  >
                    {item.title}
                  </Nav.Link>
                ))}
              </Nav>
            </div>
          </Card.Body>
        </Card>
      </Col>

      {/* <DataModal
        show={modal}
        setShow={setModal}
        page={'donation_settings'}
        data={{
          ...cardsData,
          4: donationAmounts,
          5: transactionFee,
          6: projectsCurrency,
          7: successRedirection
        }}
      /> */}
    </Row>
  );
};

/* Success Redirection */

const GET_SUCCESS_REDIRECTION_URL = gql`
  query GetSuccessRedirectionURL($organizationId: uuid!) {
    donation_settings(where: { organisation_id: { _eq: $organizationId } }) {
      success_redirection_url
    }
  }
`;

const SAVE_SUCCESS_REDIRECTION_URL = gql`
  mutation SaveSuccessRedirectionURL(
    $organizationId: uuid!
    $url: String = ""
  ) {
    insert_success_redirection_url(
      organization_id: $organizationId
      success_redirection_url: $url
    ) {
      success_redirection_url
    }
  }
`;

const successRedirectionFormSchema = Yup.object().shape({
  redirectionURL: Yup.string()
    .optional()
    .test(
      'is-valid-url',
      'Enter correct URL',
      value =>
        !value ||
        value.trim() === '' ||
        value
          .trim()
          .match(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)
    )
});

const SuccessRedirection = props => {
  const { sectionRef, sideNavLink, subject } = props;
  const { organization } = useContext(AuthContext);

  const [getSuccessRedirectionURL, { data: successRedirectionResData }] =
    useLazyQuery(GET_SUCCESS_REDIRECTION_URL);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      redirectionURL: ''
    },
    resolver: yupResolver(successRedirectionFormSchema)
  });
  const { handleSubmit: RHFHandleSubmit, reset: resetForm } = methods;

  const [saveRedirectionPage, { loading: isSaveRedirectionPageLoading }] =
    useMutation(SAVE_SUCCESS_REDIRECTION_URL, {
      onCompleted: () => {
        toast.success('Success Redirection Page has been updated');
      }
    });

  const onSave = data => {
    const { redirectionURL } = data;
    saveRedirectionPage({
      variables: {
        organizationId: organization.id,
        url: redirectionURL.trim()
      }
    });
  };

  useEffect(() => {
    if (organization && organization.id) {
      getSuccessRedirectionURL({
        variables: {
          organizationId: organization.id
        }
      });
    }
  }, [organization, getSuccessRedirectionURL]);

  useEffect(() => {
    if (successRedirectionResData) {
      resetForm({
        redirectionURL: successRedirectionResData.success_redirection_url
      });
    }
  }, [successRedirectionResData, resetForm]);

  return (
    <>
      <Card
        key="Success Redirection"
        className="border shadow-none mb-3"
        ref={sectionRef}
        id={sideNavLink}
      >
        <Card.Header>
          <Row>
            <Col>
              <Card.Title>Success Redirection</Card.Title>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <FormProvider methods={methods} onSubmit={RHFHandleSubmit(onSave)}>
            <InputGroup className="mb-3">
              <div className="flex-grow-1">
                <RHFControl
                  name="redirectionURL"
                  label="Donation Success Redirect Page"
                  type="text"
                  placeholder="https://www.example.com/success"
                />
              </div>
              <Can I={userActions.update} a={subject}>
                <LoadingButton
                  loading={isSaveRedirectionPageLoading}
                  variant="light"
                  type="submit"
                  className="border bg-transparent shadow-none align-self-end"
                  style={{
                    fontWeight: 600
                  }}
                >
                  Save
                </LoadingButton>
              </Can>
            </InputGroup>
          </FormProvider>
        </Card.Body>
      </Card>
    </>
  );
};

export default DonationSettings;
