import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import {
  FEE_TYPE_FIXED,
  FEE_TYPE_PERCENTAGE,
  GET_TRANSACTION_FEES
} from '../donations/donation-settings/TransactionFees';
import { calculatePercentageFee } from '../add-donation/DonationConfig';

import { useDonationContextProvider } from 'context/DonationContextProvider';

import { Form } from 'react-bootstrap';

function CoverTransactionFee() {
  const {
    transactionFee: isCoverTransactionFeeChecked,
    setTransactionFee,
    basket,
    urgentAppeal,
    transactionFeeAmount,
    setTransactionFeeAmount
  } = useDonationContextProvider();

  const { organizationId } = useParams();
  const { data: transactionFeesData } = useQuery(GET_TRANSACTION_FEES, {
    variables: {
      organisation_id: organizationId
    }
  });

  const transactionFee = useMemo(() => {
    let totalAmount = basket.reduce(
      (acc, cur) => acc + cur.count * cur.donationAmount.value,
      0
    );

    urgentAppeal.forEach(() => {
      totalAmount += 10;
    });
    let transactionTmp = 0;

    if (transactionFeesData) {
      const total = transactionFeesData.transaction_fee.reduce((acc, curr) => {
        if (curr.fee_type === FEE_TYPE_PERCENTAGE) {
          acc = calculatePercentageFee(totalAmount, curr.amount);
        } else if (curr.fee_type === FEE_TYPE_FIXED) {
          acc += curr.amount;
        }
        transactionTmp = acc;
      }, 0);
      if (transactionTmp === 0) {
        transactionTmp = total;
      }
      return transactionTmp;
    } else {
      return 0;
    }
  }, [basket, transactionFeesData, urgentAppeal]);

  useEffect(() => {
    if (transactionFeesData) {
      setTransactionFeeAmount(transactionFee);
    }
  }, [transactionFeesData, transactionFee, setTransactionFeeAmount]);

  return (
    <div className="wr-donation-summary-card wr-donation-text-brand-secondary  mb-3 d-flex justify-content-between align-items-center">
      <Form.Check
        type="checkbox"
        className="m-0 d-flex align-items-center gap-2 "
      >
        <Form.Check.Input
          id="wr-donation-transction-fee-checkbox"
          isValid
          className="mt-0"
          checked={isCoverTransactionFeeChecked}
          onChange={e => setTransactionFee(e.target.checked)}
        />
        <label htmlFor="wr-donation-transction-fee-checkbox" className="mb-0">
          Cover Transaction Fee
        </label>
      </Form.Check>
      <span>{transactionFeeAmount}</span>
    </div>
  );
}

export { CoverTransactionFee };
