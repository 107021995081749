function ShareCircleIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.7666C6.5 2.7666 2 7.2666 2 12.7666C2 18.2666 6.5 22.7666 12 22.7666C17.5 22.7666 22 18.2666 22 12.7666C22 7.2666 17.5 2.7666 12 2.7666ZM14 16.7666V13.7666C10.39 13.7666 7.81 15.1966 6 17.7666C6.72 14.0966 8.94 10.4966 14 9.7666V6.7666L19 11.7666L14 16.7666Z"
        fill="currentColor"
      />
    </svg>
  );
}

export { ShareCircleIcon };
